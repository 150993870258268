/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useState, useEffect } from 'react';
import { Table, Space, Input, Row, Col, Button, Menu, Dropdown, Tooltip, notification, Checkbox } from 'antd';
// eslint-disable-next-line import/no-extraneous-dependencies
import { MenuInfo } from 'rc-menu/lib/interface';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { SearchOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import JSZip from 'jszip';
import dateFormat from 'dateformat';
import AuthService from '../../../services/identity/authService';
import ParticipantService from '../../../services/participant';
import {
    ParticipantDataModel,
    pdfDataModel,
    studyDataModal,
    participantStatus,
    sendReminderRequestBody,
    MessageType,
} from '../../../types/global';
import ReminderDrawer from '../../reminderDrawer/reminderDrawer';
import ParticipantContactDrawer from '../../contactDrawer/participantContactDrawer';
import InvitationDrawer from '../../invitationDrawer/invitationDrawer';
import DeleteModal from '../../deleteModal/deleteModal';
import ExportModal from '../../exportModal/exportModal';
import { consentAccept, consentPending, consentDecline, consentViewed } from '../../../types/iconIndex';

import pdfLogo from '../../../assets/logo-pdf.png';

import '../index.scss';

type participantTableModel = {
    invitationDrawerVisible: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    invitationDrawerOnClose: (form: any) => void;
};

type excelExportObject = {
    /*     personalId: string;
    firstName: string;
    lastName: string;
    email: string;
    mobileNumber: string;
    streetAddress: string;
    postalCode: string;
    city: string;
    consentStatus: string;
    date: string;
    time: string; */
};

const ParticipantsListTable: React.FC<participantTableModel> = (props) => {
    const { invitationDrawerVisible, invitationDrawerOnClose } = props;
    const { t } = useTranslation();
    const [dataSource, setDataSource] = useState<Array<ParticipantDataModel>>([]);
    const [allSignedData, setAllSignedData] = useState<Array<pdfDataModel>>([]);
    const [selectedSignedData, setSelectedSignedData] = useState<Array<pdfDataModel>>([]);
    const [duplicateDataSource, setDuplicateDataSource] = useState<Array<ParticipantDataModel>>([]);
    const authService = new AuthService();
    const [loading, setLoading] = useState(true);
    const [exportLoading, setExportLoading] = useState(false);
    const [rowsSelected, setRowsSelected] = useState(0);
    const studyRef = useLocation<studyDataModal>().state;
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [drawVisible, setDrawVisible] = useState(false);
    const [participantId, setParticipantId] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [personalId, setPersonalId] = useState('');
    const [email, setEmail] = useState('');
    const [contactDrawVisible, setContactDrawVisible] = useState(false);
    const [exportModalRadioValue, setExportModalRadioValue] = useState<string>('excel');
    const [exportModalVisible, setExportModalVisible] = useState(false);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [deleteModalConfirmLoading, setDeleteModalConfirmLoading] = useState(false);
    const [
        { statusFilterMenuVisible, filterDropDownButtonText, statusFilterCheckListValue },
        setStatusFilterMenu,
    ] = useState({
        statusFilterMenuVisible: false,
        filterDropDownButtonText: t('participant.statusFilterButton'),
        statusFilterCheckListValue: [] as CheckboxValueType[],
    });

    const openNotification = (type: string, message: string, err: string) => {
        if (type === 'success' || type === 'warning') notification[type]({ message, description: err });
    };

    const fetch = async () => {
        try {
            const user = await authService.getUser();
            const token: string = user?.access_token || '';
            const response = await ParticipantService.getAllParticipants(token, studyRef.id);
            setDataSource(response.data);
            setDuplicateDataSource(response.data);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setDataSource([]);
            setLoading(false);
        }
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: (keys: React.Key[], selectedRows: ParticipantDataModel[]) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            setSelectedRowKeys(keys);
            setRowsSelected(selectedRows.length);
        },
        /* getCheckboxProps: (record: ParticipantDataModel) => ({
            disabled: record.status === 2, // Column configuration not to be checked
            personalId: record.personalId,
        }), */
    };

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        fetch();
    }, []);

    const bulkResend = async () => {
        try {
            const user = await authService.getUser();
            const token: string = user?.access_token || '';

            const request: sendReminderRequestBody = {
                ids: selectedRowKeys,
                messageType: MessageType.Both,
            };
            await ParticipantService.sendReminder(token, request);
            setSelectedRowKeys([]);
            setRowsSelected(0);
            openNotification('success', t('participant.notification.invitationSuccess'), '');
        } catch (error) {
            console.log(error);
            setSelectedRowKeys([]);
            setRowsSelected(0);
            openNotification('warning', t('participant.notification.invitationFail'), '');
        }
    };
    const bulkDelete = () => {
        setDeleteModalVisible(true);
    };

    const deleteModalHandleOk = async () => {
        try {
            setDeleteModalConfirmLoading(true);
            const user = await authService.getUser();
            const token: string = user?.access_token || '';
            await ParticipantService.deleteParticipant(token, selectedRowKeys);
            await fetch();
            setSelectedRowKeys([]);
            setRowsSelected(0);
            openNotification('success', t('participant.notification.deleteSuccess'), '');
            setDeleteModalConfirmLoading(false);
            setDeleteModalVisible(false);
        } catch (error) {
            console.log(error);
            setSelectedRowKeys([]);
            setRowsSelected(0);
            openNotification('warning', t('participant.notification.deleteFail'), '');
            setDeleteModalConfirmLoading(false);
            setDeleteModalVisible(false);
        }
    };
    const deleteModalHandleCancel = () => {
        setDeleteModalVisible(false);
        setSelectedRowKeys([]);
        setRowsSelected(0);
    };

    const bulkMenu = (
        <Menu>
            <Menu.Item>
                <Button className="participantList-btn-bulkMenu" onClick={bulkResend}>
                    {t('participant.resendInvitation')}
                </Button>
            </Menu.Item>
            <Menu.Item>
                <Button className="participantList-btn-bulkMenu" onClick={bulkDelete}>
                    {t('participant.delete')}
                </Button>
            </Menu.Item>
        </Menu>
    );
    const statusFilterOptions = [
        { label: t('participant.statusFilter.send'), value: participantStatus.PENDING },
        { label: t('participant.statusFilter.viewed'), value: participantStatus.VIEWED },
        { label: t('participant.statusFilter.signed'), value: participantStatus.ACCEPT },
        { label: t('participant.statusFilter.decline'), value: participantStatus.DECLINED },
    ];

    const statusFilterMenuOnclick = (e: MenuInfo) => {
        if (e.key === '2') {
            setStatusFilterMenu((prevState) => ({
                ...prevState,
                statusFilterMenuVisible: false,
            }));
        }
    };
    const onChangeStatusCheckboxGroup = (e: CheckboxValueType[]) => {
        // check list array set to state
        setStatusFilterMenu((prevState) => ({
            ...prevState,
            statusFilterCheckListValue: [...e],
        }));

        // select name for the button
        let displayText = t('participant.statusFilterButton');
        if (e.length > 0) {
            switch (e[0]) {
                case participantStatus.PENDING:
                    displayText = t('participant.statusFilter.send');
                    break;
                case participantStatus.VIEWED:
                    displayText = t('participant.statusFilter.viewed');
                    break;
                case participantStatus.ACCEPT:
                    displayText = t('participant.statusFilter.signed');
                    break;
                case participantStatus.DECLINED:
                    displayText = t('participant.statusFilter.decline');
                    break;
                default:
                    displayText = t('participant.statusFilterButton');
                    break;
            }
            // filter data
            const tableData: Array<ParticipantDataModel> = duplicateDataSource;
            const filterData: Array<ParticipantDataModel> = tableData.filter((entry: ParticipantDataModel) => {
                let found = false;
                e.forEach((element) => {
                    if (entry.status === element) {
                        found = true;
                    }
                });
                if (found) {
                    return entry;
                }
                return false;
            });
            setDataSource(filterData);
        } else {
            displayText = t('participant.statusFilterButton');
            setStatusFilterMenu((prevState) => ({
                ...prevState,
                filterDropDownButtonText: displayText,
            }));
            // set duplicate data source
            setDataSource(duplicateDataSource);
        }

        // set name for multiple selection
        if (e.length === 1) {
            setStatusFilterMenu((prevState) => ({
                ...prevState,
                filterDropDownButtonText: displayText,
            }));
        } else if (e.length > 1) {
            setStatusFilterMenu((prevState) => ({
                ...prevState,
                filterDropDownButtonText: `${displayText} (+${e.length - 1})`,
            }));
        }
    };

    const statusFilterMenu = (
        <Menu onClick={statusFilterMenuOnclick}>
            <Menu.Item key="1">
                <Checkbox.Group
                    options={statusFilterOptions}
                    onChange={onChangeStatusCheckboxGroup}
                    className="participantList-statusFilter-checkBox-menu"
                    value={statusFilterCheckListValue}
                />
            </Menu.Item>
            <Menu.Item key="2">
                <Button
                    style={{ textAlign: 'center' }}
                    className="participantList-btn-bulkMenu"
                    onClick={() => {
                        setStatusFilterMenu((prevState) => ({
                            ...prevState,
                            statusFilterCheckListValue: [],
                            filterDropDownButtonText: t('participant.statusFilterButton'),
                        }));
                        setDataSource(duplicateDataSource);
                    }}
                >
                    {t('participant.filterClearButton')}
                </Button>
            </Menu.Item>
        </Menu>
    );
    const statusFilterMenuVisibleOnChange = (flag: boolean) => {
        setStatusFilterMenu((prevState) => ({
            ...prevState,
            statusFilterMenuVisible: flag,
        }));
    };
    const columns: ColumnsType<ParticipantDataModel> = [
        {
            title: t('participant.table.firstName'),
            dataIndex: 'firstName',
            key: 'firstName',
            className: 'participantList-table-column',
            sorter: (a, b) => {
                if (a.firstName !== null && b.firstName !== null) {
                    return a.firstName.localeCompare(b.firstName);
                }
                const c = 'N';
                const d = 'N';
                return c.localeCompare(d);
            },
            sortDirections: ['ascend', 'descend'],
            render: (state: string): string => {
                if (state === null) {
                    return 'N/A';
                }
                return state;
            },
        },
        {
            title: t('participant.table.lastName'),
            dataIndex: 'lastName',
            key: 'lastName',
            className: 'participantList-table-column',
            sorter: (a, b) => {
                if (a.lastName !== null && b.lastName !== null) {
                    return a.lastName.localeCompare(b.lastName);
                }
                const c = 'N';
                const d = 'N';
                return c.localeCompare(d);
            },
            sortDirections: ['ascend', 'descend'],
            render: (state: string): string => {
                if (state === null) {
                    return 'N/A';
                }
                return state;
            },
        },
        {
            title: t('participant.table.personalId'),
            dataIndex: 'maskedPersonalId',
            key: 'maskedPersonalId',
            className: 'participantList-table-column',
            sorter: (a, b) => a.personalId.localeCompare(b.personalId),
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: t('participant.table.mobileNumber'),
            dataIndex: 'mobileNumber',
            key: 'mobileNumber',
            className: 'participantList-table-column',
            sorter: (a, b) => {
                if (a.mobileNumber !== null && b.mobileNumber !== null) {
                    return a.mobileNumber.localeCompare(b.mobileNumber);
                }
                const c = 'N';
                const d = 'N';
                return c.localeCompare(d);
            },
            sortDirections: ['ascend', 'descend'],
            render: (state: string): string => {
                if (state === null) {
                    return 'N/A';
                }
                return state;
            },
        },
        {
            title: t('participant.table.email'),
            dataIndex: 'email',
            key: 'email',
            className: 'participantList-table-column',
            sorter: (a, b) => {
                if (a.email !== null && b.email !== null) {
                    return a.email.localeCompare(b.email);
                }
                const c = 'N';
                const d = 'N';
                return c.localeCompare(d);
            },
            sortDirections: ['ascend', 'descend'],
            render: (state: string): string => {
                if (state === null) {
                    return 'N/A';
                }
                return state;
            },
        },
        {
            title: t('participant.table.status'),
            key: 'Status',
            dataIndex: 'status',
            align: 'center',
            sorter: (a, b) => a.status - b.status,
            render: (state) => {
                if (state === participantStatus.PENDING) {
                    return (
                        <Tooltip
                            placement="bottom"
                            title={t('participant.tooltip.send')}
                            className="participantList-table-statusIcon"
                        >
                            <img src={consentPending} alt="consentPending" />
                        </Tooltip>
                    );
                }
                if (state === participantStatus.VIEWED) {
                    return (
                        <Tooltip
                            placement="bottom"
                            title={t('participant.tooltip.viewed')}
                            className="participantList-table-statusIcon"
                        >
                            <img src={consentViewed} alt="consentViewed" />
                        </Tooltip>
                    );
                }
                if (state === participantStatus.ACCEPT) {
                    return (
                        <Tooltip
                            placement="bottom"
                            title={t('participant.tooltip.signed')}
                            className="participantList-table-statusIcon"
                        >
                            <img src={consentAccept} alt="ConsentAccept" />
                        </Tooltip>
                    );
                }
                if (state === participantStatus.DECLINED) {
                    return (
                        <Tooltip
                            placement="bottom"
                            title={t('participant.tooltip.decline')}
                            className="participantList-table-statusIcon"
                        >
                            <img src={consentDecline} alt="consentDecline" />
                        </Tooltip>
                    );
                }
                return <FontAwesomeIcon icon="hourglass-half" />;
            },
        },
        {
            title: t('participant.table.action'),
            key: 'action',
            align: 'center',
            render: (recode) => {
                const { status, id } = recode;
                return (
                    <Space size={60}>
                        <Tooltip placement="bottom" title={t('participant.tooltip.resendInvitation')}>
                            <Button
                                icon={
                                    <FontAwesomeIcon
                                        icon="envelope-open"
                                        className="participantList-table-actionIcon"
                                    />
                                }
                                className="participantList-table-action-button"
                                disabled={status === participantStatus.DECLINED || status === participantStatus.ACCEPT}
                                onClick={() => {
                                    setParticipantId(recode.id);
                                    setMobileNumber(recode.mobileNumber);
                                    setPersonalId(recode.maskedPersonalId);
                                    setEmail(recode.email);
                                    setDrawVisible(true);
                                }}
                            />
                        </Tooltip>
                        <Tooltip placement="bottom" title={t('participant.tooltip.personalDetails')}>
                            <Button
                                icon={
                                    <FontAwesomeIcon icon="address-card" className="participantList-table-actionIcon" />
                                }
                                className="participantList-table-action-button "
                                onClick={() => {
                                    setContactDrawVisible(true);
                                    setParticipantId(recode.id);
                                }}
                            />
                        </Tooltip>
                        <Tooltip placement="bottom" title={t('participant.tooltip.deleteParticipant')}>
                            <Button
                                icon={<FontAwesomeIcon icon="trash" className="participantList-table-actionIcon" />}
                                className="participantList-table-action-button"
                                onClick={() => {
                                    const idArray = [];
                                    idArray.push(id);
                                    setSelectedRowKeys(idArray);
                                    setDeleteModalVisible(true);
                                }}
                            />
                        </Tooltip>
                    </Space>
                );
            },
        },
    ];

    const onSearch = (e: string) => {
        if (e === '') {
            setDataSource(duplicateDataSource);
        } else {
            const tableData: Array<ParticipantDataModel> = duplicateDataSource;
            const filterData: Array<ParticipantDataModel> = tableData.filter(
                (entry: ParticipantDataModel) =>
                    entry.firstName?.toLowerCase().includes(e.toLowerCase()) ||
                    entry.lastName?.toLowerCase().includes(e.toLowerCase()) ||
                    entry.personalId?.toLowerCase().includes(e.toLowerCase()) ||
                    entry.email?.toLowerCase().includes(e.toLowerCase()) ||
                    entry.mobileNumber?.toLowerCase().includes(e.toLowerCase()),
            );
            setDataSource(filterData);
        }
    };

    const onCloseDrawer = () => {
        setDrawVisible(false);
    };

    const onCloseContactDrawer = () => {
        setContactDrawVisible(false);
    };

    const updateTable = async () => {
        await fetch();
    };

    const setStatusToExcel = (status: number) => {
        switch (status) {
            case participantStatus.PENDING:
                return 'Sent';
            case participantStatus.VIEWED:
                return 'Viewed';
            case participantStatus.ACCEPT:
                return 'Consent accepted';
            case participantStatus.DECLINED:
                return 'Consent declined';

            default:
                return 'N/A';
        }
    };

    const setDateToExcel = (dateTime: string) => {
        if (dateTime === '0001-01-01T01:12:00') {
            return 'N/A';
        }
        const date = dateFormat(dateTime, 'paddedShortDate');
        return date;
    };
    const setTimeToExcel = (dateTime: string) => {
        if (dateTime === '0001-01-01T01:12:00') {
            return 'N/A';
        }
        const time = dateFormat(dateTime, 'shortTime');
        return time;
    };

    const setDateToPdf = (dateTime: string) => {
        if (dateTime === '0001-01-01T01:12:00') {
            return 'N/A';
        }
        const date = dateFormat(dateTime, 'paddedShortDate');
        return date;
    };
    const setTimeToPdf = (dateTime: string) => {
        if (dateTime === '0001-01-01T01:12:00') {
            return 'N/A';
        }
        const time = dateFormat(dateTime, 'shortTime');
        return time;
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const exportModalHandleOnChange = async (e: any) => {
        setExportModalRadioValue(e.target.value);
        const user = await authService.getUser();
        const token: string = user?.access_token || '';
        if (rowsSelected === 0) {
            const allIds = dataSource.map((item) => item.id);
            const requestBodyArray: Array<string> = allIds;
            const allResponse = await ParticipantService.createCertificates(token, requestBodyArray);
            setAllSignedData(allResponse.data);
        } else {
            const selectedData = dataSource.filter((dataItem) => selectedRowKeys.includes(dataItem.id));
            const selectedIds = selectedData.map((item) => item.id);
            const requestBodyArray: Array<string> = selectedIds;
            const response = await ParticipantService.createCertificates(token, requestBodyArray);
            setSelectedSignedData(response.data);
        }
    };

    const exportModalHandleCancel = () => {
        setExportModalVisible(false);
        setSelectedRowKeys([]);
        setRowsSelected(0);
        setExportModalRadioValue('excel');
    };

    const exportModalHandleDownload = async () => {
        setExportLoading(true);
        if (exportModalRadioValue === 'excel') {
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const fileExtension = '.xlsx';
            const excelData: Array<excelExportObject> = [];
            const fileName = `Participant List of ${studyRef.studyName}`;

            if (rowsSelected === 0) {
                dataSource.forEach((item: ParticipantDataModel) => {
                    const tempObj = {
                        'Personal ID Number': item.personalId ? item.personalId : 'N/A',
                        'First Name': item.firstName ? item.firstName : 'N/A',
                        'Last Name': item.lastName ? item.lastName : 'N/A',
                        'Email ': item.email ? item.email : 'N/A',
                        'Mobile Number': item.mobileNumber ? item.mobileNumber : 'N/A',
                        'Street Address': item.streetAddress ? item.streetAddress : 'N/A',
                        'Postal Code': item.postalCode ? item.postalCode : 'N/A',
                        'City ': item.city ? item.city : 'N/A',
                        'Consent Status': setStatusToExcel(item.status),
                        'Date ': setDateToExcel(item.consentActionDate),
                        'Time ': setTimeToExcel(item.consentActionDate),
                        'Unique ID provided by Bank ID': item.authorisationCode ? item.authorisationCode : 'N/A',
                    };
                    excelData.push(tempObj);
                });
            } else {
                const selectedData = dataSource.filter((dataItem) => selectedRowKeys.includes(dataItem.id));
                selectedData.forEach((item: ParticipantDataModel) => {
                    const tempObj = {
                        'Personal ID Number': item.personalId ? item.personalId : 'N/A',
                        'First Name': item.firstName ? item.firstName : 'N/A',
                        'Last Name': item.lastName ? item.lastName : 'N/A',
                        'Email ': item.email ? item.email : 'N/A',
                        'Mobile Number': item.mobileNumber ? item.mobileNumber : 'N/A',
                        'Street Address': item.streetAddress ? item.streetAddress : 'N/A',
                        'Postal Code': item.postalCode ? item.postalCode : 'N/A',
                        'City ': item.city ? item.city : 'N/A',
                        'Consent Status': setStatusToExcel(item.status),
                        'Date ': setDateToExcel(item.consentActionDate),
                        'Time ': setTimeToExcel(item.consentActionDate),
                        'Unique ID provided by Bank ID': item.authorisationCode ? item.authorisationCode : 'N/A',
                    };
                    excelData.push(tempObj);
                });
            }

            const ws = XLSX.utils.json_to_sheet(excelData);
            const wb = { Sheets: { ParticipantList: ws }, SheetNames: ['ParticipantList'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(data, fileName + fileExtension);
        } else {
            // console.log('Selected Radio Value', exportModalRadioValue);
            /* eslint-disable-next-line no-lonely-if */
            if (rowsSelected === 0) {
                if (allSignedData.length >= 1) {
                    const zip = new JSZip();
                    allSignedData.forEach((employee) => {
                        // eslint-disable-next-line new-cap
                        const pdf = new jsPDF('p', 'mm', 'a4');
                        const fileName = `${employee.personalId}`;
                        const fileExtension = '.pdf';
                        pdf.addImage(pdfLogo, 'png', 85, 10, 38, 23);
                        // pdf.addFont('Helvetica', 'Helvetica', 'normal');
                        // pdf.setFont('Helvetica');
                        pdf.setFontSize(11);

                        pdf.setDrawColor('#1688a8');
                        pdf.setLineWidth(8);
                        // border top
                        pdf.line(0, 0, pdf.internal.pageSize.getWidth(), 0);
                        // border bottom
                        pdf.line(
                            0,
                            pdf.internal.pageSize.getHeight(),
                            pdf.internal.pageSize.getWidth(),
                            pdf.internal.pageSize.getHeight(),
                        );

                        pdf.text('Study Name: ', 10, 40);
                        pdf.text(`${employee.studyName}`, 100, 40, { maxWidth: 100 });

                        pdf.text('Name of participant: ', 10, 55);
                        pdf.text(`${employee.participantName}`, 100, 55, { maxWidth: 100 });

                        pdf.text('Contact details of participant: ', 10, 70);
                        pdf.text(`${employee.email}`, 100, 70, { maxWidth: 100 });

                        pdf.text('The personal ID of the participant: ', 10, 85);
                        pdf.text(`${employee.personalId}`, 100, 85, { maxWidth: 100 });

                        pdf.text('Date: ', 10, 100);
                        pdf.text(`${setDateToPdf(employee.created)}`, 100, 100, { maxWidth: 100 });

                        pdf.text('Time: ', 10, 115);
                        pdf.text(`${setTimeToPdf(employee.created)}`, 100, 115, { maxWidth: 100 });

                        pdf.text('Bank ID Data: ', 10, 130);
                        pdf.text(`${employee.authorisationCode}`, 100, 130, { maxWidth: 100 });

                        pdf.text('Consent questions: ', 10, 150);
                        employee.consentQuestions.forEach((Question, j) => {
                            pdf.text(`\u2022 `, 100, 150 + j * 18, { maxWidth: 100 });
                            pdf.text(`${Question}`, 105, 150 + j * 18, { maxWidth: 100 });
                        });

                        if (allSignedData.length === 1) {
                            pdf.save(fileName + fileExtension);
                        } else if (allSignedData.length > 1) {
                            // pdf.save(fileName + fileExtension);
                            zip.file(fileName + fileExtension, pdf.output('blob'));
                        }
                    });

                    if (allSignedData.length > 1) {
                        const zipFileName = `Participant List of ${studyRef.studyName}`;
                        const zipFileExtension = '.zip';

                        await zip.generateAsync({ type: 'blob' }).then((content) => {
                            FileSaver.saveAs(content, zipFileName + zipFileExtension);
                        });
                    }
                } else {
                    openNotification('warning', t('participant.notification.pdfDownloadFail'), '');
                }
            } else {
                // console.log('PDF Selected Data Source:', selectedSignedData);
                /* eslint-disable-next-line no-lonely-if */
                if (selectedSignedData.length >= 1) {
                    const zip = new JSZip();
                    selectedSignedData.forEach((employee) => {
                        // eslint-disable-next-line new-cap
                        const pdf = new jsPDF('p', 'mm', 'a4');
                        const fileName = `${employee.personalId}`;
                        const fileExtension = '.pdf';
                        pdf.addImage(pdfLogo, 'png', 85, 10, 38, 23);
                        // pdf.addFont('Helvetica', 'Helvetica', 'normal');
                        // pdf.setFont('Helvetica');
                        pdf.setFontSize(11);

                        pdf.setDrawColor('#1688a8');
                        pdf.setLineWidth(8);
                        // border top
                        pdf.line(0, 0, pdf.internal.pageSize.getWidth(), 0);
                        // border bottom
                        pdf.line(
                            0,
                            pdf.internal.pageSize.getHeight(),
                            pdf.internal.pageSize.getWidth(),
                            pdf.internal.pageSize.getHeight(),
                        );

                        pdf.text('Study Name: ', 10, 40);
                        pdf.text(`${employee.studyName}`, 100, 40, { maxWidth: 100 });

                        pdf.text('Name of participant: ', 10, 55);
                        pdf.text(`${employee.participantName}`, 100, 55, { maxWidth: 100 });

                        pdf.text('Contact details of participant: ', 10, 70);
                        pdf.text(`${employee.email}`, 100, 70, { maxWidth: 100 });

                        pdf.text('The personal ID of the participant: ', 10, 85);
                        pdf.text(`${employee.personalId}`, 100, 85, { maxWidth: 100 });

                        pdf.text('Date: ', 10, 100);
                        pdf.text(`${setDateToPdf(employee.created)}`, 100, 100, { maxWidth: 100 });

                        pdf.text('Time: ', 10, 115);
                        pdf.text(`${setTimeToPdf(employee.created)}`, 100, 115, { maxWidth: 100 });

                        pdf.text('Bank ID Data: ', 10, 130);
                        pdf.text(`${employee.authorisationCode}`, 100, 130, { maxWidth: 100 });

                        pdf.text('Consent questions: ', 10, 150);
                        employee.consentQuestions.forEach((Question, j) => {
                            pdf.text(`\u2022 `, 100, 150 + j * 18, { maxWidth: 100 });
                            pdf.text(`${Question}`, 105, 150 + j * 18, { maxWidth: 100 });
                        });

                        if (selectedSignedData.length === 1) {
                            pdf.save(fileName + fileExtension);
                        } else if (selectedSignedData.length > 1) {
                            // pdf.save(fileName + fileExtension);
                            zip.file(fileName + fileExtension, pdf.output('blob'));
                        }
                    });
                    if (selectedSignedData.length > 1) {
                        const zipFileName = `Participant List of ${studyRef.studyName}`;
                        const zipFileExtension = '.zip';

                        await zip.generateAsync({ type: 'blob' }).then((content) => {
                            FileSaver.saveAs(content, zipFileName + zipFileExtension);
                        });
                    }
                } else {
                    openNotification('warning', t('participant.notification.pdfDownloadFail'), '');
                }
            }
        }
        setExportLoading(false);
    };

    return (
        <div className="participantList-root">
            <Table<ParticipantDataModel>
                rowSelection={rowSelection}
                columns={columns}
                className={rowsSelected === 0 ? '' : 'participantList-bulkMenu'}
                rowKey="id"
                dataSource={dataSource}
                loading={loading}
                pagination={{ hideOnSinglePage: true }}
                title={() =>
                    rowsSelected <= 1 ? (
                        <Row gutter={24}>
                            <Col span={6}>
                                {' '}
                                <Input
                                    placeholder={t('global.searchPlaceHolder')}
                                    prefix={<SearchOutlined />}
                                    allowClear
                                    onChange={(e) => {
                                        onSearch((e.target as HTMLInputElement).value);
                                    }}
                                    onPressEnter={(e) => {
                                        onSearch((e.target as HTMLInputElement).value);
                                    }}
                                />
                            </Col>
                            <Col span={14}>
                                <Dropdown
                                    overlay={statusFilterMenu}
                                    overlayClassName="participantList-dropdown-filterMenu"
                                    placement="bottomCenter"
                                    arrow
                                    visible={statusFilterMenuVisible}
                                    onVisibleChange={statusFilterMenuVisibleOnChange}
                                    trigger={['click']}
                                >
                                    <Button className="participantList-bulkMenu-btn-action">
                                        {filterDropDownButtonText}
                                        <FontAwesomeIcon
                                            icon="caret-down"
                                            className="participantList-bulkMenu-btn-icon"
                                        />
                                    </Button>
                                </Dropdown>
                            </Col>
                            <Col span={4} style={{ textAlign: 'right' }}>
                                <Button
                                    className="participantList-btn-export"
                                    loading={exportLoading}
                                    onClick={() => {
                                        setExportModalVisible(true);
                                    }}
                                >
                                    {t('participant.exportButton')}
                                </Button>
                            </Col>
                        </Row>
                    ) : (
                        <Row className="participantList-bulkMenu-outer">
                            <Col span={4} className="participantList-bulkMenu-selectItems">
                                {`${rowsSelected} ${t('participant.selectedItems')}`}
                            </Col>
                            <Col span={16}>
                                <Dropdown overlay={bulkMenu} placement="bottomRight" arrow>
                                    <Button className="participantList-bulkMenu-btn-action">
                                        {t('participant.bulkActionButton')}
                                        <FontAwesomeIcon
                                            icon="caret-down"
                                            className="participantList-bulkMenu-btn-icon"
                                        />
                                    </Button>
                                </Dropdown>
                            </Col>
                            <Col span={4} style={{ textAlign: 'right' }}>
                                <Button
                                    className="participantList-btn-export"
                                    loading={exportLoading}
                                    onClick={() => {
                                        setExportModalVisible(true);
                                    }}
                                >
                                    {t('participant.exportButton')}
                                </Button>
                            </Col>
                            {/* <Col span={4} className="participantList-bulkMenu-col-btn-reset">
                                <Button
                                    className="participantList-bulkMenu-btn-reset"
                                    onClick={() => {
                                        setSelectedRowKeys([]);
                                        setRowsSelected(0);
                                    }}
                                >
                                    {t('participant.resetButton')}
                                </Button>
                            </Col> */}
                        </Row>
                    )
                }
            />
            <ExportModal
                infoMessage={t('global.participantExport.infoMessage')}
                subTitle={t('global.participantExport.subTitle')}
                visible={exportModalVisible}
                radioValue={exportModalRadioValue}
                radioTextExcel={t('global.participantExport.radioTextExcel')}
                radioTextPdf={t('global.participantExport.radioTextPdf')}
                downloadButtonText={t('global.participantExport.downloadButtonText')}
                handleOnChange={exportModalHandleOnChange}
                handleDownload={exportModalHandleDownload}
                confirmLoading={exportLoading}
                handleCancel={exportModalHandleCancel}
            />
            <DeleteModal
                title={t('global.participantDelete.title')}
                modalText={t('global.participantDelete.messageText')}
                visible={deleteModalVisible}
                okButtonText={t('global.participantDelete.okButtonText')}
                handleOk={deleteModalHandleOk}
                confirmLoading={deleteModalConfirmLoading}
                handleCancel={deleteModalHandleCancel}
            />
            <ReminderDrawer
                visible={drawVisible}
                onClose={onCloseDrawer}
                participantId={participantId}
                mobileNumber={mobileNumber}
                personalId={personalId}
                studyName={studyRef.studyName}
                email={email}
                studyId={studyRef.id}
            />
            <ParticipantContactDrawer
                visible={contactDrawVisible}
                onClose={onCloseContactDrawer}
                participantId={participantId}
                onUpdate={updateTable}
            />
            <InvitationDrawer
                visible={invitationDrawerVisible}
                onClose={invitationDrawerOnClose}
                studyId={studyRef.id}
                studyName={studyRef.studyName}
                updateStudyList={fetch}
            />
        </div>
    );
};
export default ParticipantsListTable;
