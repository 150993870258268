/* eslint-disable @typescript-eslint/no-unsafe-call */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// local imports

import en from './en.json';

// eslint-disable-next-line @typescript-eslint/no-floating-promises
i18n.use(initReactI18next).init({
    resources: {
        en: { translations: en },
    },
    fallbackLng: 'en',
    lng: 'en',
    // debug only when not in production
    debug: process.env.NODE_ENV !== 'production',
    ns: ['translations'],
    defaultNS: 'translations',
    // keySeparator: false,
    interpolation: {
        escapeValue: false,
        formatSeparator: ',',
    },
    react: {
        // wait: true,
        useSuspense: true,
    },
});

export default i18n;
