import { Log, User, UserManager, WebStorageStateStore } from 'oidc-client';

export default class AuthService {
    public userManager: UserManager;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private user: any = null;

    constructor() {
        const settings = {
            authority: process.env.REACT_APP_IDENTITY_BASE_URL,
            client_id: process.env.REACT_APP_IDENTITY_CLIENT_ID,
            redirect_uri: `${window.location.origin}/signin-callback.html`,
            monitorSession: false,
            post_logout_redirect_uri: `${window.location.origin}/`,
            response_type: 'code',
            scope: 'read openid profile email offline_access',
            userStore: new WebStorageStateStore({ store: window.localStorage }), // set this to save user info in localStorage
        };
        this.userManager = new UserManager(settings);

        Log.logger = console;
        Log.level = Log.INFO;

        this.userManager.events.addSilentRenewError((e) => {
            console.log('silent renew error', e.message);
        });

        this.userManager.events.addAccessTokenExpired(() => {
            console.log('token expired');
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            this.logout();
        });
    }

    public getUser(): Promise<User | null> {
        return this.userManager.getUser();
    }

    public login(): Promise<void> {
        console.log('Login button click handled.');
        return this.userManager.signinRedirect();
    }

    public isLoggedIn(): Promise<boolean> {
        return this.userManager.getUser().then((user) => !!user);
    }

    public logout(): Promise<void> {
        return this.userManager.signoutRedirect();
    }
}
