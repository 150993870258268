import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useTitle from '../../utils/hooks/useTitle';
import './index.scss';
import UserListTable from '../../components/users/table/userListTable';
import { Button, Col, Row } from 'antd';

const UserManagement: React.FC = () => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { setHeaderTitle } = useTitle();
    const { t } = useTranslation();
    const [drawVisible, setDrawVisible] = useState<boolean>(false);

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        setHeaderTitle(t('users.usersTitle'));
    }, []);

    return (
        <div className="root">
            <Row>
                <Col span={24} style={{ textAlign: 'right' }}>
                    <Button
                        type="primary"
                        className="studyList-btn-addUser"
                        size="large"
                        shape="round"
                        onClick={() => {
                            // console.log('click');
                            setDrawVisible(true);
                        }}
                    >
                        {t('users.addUserButton')}
                    </Button>
                </Col>
            </Row>
            <UserListTable addUserDrawerVisible={drawVisible} addUserDrawerOnClose={setDrawVisible} />
            {/* <Registration /> */}
        </div>
    );
};

export default UserManagement;
