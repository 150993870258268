/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { Drawer, Form, Button, Col, Row, Avatar, Input, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { UserOutlined } from '@ant-design/icons';
import Loading from '../loading/loading';
import { NotificationType, RoleModel, User, UsersDataModel } from '../../types/global';

import './index.scss';
import UserService from '../../services/user';
import openNotification from '../../utils/notification/notification';
import RoleService from '../../services/role';

interface drawerProps {
    visible: boolean;
    userData: UsersDataModel;
    onClose: (form: any, id?: string, user?: User) => void;
    onCancel: (userId: string, user: User) => void;
    // onUpdate: () => void;
}
const obj: UsersDataModel = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    roleId: 2,
    unit: '',
    roleName: '',
    id: '',
    key: '',
};

const { Option } = Select;

const UserEditDrawer: React.FC<drawerProps> = (props) => {
    const { onClose, visible, userData, onCancel } = props;
    const { t } = useTranslation();
    const [form] = Form.useForm();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [loading, setLoading] = useState(false);
    const [selectedUser, setSelectedUser] = useState<UsersDataModel>(obj);
    const [userRole, setUserRole] = useState<RoleModel[]>([{ id: 0, roleName: '' }]);

    const fetch = () => {
        form.setFieldsValue({
            email: userData ? userData.email : '',
            firstName: userData ? userData.firstName : '',
            lastName: userData ? userData.lastName : '',
            role: userData ? userData.roleId : '',
        });
    };

    useEffect(() => {
        if (visible) {
            fetch();
        }
    }, [visible]);

    useEffect(() => {
        setSelectedUser(userData);
    }, [props]);

    const onClickSave = () => {
        form.submit();
    };

    const getAllRoles = () => {
        RoleService.GetRoles()
            .then((res) => {
                const { data } = res.data;
                setUserRole(data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const onFinish = ({ firstName, lastName, role }: { firstName: string; lastName: string; role: number }) => {
        if (
            firstName !== selectedUser.firstName ||
            lastName !== selectedUser.lastName ||
            role !== selectedUser.roleId
        ) {
            setLoading(true);
            UserService.UpdateUser(selectedUser.id, {
                firstName: firstName.trim(),
                lastName: lastName.trim(),
                roleId: role,
                mobileNumber: selectedUser.phone,
                streetAddress: selectedUser?.streetAddress,
                postalCode: selectedUser.postalCode,
            })
                .then((res) => {
                    setLoading(false);
                    openNotification(NotificationType.success, t('userEditDrawer.successMessage'), '');
                    form.resetFields(['email', 'firstName', 'lastName', 'role']);
                    onClose(form, selectedUser.id, {
                        email: selectedUser.email,
                        firstName: firstName.trim(),
                        lastName: lastName.trim(),
                        roleId: role,
                    });
                })
                .catch((err) => {
                    openNotification(
                        NotificationType.error,
                        'Failed to update the user. Please try again later',
                        err.message,
                    );
                });
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log(errorInfo);
    };

    useEffect(() => {
        getAllRoles();
    }, []);

    const setDrawerHeader = () => {
        if (selectedUser.firstName != null && selectedUser.lastName != null) {
            return (
                <div className="userEdit-drawer-title">
                    <div>
                        <Avatar size="large" className="userEdit-drawer-avatar">
                            {selectedUser.firstName.charAt(0).toUpperCase()}
                            {selectedUser.lastName.charAt(0).toUpperCase()}
                        </Avatar>
                        <div style={{ marginTop: '10px' }}>{`${selectedUser.firstName} ${selectedUser.lastName}`}</div>
                    </div>
                </div>
            );
        }
        if (selectedUser.firstName === '' && selectedUser.lastName === '') {
            return (
                <div className="userEdit-drawer-title">
                    <Avatar size="large" icon={<UserOutlined />} />
                </div>
            );
        }
        return (
            <div className="userEdit-drawer-title">
                <Avatar size="large" icon={<UserOutlined />} />
            </div>
        );
    };

    return (
        <div>
            <Drawer
                title={setDrawerHeader()}
                width={450}
                onClose={() => {
                    onCancel(selectedUser.id, {
                        firstName: form.getFieldValue('firstName') as string,
                        lastName: form.getFieldValue('lastName') as string,
                        email: form.getFieldValue('email') as string,
                        roleId: form.getFieldValue('role') as number,
                    });
                }}
                visible={visible}
                maskClosable={!loading}
                footer={
                    !loading && (
                        <div className="userEdit-drawer-footer">
                            <Row>
                                <Col span={12}>
                                    <Button
                                        onClick={() =>
                                            onCancel(selectedUser.id, {
                                                firstName: form.getFieldValue('firstName') as string,
                                                lastName: form.getFieldValue('lastName') as string,
                                                email: form.getFieldValue('email') as string,
                                                roleId: form.getFieldValue('role') as number,
                                            })
                                        }
                                        className="userEdit-drawer-btn-cancel"
                                    >
                                        {t('userEditDrawer.cancelButton')}
                                    </Button>
                                </Col>
                                <Col span={12}>
                                    <Button onClick={onClickSave} className="userEdit-drawer-btn-save">
                                        {t('userEditDrawer.saveButton')}
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    )
                }
            >
                {!loading ? (
                    <Form
                        layout="vertical"
                        form={form}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        className="userEdit-drawer-form"
                    >
                        <Row gutter={16} className="userEdit-drawer-form-row">
                            <Col span={24}>
                                <Row>
                                    <Col span={12}>
                                        <div className="userEdit-drawer-form-label">
                                            {t('userEditDrawer.firstName')}
                                        </div>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name="firstName"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t('userEditDrawer.firstNameRequired'),
                                                },
                                                () => ({
                                                    validator(_, value: string) {
                                                        if (!value || value.trim() !== '') {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject(
                                                            new Error(t('userEditDrawer.firstNameRequired')),
                                                        );
                                                    },
                                                }),
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row gutter={16} className="userEdit-drawer-form-row">
                            <Col span={24}>
                                <Row>
                                    <Col span={12}>
                                        <div className="userEdit-drawer-form-label">{t('userEditDrawer.lastName')}</div>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name="lastName"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t('userEditDrawer.LastNameRequired'),
                                                },
                                                () => ({
                                                    validator(_, value: string) {
                                                        if (!value || value.trim() !== '') {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject(
                                                            new Error(t('userEditDrawer.LastNameRequired')),
                                                        );
                                                    },
                                                }),
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row gutter={16} className="userEdit-drawer-form-row">
                            <Col span={24}>
                                <Row>
                                    <Col span={12}>
                                        <div className="userEdit-drawer-form-label">{t('userEditDrawer.email')}</div>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name="email"
                                            rules={[
                                                {
                                                    type: 'email',
                                                    message: t('userEditDrawer.emailValidation'),
                                                },
                                                {
                                                    required: true,
                                                    message: t('userEditDrawer.emailRequired'),
                                                },
                                            ]}
                                        >
                                            <Input disabled />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row gutter={16} className="userEdit-drawer-form-row">
                            <Col span={24}>
                                <Row>
                                    <Col span={12}>
                                        <div className="userEdit-drawer-form-label">{t('userEditDrawer.role')}</div>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name="role"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t('userEditDrawer.roleRequired'),
                                                },
                                            ]}
                                        >
                                            <Select>
                                                {userRole.map((role: RoleModel) => (
                                                    <Option key={role.id} value={role.id}>
                                                        {role.roleName}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                ) : (
                    <Loading />
                )}
            </Drawer>
        </div>
    );
};

export default UserEditDrawer;
