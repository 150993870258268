/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useEffect, useState } from 'react';
import defaultModelContext from '../utils/context/defaultModel-context';
import AuthService from '../services/identity/authService';
import StudyService from '../services/study';
import { studyTemplateObject, studyTemplateKey } from '../types/global';

const DefaultModelProvider: React.FC = ({ children }) => {
    const authService = new AuthService();
    const [inviteEmail, setInviteEmail] = useState('');
    const [reminderEmail, setReminderEmail] = useState('');
    const [consent1, setConsent1] = useState('');
    const [consent2, setConsent2] = useState('');
    const [participantInfoText, setParticipantInfoText] = useState('');
    const [introductionText, setIntroductionText] = useState('');

    useEffect(() => {
        const fetch = async () => {
            try {
                const user = await authService.getUser();
                const token = user?.access_token || '';
                const response = await StudyService.getDefaultDetails(token);
                // console.log('getDefaultDetails:', response);

                const array: Array<studyTemplateObject> = response.data.data.studyTemplates;
                array.forEach((element: studyTemplateObject) => {
                    if (element.key === studyTemplateKey.REMINDER_EMAIL) {
                        setReminderEmail(element.value);
                    }
                    if (element.key === studyTemplateKey.INVITE_EMAIL) {
                        setInviteEmail(element.value);
                    }
                    if (element.key === studyTemplateKey.CONSENT_1) {
                        setConsent1(element.value);
                    }
                    if (element.key === studyTemplateKey.CONSENT_2) {
                        setConsent2(element.value);
                    }
                    if (element.key === studyTemplateKey.PARTICIPANT_INFO) {
                        setParticipantInfoText(element.value);
                    }
                    if (element.key === studyTemplateKey.STUDY_INTRODUCTION_TEXT) {
                        setIntroductionText(element.value);
                    }
                });
            } catch (error) {
                console.log(error);
            }
        };
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        fetch();
    }, []);

    const modelContextObj = {
        inviteEmail,
        reminderEmail,
        consent1,
        consent2,
        participantInfoText,
        introductionText,
    };

    return <defaultModelContext.Provider value={modelContextObj}> {children}</defaultModelContext.Provider>;
};

export default DefaultModelProvider;
