/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from 'react';
import { AxiosRequestConfig, AxiosResponse } from 'axios';

import axiosInstance from '../../utils/axios/axiosInstance';
import { ENDPOINTS } from './contracts';
import {
    addParticipantRequestBody,
    sendReminderRequestBody,
    updateContactInfoRequestBody,
    updateContactInfoResponse,
} from '../../types/global';

class ParticipantService {
    public static async addParticipant(
        idToken: string,
        request: Array<addParticipantRequestBody>,
    ): Promise<AxiosResponse> {
        const requestConfig: AxiosRequestConfig = {
            method: 'post',
            url: `${ENDPOINTS.PARTICIPANT}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${idToken}`,
            },
            data: request,
        };
        const response: AxiosResponse = await axiosInstance(requestConfig);
        return response;
    }

    public static async updateParticipantContactInfo(
        idToken: string,
        request: updateContactInfoRequestBody,
    ): Promise<AxiosResponse<updateContactInfoResponse>> {
        const requestConfig: AxiosRequestConfig = {
            method: 'put',
            url: `${ENDPOINTS.PARTICIPANT}/${request.id}/ContactInfo`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${idToken}`,
            },
            data: request,
        };
        const response: AxiosResponse = await axiosInstance(requestConfig);
        return response;
    }

    public static async getAllParticipants(idToken: string, studyId: string): Promise<AxiosResponse> {
        const requestConfig: AxiosRequestConfig = {
            method: 'get',
            url: `${ENDPOINTS.PARTICIPANT}/GetParticipants/${studyId}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${idToken}`,
            },
        };
        const response: AxiosResponse = await axiosInstance(requestConfig);
        return response;
    }

    public static async sendReminder(idToken: string, request: sendReminderRequestBody): Promise<AxiosResponse> {
        const requestConfig: AxiosRequestConfig = {
            method: 'put',
            url: `${ENDPOINTS.PARTICIPANT}/SendReminder`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${idToken}`,
            },
            data: request,
        };
        const response: AxiosResponse = await axiosInstance(requestConfig);
        return response;
    }

    public static async deleteParticipant(
        idToken: string,
        participantIds: string[] | React.Key[],
    ): Promise<AxiosResponse> {
        const requestConfig: AxiosRequestConfig = {
            method: 'delete',
            url: `${ENDPOINTS.PARTICIPANT}/delete`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${idToken}`,
            },
            data: participantIds,
        };
        const response: AxiosResponse = await axiosInstance(requestConfig);
        return response;
    }

    public static async getParticipant(idToken: string, participantId: string): Promise<AxiosResponse> {
        const requestConfig: AxiosRequestConfig = {
            method: 'get',
            url: `${ENDPOINTS.PARTICIPANT}/${participantId}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${idToken}`,
            },
        };
        const response: AxiosResponse = await axiosInstance(requestConfig);
        return response;
    }

    public static async checkDuplicateParticipant(
        idToken: string,
        requestBody: Array<addParticipantRequestBody>,
    ): Promise<AxiosResponse> {
        const requestConfig: AxiosRequestConfig = {
            method: 'post',
            url: `${ENDPOINTS.PARTICIPANT}/GetDuplicateParticipants`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${idToken}`,
            },
            data: requestBody,
        };
        const response: AxiosResponse = await axiosInstance(requestConfig);
        return response;
    }

    public static async createCertificates(idToken: string, requestBody: Array<string>): Promise<AxiosResponse> {
        const requestConfig: AxiosRequestConfig = {
            method: 'post',
            url: `${ENDPOINTS.PARTICIPANT}/CreateCertificates`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${idToken}`,
            },
            data: requestBody,
        };
        const response: AxiosResponse = await axiosInstance(requestConfig);
        return response;
    }
}
export default ParticipantService;
