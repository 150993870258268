import React from 'react';
import { Spin, Space } from 'antd';

import './index.scss';

const Loading: React.FC = () => (
    <div className="loading">
        <Space size="middle">
            <Spin size="large" />
        </Space>
    </div>
);

export default Loading;
