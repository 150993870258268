/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Input, Form, Spin, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import FloatLabel from '../../components/floatLabel/floatLabel';
import AuthService from '../../services/identity/authService';
import { APIResponse, UpdateUser, researcherProfileFormValues } from '../../types/global';
import './index.scss';
import 'react-phone-number-input/style.css';
import UserService from '../../services/user';
import { AxiosResponse } from 'axios';

type valuesForLabel = {
    cFirstName: string;
    cLastName: string;
    cInstitution: string;
    cUnit: string;
    cMobileNumber: string;
    cStreetAddress: string;
    cPostCode: string;
    cPostalAddress: string;
};

const ProfileContent: React.FC = () => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const authService = new AuthService();
    const [
        { cFirstName, cLastName, cInstitution, cUnit, cMobileNumber, cStreetAddress, cPostCode, cPostalAddress },
        setChangeData,
    ] = useState({
        cFirstName: '',
        cLastName: '',
        cInstitution: '',
        cUnit: '',
        cMobileNumber: ' ',
        cStreetAddress: '',
        cPostCode: '',
        cPostalAddress: '',
    } as valuesForLabel);

    const [userDetail, setUserDetail] = useState<UpdateUser>();

    const openNotification = (type: string, message: string, err: string) => {
        if (type === 'success' || type === 'warning') notification[type]({ message, description: err });
    };

    const setValues = (response: AxiosResponse<APIResponse<UpdateUser>>, userEmail: string) => {
        try {
            const metaData = response.data.data;
            if (response.data !== null) {
                form.setFieldsValue({
                    firstName: metaData.firstName ? metaData.firstName : '',
                    lastName: metaData.lastName ? metaData.lastName : '',
                    email: userEmail,
                    mobileNumber: metaData.mobileNumber ? metaData.mobileNumber : '',
                    postalAddress: metaData.streetAddress ? metaData.streetAddress : '',
                    postalCode: metaData.postalCode ? metaData.postalCode : '',
                });
                setChangeData((oldState) => {
                    const newObj = {
                        ...oldState,
                        cFirstName: metaData.firstName ? metaData.firstName : '',
                        cLastName: metaData.lastName ? metaData.lastName : '',
                        cMobileNumber: metaData.mobileNumber ? metaData.mobileNumber : '',
                        cStreetAddress: metaData.streetAddress ? metaData.streetAddress : '',
                        cPostCode: metaData.postalCode ? metaData.postalCode : '',
                    };
                    return newObj;
                });
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const getUserDetails = async () => {
        try {
            const user = await authService.getUser();
            const userEmail: string = user?.profile.email || '';
            const response = await UserService.GetMyDetails();
            setUserDetail(response.data.data);
            setValues(response, userEmail);
        } catch (error) {
            console.log(error);
            openNotification('warning', t('profile.notification.error'), ' ');
            setLoading(false);
        }
    };

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        getUserDetails();
    }, []);

    const onFinish = async (values: researcherProfileFormValues) => {
        // console.log(values);
        try {
            setLoading(true);
            const reqBody: UpdateUser = {
                firstName: values.firstName,
                lastName: values.lastName,
                mobileNumber: values.mobileNumber,
                streetAddress: values.streetAddress,
                postalCode: values.postalCode,
            };
            await UserService.UpdateUser(userDetail?.id ?? '', reqBody);
            openNotification('success', t('profile.notification.updateSuccess'), '');
            setLoading(false);
            history.push('/app/studies');
        } catch (error) {
            console.log(error);
            openNotification('warning', t('profile.notification.updateFail'), ' ');
            setLoading(false);
        }
    };
    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div className="auth-profile-cardDiv">
            <div className="auth-profile-card">
                <Spin spinning={loading}>
                    <Row>
                        <Col span={24} className="auth-profile-title">
                            {t('autProfile.title')}
                        </Col>
                    </Row>

                    <Form
                        layout="vertical"
                        className="drawer-form"
                        form={form}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >
                        <Row gutter={16}>
                            <Col span={12}>
                                <FloatLabel label={t('autProfile.firstName')} value={cFirstName}>
                                    <Form.Item
                                        name="firstName"
                                        style={{ textAlign: 'left' }}
                                        rules={[{ required: true, message: t('autProfile.requiredField') }]}
                                    >
                                        <Input
                                            className="auth-profile-form-input"
                                            onChange={(e) => {
                                                setChangeData((oldState) => {
                                                    const newObj = {
                                                        ...oldState,
                                                        cFirstName: e.target.value,
                                                    };
                                                    return newObj;
                                                });
                                            }}
                                        />
                                    </Form.Item>
                                </FloatLabel>
                            </Col>
                            <Col span={12}>
                                <FloatLabel label={t('autProfile.lastName')} value={cLastName}>
                                    <Form.Item
                                        name="lastName"
                                        style={{ textAlign: 'left' }}
                                        rules={[{ required: true, message: t('autProfile.requiredField') }]}
                                    >
                                        <Input
                                            className="auth-profile-form-input"
                                            onChange={(e) => {
                                                setChangeData((oldState) => {
                                                    const newObj = {
                                                        ...oldState,
                                                        cLastName: e.target.value,
                                                    };
                                                    return newObj;
                                                });
                                            }}
                                        />
                                    </Form.Item>
                                </FloatLabel>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={24}>
                                <FloatLabel label={t('autProfile.mobileNumber')} value={cMobileNumber} intlNumber>
                                    <Form.Item
                                        name="mobileNumber"
                                        style={{ textAlign: 'left' }}
                                        rules={[
                                            { required: true, message: t('autProfile.requiredField') },
                                            () => ({
                                                validator(_, value1) {
                                                    if (!value1 || (value1 && isValidPhoneNumber(value1))) {
                                                        return Promise.resolve();
                                                    }

                                                    return Promise.reject(new Error(t('autProfile.mobileNumberRule')));
                                                },
                                            }),
                                        ]}
                                    >
                                        <PhoneInput
                                            international
                                            defaultCountry="SE"
                                            value={cMobileNumber}
                                            onChange={(val) => {
                                                setChangeData((oldState: any) => {
                                                    const newObj = {
                                                        ...oldState,
                                                        cMobileNumber: val,
                                                    };
                                                    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                                                    return newObj;
                                                });
                                            }}
                                        />
                                    </Form.Item>
                                </FloatLabel>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={24}>
                                <FloatLabel label={t('autProfile.postalAddress')} value={cStreetAddress}>
                                    <Form.Item
                                        name="streetAddress"
                                        style={{ textAlign: 'left' }}
                                        rules={[{ required: true, message: t('autProfile.requiredField') }]}
                                    >
                                        <Input
                                            className="auth-profile-form-input"
                                            onChange={(e) => {
                                                setChangeData((oldState) => {
                                                    const newObj = {
                                                        ...oldState,
                                                        cStreetAddress: e.target.value,
                                                    };
                                                    return newObj;
                                                });
                                            }}
                                        />
                                    </Form.Item>
                                </FloatLabel>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={24}>
                                <FloatLabel label={t('autProfile.postalCode')} value={cPostCode}>
                                    <Form.Item
                                        name="postalCode"
                                        style={{ textAlign: 'left' }}
                                        rules={[{ required: true, message: t('autProfile.requiredField') }]}
                                    >
                                        <Input
                                            className="auth-profile-form-input"
                                            onChange={(e) => {
                                                setChangeData((oldState) => {
                                                    const newObj = {
                                                        ...oldState,
                                                        cPostCode: e.target.value,
                                                    };
                                                    return newObj;
                                                });
                                            }}
                                        />
                                    </Form.Item>
                                </FloatLabel>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24} className="auth-profile-button-col">
                                <Button
                                    className="auth-profile-saveButton"
                                    type="primary"
                                    onClick={() => {
                                        form.submit();
                                    }}
                                >
                                    {t('autProfile.saveButton')}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </div>
        </div>
    );
};
export default ProfileContent;
