/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React from 'react';
import { Input, Form, FormInstance } from 'antd';
import { useTranslation } from 'react-i18next';

import './index.scss';

const { TextArea } = Input;

const EditableContext = React.createContext({} as FormInstance);

export const EditableRow = ({ index, ...props }: any) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

export const EditableCell = ({ title, editable, children, dataIndex, record, handleSave, ...restProps }: any) => {
    const { t } = useTranslation();
    const [editing, setEditing] = React.useState(false);
    const inputRef = React.useRef(title);
    const form = React.useContext(EditableContext);
    React.useEffect(() => {
        if (editing) {
            inputRef.current.focus();
        }
    }, [editing]);

    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({
            [dataIndex]: record[dataIndex],
        });
    };

    const save = async () => {
        try {
            const values = await form.validateFields();
            toggleEdit();
            handleSave({ ...record, ...values });
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };

    let childNode = children;

    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{
                    margin: 0,
                }}
                name={dataIndex}
                rules={
                    record.key === 0
                        ? [
                              {
                                  required: true,
                                  message: `${t('newStudy.introductionText')} ${t('newStudy.required')}`,
                              },
                          ]
                        : [
                              {
                                  required: true,
                                  message: `${title} ${t('newStudy.required')}`,
                              },
                          ]
                }
            >
                <TextArea ref={inputRef} onPressEnter={save} onBlur={save} autoSize />
            </Form.Item>
        ) : (
            <div
                className="create-study-editable-cell-value-wrap"
                style={{
                    paddingRight: 24,
                }}
                onClick={toggleEdit}
            >
                {children}
            </div>
        );
    }

    return <td {...restProps}>{childNode}</td>;
};
