import React, { useEffect, useState } from 'react';
import { Drawer, Form, Button, Col, Row, Avatar } from 'antd';
import { useTranslation } from 'react-i18next';
import { UserOutlined } from '@ant-design/icons';
import Loading from '../loading/loading';
import { User, UsersDataModel } from '../../types/global';
import DeleteModal from '../deleteModal/deleteModal';
import UserEditDrawer from '../usereditDrawer/userEditDrawer';
import './index.scss';
import ConfirmationModal, { DeleteModelTypes } from '../ConfirmationModal/ConfirmationModal';

interface drawerProps {
    visible: boolean;
    userData: UsersDataModel;
    onClose: () => void;
    onEditCancel: (id: string, user: User) => void;
    editConfirmationModalData: () => DeleteModelTypes;
    // onUpdate: () => void;
}
const obj: UsersDataModel = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    unit: '',
    roleId: 2,
    roleName: '',
    id: '',
    key: '',
};

const UserViewDrawer: React.FC<drawerProps> = (props) => {
    const { onClose, visible, userData, onEditCancel, editConfirmationModalData } = props;
    const { t } = useTranslation();
    const [form] = Form.useForm();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [loading, setLoading] = useState(false);
    const [selectedUser, setSelectedUser] = useState<UsersDataModel>(obj);
    const [userEditDrawerVisible, setUserEditDrawerVisible] = useState(false);

    useEffect(() => {
        setSelectedUser(userData);
    }, [props]);

    const setDrawerHeader = () => {
        if (selectedUser.firstName != null && selectedUser.lastName != null) {
            return (
                <div className="userView-drawer-title">
                    <div>
                        <Avatar size="large" className="userView-drawer-avatar">
                            {selectedUser.firstName.charAt(0)}
                            {selectedUser.lastName.charAt(0)}
                        </Avatar>
                        <div style={{ paddingTop: '10px' }}>{`${selectedUser.firstName} ${selectedUser.lastName}`}</div>
                    </div>
                </div>
            );
        }
        if (selectedUser.firstName === '' && selectedUser.lastName === '') {
            return (
                <div className="userView-drawer-title">
                    <Avatar size="large" icon={<UserOutlined />} />
                </div>
            );
        }
        return (
            <div className="userView-drawer-title">
                <Avatar size="large" icon={<UserOutlined />} />
            </div>
        );
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const userEditDrawerOnClose = (userEditForm: any) => {
        setUserEditDrawerVisible(false);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        userEditForm.resetFields(['email', 'institution', 'unit', 'role']);
    };

    return (
        <div>
            <Drawer title={setDrawerHeader()} width={450} onClose={onClose} visible={visible}>
                {!loading ? (
                    <Form layout="vertical" form={form} className="userView-drawer-form">
                        <Row gutter={16} className="userView-drawer-form-row">
                            <Col span={24}>
                                <Row>
                                    <Col span={12}>
                                        <div className="userView-drawer-form-label">
                                            {t('userViewDrawer.firstName')}
                                        </div>
                                    </Col>
                                    <Col span={12}>
                                        <div className="userView-drawer-form-label">
                                            {selectedUser.firstName || 'N/A'}
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row gutter={16} className="userView-drawer-form-row">
                            <Col span={24}>
                                <Row>
                                    <Col span={12}>
                                        <div className="userView-drawer-form-label">{t('userViewDrawer.lastName')}</div>
                                    </Col>
                                    <Col span={12}>
                                        <div className="userView-drawer-form-label">
                                            {selectedUser.lastName || 'N/A'}
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row gutter={16} className="userView-drawer-form-row">
                            <Col span={24}>
                                <Row>
                                    <Col span={12}>
                                        <div className="userView-drawer-form-label">{t('userViewDrawer.email')}</div>
                                    </Col>
                                    <Col span={12}>
                                        <div className="userView-drawer-form-label">{selectedUser.email || 'N/A'}</div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row gutter={16} className="contact-drawer-form-row">
                            <Col span={24}>
                                <Row>
                                    <Col span={12}>
                                        <div className="contact-drawer-form-label">{t('userViewDrawer.role')}</div>
                                    </Col>
                                    <Col span={12}>
                                        <div className="contact-drawer-form-label">
                                            {selectedUser.roleName || 'N/A'}
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                ) : (
                    <Loading />
                )}
            </Drawer>
            <UserEditDrawer
                visible={userEditDrawerVisible}
                userData={userData}
                onCancel={() => onEditCancel(selectedUser.id, selectedUser)}
                onClose={userEditDrawerOnClose}
            />
            <ConfirmationModal
                cancelButtonText={editConfirmationModalData().cancelButtonText}
                title={editConfirmationModalData().title}
                modalBodyText={editConfirmationModalData().modalBodyText}
                okButtonText={editConfirmationModalData().okButtonText}
                visible={editConfirmationModalData().visible}
                confirmLoading={editConfirmationModalData().confirmLoading}
                handleOk={editConfirmationModalData().handleOk}
                handleCancel={editConfirmationModalData().handleCancel}
                setConformationText={editConfirmationModalData().setConformationText}
            />
        </div>
    );
};

export default UserViewDrawer;
