/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Input } from 'antd';
import ReactInputMask from 'react-input-mask';

type maskInputProps = {
    value: any;
    onChange: (e: any) => void;
    mask: any;
    placeholder: any;
};

// eslint-disable-next-line arrow-body-style
const MaskInput: React.FC<maskInputProps> = (props: any) => {
    // console.log(props);
    return <ReactInputMask {...props}>{(inputProps: any) => <Input {...inputProps} />}</ReactInputMask>;
};

export default MaskInput;
