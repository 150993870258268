import React, { useState, useEffect } from 'react';
import { Table, Space, Input, Row, Col, Button, Tooltip } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { MyToken, NotificationType, Role, User, UsersDataModel } from '../../../types/global';
import AddUserDrawer from '../../addUserDrawer/addUserDrawer';
import UserViewDrawer from '../../userViewDrawer/userViewDrawer';
import UserEditDrawer from '../../usereditDrawer/userEditDrawer';
import '../index.scss';
import AuthService from '../../../services/identity/authService';
import TenantService from '../../../services/tenant';
import getUserType from '../../../utils/Common/GetRoleType';
import ConfirmationModal, { DeleteModelTypes } from '../../ConfirmationModal/ConfirmationModal';
import UserService from '../../../services/user';
import openNotification from '../../../utils/notification/notification';
import { jwtDecode } from 'jwt-decode';

interface userTableModel {
    addUserDrawerVisible: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    addUserDrawerOnClose: React.Dispatch<React.SetStateAction<boolean>>;
}

const UserListTable: React.FC<userTableModel> = (props) => {
    const { addUserDrawerVisible, addUserDrawerOnClose } = props;
    const [dataSource, setDataSource] = useState<Array<UsersDataModel>>([]);
    const [duplicateDataSource, setDuplicateDataSource] = useState<Array<UsersDataModel>>([]);
    const [currentUserEmail, setCurrentUserEmail] = useState('');
    const [loading, setLoading] = useState(true);
    const authService = new AuthService();
    const { t } = useTranslation();
    const [userData, setUserData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        institution: '',
        unit: '',
        id: '',
        roleId: 0,
        roleName: '',
        key: '',
    } as UsersDataModel);

    const [userViewerDrawVisible, setUserViewerDrawVisible] = useState(false);
    const [userEditDrawerVisible, setUserEditDrawerVisible] = useState(false);

    const [modalVisibility, setModalVisibility] = useState({
        deleteModalVisible: false,
        deleteConformationModalVisible: false,
        discardModalVisible: false,
    });

    const [deleteModalConfirmLoading, setDeleteModalConfirmLoading] = useState(false);
    const [deleteConformationText, setDeleteConformationText] = useState('');

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onCloseDrawer = (myForm: any, id?: string, user?: User) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        myForm.resetFields(['firstName', 'lastName', 'email', 'role', 'institution', 'unit']);
        if (id !== undefined) {
            setDataSource((prevState) => [
                {
                    id,
                    firstName: user?.firstName || '',
                    lastName: user?.lastName || '',
                    email: user?.email || '',
                    roleName: user?.roleId === Role.ADMIN ? 'Admin' : 'Researcher',
                    institution: '',
                    unit: '',
                    key: '',
                    phone: '',
                    roleId: user?.roleId || 0,
                },
                ...prevState,
            ]);
            setDuplicateDataSource((prevState) => [
                {
                    id,
                    firstName: user?.firstName || '',
                    lastName: user?.lastName || '',
                    email: user?.email || '',
                    roleName: user?.roleId === Role.ADMIN ? 'Admin' : 'Researcher',
                    institution: '',
                    unit: '',
                    key: '',
                    phone: '',
                    roleId: user?.roleId || 0,
                },
                ...prevState,
            ]);
        }
        addUserDrawerOnClose(false);
    };

    const fetch = () => {
        authService
            .getUser()
            .then((user) => {
                const token: string = user?.access_token || '';
                const decoded: MyToken = jwtDecode(token);
                setCurrentUserEmail(decoded.email);
                TenantService.GetUsers(token)
                    .then((response) => {
                        setDataSource(response.data.data);
                        setDuplicateDataSource(response.data.data);
                        setLoading(false);
                    })
                    .catch((error) => {
                        console.log(error);
                        setDataSource([]);
                        setLoading(false);
                    });
            })
            .catch((error) => {
                console.log(error);
                setDataSource([]);
                setLoading(false);
            });
    };

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        fetch();
    }, []);

    const columns: ColumnsType<UsersDataModel> = [
        {
            title: t('users.table.firstName'),
            dataIndex: 'firstName',
            key: 'firstName',
            className: 'firstNameColumn',
            width: '15%',
            sorter: (a, b) => {
                if (a.firstName !== null && b.firstName !== null) {
                    return a.firstName.localeCompare(b.firstName);
                }
                const c = 'N';
                const d = 'N';
                return c.localeCompare(d);
            },
            sortDirections: ['ascend', 'descend'],
            render: (state) => {
                if (state === null) {
                    return 'N/A';
                }
                // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                return state;
            },
        },
        {
            title: t('users.table.lastName'),
            dataIndex: 'lastName',
            key: 'lastName',
            className: 'lastNameColumn',
            width: '15%',
            sorter: (a, b) => {
                if (a.lastName !== null && b.lastName !== null) {
                    return a.lastName.localeCompare(b.lastName);
                }
                const c = 'N';
                const d = 'N';
                return c.localeCompare(d);
            },
            sortDirections: ['ascend', 'descend'],
            render: (state) => {
                if (state === null) {
                    return 'N/A';
                }
                // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                return state;
            },
        },
        {
            title: t('users.table.email'),
            dataIndex: 'email',
            key: 'email',
            className: 'emailColumn',
            width: '25%',
            sorter: (a, b) => {
                if (a.email !== null && b.email !== null) {
                    return a.email.localeCompare(b.email);
                }
                const c = 'N';
                const d = 'N';
                return c.localeCompare(d);
            },
            sortDirections: ['ascend', 'descend'],
            render: (state) => {
                if (state === null) {
                    return 'N/A';
                }
                // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                return state;
            },
        },
        {
            title: t('users.table.role'),
            dataIndex: 'roleName',
            key: 'roleName',
            className: 'roleColumn',
            width: '10%',
            sorter: (a, b) => {
                if (a.roleId !== null && b.roleId !== null) {
                    return a.roleId - b.roleId;
                }
                return 0;
            },
            sortDirections: ['ascend', 'descend'],
            render: (state) => {
                if (state === null) {
                    return 'N/A';
                }
                // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                return state;
            },
        },
        {
            title: t('users.table.action'),
            key: 'action',
            align: 'center',
            render: (recode) => (
                <Space size={60}>
                    <Tooltip placement="bottom" title="Edit user">
                        <Button
                            icon={<FontAwesomeIcon icon="edit" className="userList-table-icon" />}
                            className="userList-table-link-button "
                            onClick={() => {
                                setUserData(recode);
                                setUserEditDrawerVisible(true);
                            }}
                        />
                    </Tooltip>
                    <Tooltip placement="bottom" title="View user">
                        <Button
                            icon={<FontAwesomeIcon icon="eye" className="userList-table-icon" />}
                            className="userList-table-link-button "
                            onClick={() => {
                                setUserData(recode);
                                setUserViewerDrawVisible(true);
                            }}
                        />
                    </Tooltip>

                    {currentUserEmail === recode.email ? (
                        <Tooltip
                            placement="bottom"
                            title={<div style={{ textAlign: 'center' }}>Deleting your own account is not allowed</div>}
                        >
                            <div style={{ width: '32px' }}>
                                <FontAwesomeIcon icon="trash" style={{ opacity: '0.5', marginRight: '5px' }} />
                            </div>
                        </Tooltip>
                    ) : (
                        <Tooltip placement="bottom" title="Delete user">
                            <Button
                                icon={<FontAwesomeIcon icon="trash" className="userList-table-icon" />}
                                className="userList-table-link-button "
                                disabled={currentUserEmail === recode.email}
                                onClick={() => {
                                    setUserData(recode);
                                    setModalVisibility((prev) => ({ ...prev, deleteModalVisible: true }));
                                }}
                            />
                        </Tooltip>
                    )}
                </Space>
            ),
        },
    ];

    const onSearch = (e: string) => {
        if (e === '') {
            setDataSource(duplicateDataSource);
        } else {
            const userTableData = duplicateDataSource;
            const filterData = userTableData.filter(
                (entry: UsersDataModel) =>
                    entry?.firstName?.toLowerCase().includes(e?.toLowerCase()) ||
                    entry?.lastName?.toLowerCase().includes(e?.toLowerCase()) ||
                    entry?.email?.toLowerCase().includes(e?.toLowerCase()),
            );
            setDataSource(filterData);
        }
    };

    const onCloseUserViewDrawer = () => {
        setUserViewerDrawVisible(false);
    };

    const deleteModalHandleOk = (): void => {
        setDeleteModalConfirmLoading(true);
        UserService.DeleteUser(userData.id)
            .then(() => {
                const tempDataSource = dataSource;
                const filteredData = tempDataSource.filter((item) => item.id !== userData.id);
                setDataSource(filteredData);

                const tempDuplicateDataSource = duplicateDataSource;
                const duplicateFilteredData = tempDuplicateDataSource.filter((item) => item.id !== userData.id);
                setDuplicateDataSource(duplicateFilteredData);

                setModalVisibility((prev) => ({
                    ...prev,
                    deleteModalVisible: false,
                    deleteConformationModalVisible: true,
                }));
                openNotification(NotificationType.success, 'User Deleted Successfully', '');
            })
            .catch((err) => {
                openNotification(
                    NotificationType.error,
                    'Failed to delete the user, Please try again later',
                    err?.message,
                );
            })
            .finally(() => {
                setDeleteModalConfirmLoading(false);
            });
    };
    const deleteModalHandleCancel = () => {
        setModalVisibility((prev) => ({
            ...prev,
            deleteModalVisible: false,
        }));
    };
    const onCancel = (id: string, user: User) => {
        const editingUser = dataSource.find((item) => item.id === id);
        if (
            editingUser?.firstName !== user.firstName ||
            editingUser?.lastName !== user.lastName ||
            editingUser?.roleId !== user.roleId
        ) {
            // discard popup
            setModalVisibility((prev) => ({
                ...prev,
                discardModalVisible: true,
            }));
        } else {
            setUserEditDrawerVisible(false);
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const userEditDrawerOnClose = (userEditForm: any, id?: string, user?: User) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        userEditForm.resetFields(['firstName', 'lastName', 'email', 'role', 'institution', 'unit']);
        if (id !== undefined) {
            const tempDataSource = dataSource;
            const index = tempDataSource.findIndex((item) => item.id === id);
            tempDataSource[index].firstName = user?.firstName || '';
            tempDataSource[index].lastName = user?.lastName || '';
            tempDataSource[index].roleId = user?.roleId || 0;
            tempDataSource[index].roleName = getUserType(user?.roleId || 0);
            setDataSource(tempDataSource);

            const tempDuplicateDataSource = duplicateDataSource;
            const duplicateIndex = tempDuplicateDataSource.findIndex((item) => item.id === id);
            tempDuplicateDataSource[duplicateIndex].firstName = user?.firstName || '';
            tempDuplicateDataSource[duplicateIndex].lastName = user?.lastName || '';
            tempDuplicateDataSource[duplicateIndex].roleId = user?.roleId || 0;
            tempDuplicateDataSource[duplicateIndex].roleName = getUserType(user?.roleId || 0);
            setDuplicateDataSource(tempDuplicateDataSource);
        }

        setUserEditDrawerVisible(false);
    };

    const ConfirmationModalData = (): DeleteModelTypes => {
        if (modalVisibility.deleteModalVisible) {
            return {
                title: t('global.userDelete.title'),
                modalBodyText: t('global.userDelete.messageText'),
                okButtonText: t('global.userDelete.okButtonText'),
                visible: modalVisibility.deleteModalVisible,
                confirmLoading: deleteModalConfirmLoading,
                cancelButtonText: t('global.userDelete.cancelButtonText'),
                handleOk: deleteModalHandleOk,
                handleCancel: deleteModalHandleCancel,
                setConformationText: setDeleteConformationText,
            };
        }

        return {
            title: t('global.discardChanges.title'),
            modalBodyText: t('global.discardChanges.messageText'),
            okButtonText: t('global.discardChanges.okButtonText'),
            cancelButtonText: t('global.discardChanges.cancelButtonText'),
            visible: modalVisibility.discardModalVisible,
            confirmLoading: false,
            handleOk: () => {
                setUserEditDrawerVisible(false);
                setModalVisibility((prev) => ({
                    ...prev,
                    discardModalVisible: false,
                }));
            },
            handleCancel: () => {
                setUserEditDrawerVisible(true);
                setModalVisibility((prev) => ({
                    ...prev,
                    discardModalVisible: false,
                }));
            },
            setConformationText: setDeleteConformationText,
        };
    };

    useEffect(() => {
        ConfirmationModalData();
    }, [modalVisibility]);

    return (
        <div className="userList-root">
            <Table<UsersDataModel>
                columns={columns}
                dataSource={dataSource}
                rowKey="id"
                loading={loading}
                pagination={{ hideOnSinglePage: true }}
                title={() => (
                    <Row>
                        <Col span={6}>
                            <Input
                                placeholder={t('global.searchPlaceHolder')}
                                prefix={<SearchOutlined />}
                                allowClear
                                onChange={(e) => {
                                    onSearch((e.target as HTMLInputElement).value);
                                }}
                                onPressEnter={(e) => {
                                    onSearch((e.target as HTMLInputElement).value);
                                }}
                            />
                        </Col>
                    </Row>
                )}
            />
            <AddUserDrawer visible={addUserDrawerVisible} onClose={onCloseDrawer} />
            <UserViewDrawer
                visible={userViewerDrawVisible}
                onClose={onCloseUserViewDrawer}
                editConfirmationModalData={ConfirmationModalData}
                onEditCancel={onCancel}
                userData={userData}
            />
            <UserEditDrawer
                visible={userEditDrawerVisible}
                onCancel={onCancel}
                onClose={userEditDrawerOnClose}
                userData={userData}
            />
            <ConfirmationModal
                cancelButtonText={ConfirmationModalData().cancelButtonText}
                title={ConfirmationModalData().title}
                modalBodyText={ConfirmationModalData().modalBodyText}
                okButtonText={ConfirmationModalData().okButtonText}
                visible={ConfirmationModalData().visible}
                confirmLoading={ConfirmationModalData().confirmLoading}
                handleOk={ConfirmationModalData().handleOk}
                handleCancel={ConfirmationModalData().handleCancel}
                setConformationText={ConfirmationModalData().setConformationText}
            />
        </div>
    );
};

export default UserListTable;
