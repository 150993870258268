import { AxiosRequestConfig, AxiosResponse } from 'axios';
import axiosInstance from '../../utils/axios/axiosInstance';
import { ENDPOINTS } from './contract';
import { APIResponse, UpdateUser, User } from '../../types/global';

class UserService {
    public static async AddUser(user: User): Promise<AxiosResponse> {
        const requestConfig: AxiosRequestConfig = {
            method: 'POST',
            url: `${ENDPOINTS.USER_BASE_URL}`,
            headers: {
                'Content-Type': 'application/json',
            },
            data: user,
        };

        const response: AxiosResponse = await axiosInstance(requestConfig);
        return response;
    }

    public static async UpdateUser(userId: string, user: UpdateUser): Promise<AxiosResponse> {
        const requestConfig: AxiosRequestConfig = {
            method: 'PUT',
            url: `${ENDPOINTS.USER_BASE_URL}/${userId}`,
            headers: {
                'Content-Type': 'application/json',
            },
            data: user,
        };

        const response: AxiosResponse = await axiosInstance(requestConfig);
        return response;
    }

    public static async DeleteUser(userId: string): Promise<AxiosResponse> {
        const requestConfig: AxiosRequestConfig = {
            method: 'DELETE',
            url: `${ENDPOINTS.USER_BASE_URL}/${userId}`,
            headers: {
                'Content-Type': 'application/json',
            },
        };

        const response: AxiosResponse = await axiosInstance(requestConfig);
        return response;
    }

    public static async GetMyDetails(): Promise<AxiosResponse<APIResponse<UpdateUser>>> {
        const requestConfig: AxiosRequestConfig = {
            method: 'GET',
            url: `${ENDPOINTS.USER_BASE_URL}/me`,
            headers: {
                'Content-Type': 'application/json',
            },
        };

        const response: AxiosResponse = await axiosInstance(requestConfig);
        return response;
    }
}

export default UserService;
