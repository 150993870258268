/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { studyRequestBody } from '../../types/global';
import axiosInstance from '../../utils/axios/axiosInstance';
import { ENDPOINTS } from './contracts';

class StudyService {
    public static async getAllStudies(idToken: string): Promise<AxiosResponse> {
        const requestConfig: AxiosRequestConfig = {
            method: 'get',
            url: `${ENDPOINTS.GET_STUDIES}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${idToken}`,
            },
        };
        const response: AxiosResponse = await axiosInstance(requestConfig);

        return response;
    }

    public static async getStudy(idToken: string, studyId: string): Promise<AxiosResponse> {
        const requestConfig: AxiosRequestConfig = {
            method: 'get',
            url: `${ENDPOINTS.GET_STUDIES}/${studyId}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${idToken}`,
            },
        };

        const response: AxiosResponse = await axiosInstance(requestConfig);

        return response;
    }

    public static async getDefaultDetails(idToken: string): Promise<AxiosResponse> {
        const requestConfig: AxiosRequestConfig = {
            method: 'get',
            url: `${ENDPOINTS.GET_DEFAULT_STUDIES}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${idToken}`,
            },
        };
        const response: AxiosResponse = await axiosInstance(requestConfig);
        return response;
    }

    public static async createStudy(idToken: string, request: studyRequestBody): Promise<AxiosResponse> {
        const requestConfig: AxiosRequestConfig = {
            method: 'post',
            url: `${ENDPOINTS.CREATE_STUDY}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${idToken}`,
            },
            data: request,
        };
        // console.log(requestConfig);

        const response: AxiosResponse = await axiosInstance(requestConfig);
        return response;
    }

    public static async updateStudy(
        idToken: string,
        request: studyRequestBody,
        studyId: string,
    ): Promise<AxiosResponse> {
        const requestConfig: AxiosRequestConfig = {
            method: 'put',
            url: `${ENDPOINTS.CREATE_STUDY}/${studyId}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${idToken}`,
            },
            data: request,
        };
        // console.log(requestConfig);
        const response: AxiosResponse = await axiosInstance(requestConfig);
        return response;
    }

    public static async publishStudy(idToken: string, studyId: string): Promise<AxiosResponse> {
        const requestConfig: AxiosRequestConfig = {
            method: 'patch',
            url: `${ENDPOINTS.PUBLISH_STUDY}/${studyId}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${idToken}`,
            },
        };
        const response: AxiosResponse = await axiosInstance(requestConfig);
        return response;
    }

    public static async deleteStudy(idToken: string, studyId: string): Promise<AxiosResponse> {
        const requestConfig: AxiosRequestConfig = {
            method: 'delete',
            url: `${ENDPOINTS.DELETE_STUDY}/${studyId}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${idToken}`,
            },
        };
        const response: AxiosResponse = await axiosInstance(requestConfig);
        return response;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public static async uploadFiles(requestConfig: any): Promise<AxiosResponse> {
        const response: AxiosResponse = await axiosInstance(requestConfig);
        return response;
    }

    public static async removeFiles(url: string, idToken: string): Promise<AxiosResponse> {
        const bodyFormData = new FormData();
        bodyFormData.append('fileUrl', url);
        const requestConfig: AxiosRequestConfig = {
            method: 'delete',
            url: `${ENDPOINTS.DELETE_FILE}`,
            headers: {
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${idToken}`,
            },

            data: bodyFormData,
        };
        const response: AxiosResponse = await axiosInstance(requestConfig);
        return response;
    }
}
export default StudyService;
