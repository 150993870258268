/* eslint-disable @typescript-eslint/no-explicit-any */
export type consentArrayInResponse = {
    consentText: string;
    createdBy: string;
    createdOn: string;
    deletedDate: string;
    id: string;
    institute: string;
    isDeleted: boolean;
    lastModifiedBy: string;
    lastModifiedOn: string;
    participantConsents: [];
    status: number;
    studyId: string;
    tenant: any;
    tenantId: string;
    unit: string;
};

export type MyToken = {
    email: string;
    role: string;
    emails: string;
    tenant_id: string;
};

export type studyDataModal = {
    consents: Array<consentArrayInResponse>;
    createdBy: string;
    createdOn: string;
    deletedDate: null;
    filesInfo: [];
    id: string;
    institute: string;
    introductionText: string;
    isDeleted: boolean;
    isEditDisable: boolean;
    lastModifiedBy: string;
    lastModifiedOn: string | null;
    link: string;
    numberOfConsents: number;
    numberOfParticipants: number;
    participants: [];
    participationInfo: string;
    status: number;
    storageId: string;
    studyName: string;
    tenant: any;
    tenantId: string;
    unit: string;
    key?: string;
};

export interface ParticipantDataModel {
    authorisationCode: string;
    city: string;
    consentActionDate: string;
    createdBy: string;
    createdOn: string;
    deletedDate: any;
    email: string;
    emailContent: any;
    emailToken: any;
    firstName: string;
    id: string;
    institute: any;
    isBankIdVerified: boolean;
    isDeleted: boolean;
    lastModifiedBy: string;
    lastModifiedOn: string;
    lastName: string;
    maskedPersonalId: string;
    mobileNumber: string;
    participantConsents: [];
    personalId: string;
    postalCode: string;
    smsContent: any;
    smsToken: any;
    status: number;
    streetAddress: string;
    study: any;
    studyId: string;
    tenant: any;
    tenantId: string;
    unit: any;
}

export type User = {
    firstName: string;
    lastName: string;
    email: string;
    roleId: number;
};

export type UpdateUser = {
    id?: string;
    firstName: string;
    lastName: string;
    mobileNumber?: string;
    streetAddress?: string;
    postalCode?: string;
    city?: string;
    roleId?: number;
};

export type APIResponse<T> = {
    data: T;
    isSuccess: boolean;
    message: any;
    paging: any;
    responseCode: string | null;
    subResponseCodes: null | Array<string>;
};

export type RoleModel = {
    id: number;
    roleName: string;
};

export type pdfDataModel = {
    studyName: string;
    participantName: string;
    email: string;
    mobileNumber?: string;
    personalId: string;
    consentQuestions: [];
    created: string;
    authorisationCode: string;
};

export type UsersDataModel = {
    firstName: string;
    lastName: string;
    email: string;
    streetAddress?: string;
    postalCode?: string;
    phone?: string;
    roleName: string;
    roleId: number;
    unit: string;
    id: string;
    key?: string;
};

export type studyTemplateObject = {
    createdBy: string;
    createdOn: string;
    deletedDate: any;
    id: string;
    institute: string;
    isDeleted: boolean;
    key: string;
    lastModifiedBy: any;
    lastModifiedOn: any;
    tenant: any;
    tenantId: string;
    unit: string;
    value: string;
};

export type researcherProfileFormValues = {
    firstName: string;
    lastName: string;
    email: string;
    institution: string;
    unit: string;
    role: string;
    mobileNumber: string;
    streetAddress: string;
    postalAddress: string;
    postalCode: string;
};

export type identityRegistrationFormValues = {
    email: string;
    password: string;
    confirmPassword: string;
};

/* export type researcherProfileUpdateValues = {
    firstName: string;
    lastName: string;
    email: string;
    mobileNumber: string;
    postalAddress: string;
    postalCode: string;
    streetAddress: string;
}; */

/* export type updateUserDetailsReqBody = {
    user_metadata: researcherProfileFormValues | researcherProfileUpdateValues;
}; */

export type consentDataObject = {
    key: number;
    consentId: string;
    consentNumber: string;
    consent: string;
};

export type consentObjectInResponse = {
    consentText: string;
    createdBy: string;
    createdOn: string;
    deletedDate: any;
    id: string;
    institute: any;
    isDeleted: boolean;
    lastModifiedBy: string;
    lastModifiedOn: string;
    participantConsents: [];
    status: number;
    studyId: string;
    tenant: any;
    tenantId: string;
    unit: any;
};

export type fileArrayObjectInResponse = {
    key: string;
    value: string;
};

export type uploadFileArrayObject = {
    key: string;
    uid: string;
    name: string;
    url: string;
};

export type studyResponseObject = {
    consents: Array<consentObjectInResponse>;
    createdBy: string;
    createdOn: string;
    deletedDate: any;
    filesInfo: Array<fileArrayObjectInResponse>;
    id: string;
    institute: any;
    introductionText: string;
    isDeleted: boolean;
    isEditDisable: boolean;
    lastModifiedBy: string;
    lastModifiedOn: string;
    link: any;
    numberOfConsents: number;
    numberOfParticipants: number;
    participants: Array<any>;
    participationInfo: string;
    status: number;
    storageId: string;
    studyName: string;
    tenant: any;
    tenantId: string;
    unit: any;
};

export type consentArrayForReqBody = {
    id?: string;
    consentText?: string;
};

export type studyRequestBody = {
    studyName: string;
    participationInfo: string;
    status: number;
    consents: Array<consentArrayForReqBody>;
    introductionText: string;
    storageId: string;
};

export type addParticipantRequestBody = {
    personalId: string;
    studyId?: string;
    email?: string | undefined;
    mobileNumber?: string | undefined;
};

export type inviteResponse = {
    data: ParticipantDataModel;
    isSuccess: boolean;
    message: any;
    paging: any;
    responseCode: string | null;
    subResponseCodes: null | Array<string>;
};
export type duplicateArrayResponse = {
    participant: ParticipantDataModel;
    responseCode: string;
    subResponseCodes: Array<string>;
};
export type duplicateResponse = {
    data: Array<duplicateArrayResponse> | null;
    isSuccess: boolean;
    message: null;
    paging: null;
    responseCode: null;
    subResponseCodes: null;
};

export enum studyTemplateKey {
    REMINDER_EMAIL = 'STUDY_PARTICIPANT_REMINDER_EMAIL',
    INVITE_EMAIL = 'STUDY_PARTICIPANT_INVITE_EMAIL',
    CONSENT_1 = 'DEFAULT_CONSENT_1',
    CONSENT_2 = 'DEFAULT_CONSENT_2',
    PARTICIPANT_INFO = 'PARTICIPANT_INFO',
    STUDY_INTRODUCTION_TEXT = 'STUDY_INTRODUCTION_TEXT',
}

export enum roles {
    ADMIN = 'Admin',
    RESEARCHER = 'Researcher',
}

export enum NotificationType {
    success = 'success',
    warning = 'warning',
    error = 'error',
}

export enum appMode {
    AUTH = 'Auth',
    APP = 'App',
}

export enum studyStatus {
    PUBLISHED = 1,
    DRAFT = 0,
}
export enum participantStatus {
    PENDING = 0,
    VIEWED = 1,
    ACCEPT = 2,
    DECLINED = 3,
}
export enum invitationValidateStatus {
    RECODE_EXIST = '708',
    EMAIL_EXIST = '709',
    MOBILE_NUMBER_EXIST = '710',
    PERSONAL_ID_EXIST = '711',
}

export enum registrationValidateStatus {
    REGISTRATION_SUCCESS = '200',
    REGISTRATION_FAIL = '400',
}

export enum Role {
    ADMIN = 1,
    RESEARCHER = 2,
}

export enum MessageType {
    Email = 0,
    SMS = 1,
    Both = 2,
}

export type updateContactInfoRequestBody = {
    id: string;
    email?: string | null;
    mobileNumber?: string | null;
    studyId?: string | undefined;
};

type ParticipantInfo = {
    email: string;
    mobileNumber: string | null;
    studyId: string;
    id: string;
};

export type updateContactInfoResponse = {
    data: ParticipantInfo | null;
    isSuccess: boolean;
    message: string | null;
    paging: string | null;
    responseCode: string;
    subResponseCodes: string | null;
};

export type sendReminderRequestBody = {
    ids: string[] | React.Key[];
    messageType: number;
};
