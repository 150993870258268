/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useState } from 'react';
import AuthService from '../services/identity/authService';
import AuthContext from '../utils/context/auth-context';
import UserService from '../services/user';
import { UpdateUser } from '../types/global';

const AuthProvider: React.FC = ({ children }) => {
    const authService = new AuthService();
    const [role, setRole] = useState('');
    const [avatarText, setAvatarText] = useState('');
    const [headerName, setHeaderName] = useState('');

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const setValues = (response: UpdateUser, userEmail: string) => {
        try {
            if (response.firstName) {
                setAvatarText(response.firstName.charAt(0).toUpperCase());
            }
            if (userEmail) {
                setHeaderName(userEmail);
            }
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        const fetch = async () => {
            try {
                const user = await authService.getUser();
                const userEmail: string = user?.profile.email || '';
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                const userRole = user?.profile.role || '';
                const response = await UserService.GetMyDetails();
                setRole(userRole);
                setValues(response.data.data, userEmail);
            } catch (error) {
                console.log(error);
            }
        };
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        fetch();
    }, []);

    const authContextObj = {
        isAuth: false,
        role,
        avatarText,
        headerName,
    };

    return <AuthContext.Provider value={authContextObj}> {children}</AuthContext.Provider>;
};

export default AuthProvider;
