import React from 'react';
import { Modal, Button, Alert, Radio, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import './index.scss';

interface ExportModalProps {
    infoMessage?: string;
    subTitle?: string;
    cancelButtonText?: string;
    downloadButtonText?: string;
    radioValue?: string;
    radioTextExcel?: string;
    radioTextPdf?: string;
    visible?: boolean;
    confirmLoading?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleOnChange?: (e: any) => void;
    handleCancel?: () => void;
    handleDownload?: () => void;
}

const ExportModal: React.FC<ExportModalProps> = ({
    infoMessage,
    subTitle,
    cancelButtonText,
    downloadButtonText,
    radioValue,
    radioTextExcel,
    radioTextPdf,
    visible,
    confirmLoading,
    handleOnChange,
    handleCancel,
    handleDownload,
}) => {
    const { t } = useTranslation();

    return (
        <div>
            <Modal
                visible={visible}
                onOk={handleDownload}
                className="export-modal"
                centered
                width={600}
                closable={false}
                footer={[
                    <div>
                        <Button onClick={handleCancel} className="export-modal-btn-cancel">
                            {cancelButtonText || t('global.cancelButton')}
                        </Button>
                        <Button
                            onClick={handleDownload}
                            type="primary"
                            className="export-modal-btn-download"
                            shape="round"
                            loading={confirmLoading}
                        >
                            {downloadButtonText}
                        </Button>
                    </div>,
                ]}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
            >
                <div className="export-modal-body-wrapper">
                    <Alert message={infoMessage} type="info" showIcon className="drawer-alert" />
                    <p className="export-modal-body-text">{subTitle}</p>
                    <Radio.Group onChange={handleOnChange} value={radioValue}>
                        <Space direction="vertical">
                            <Radio value="excel">{radioTextExcel}</Radio>
                            <Radio value="pdf">{radioTextPdf}</Radio>
                        </Space>
                    </Radio.Group>
                </div>
            </Modal>
        </div>
    );
};

export default ExportModal;
