import React from 'react';
import { Modal, Button, Input } from 'antd';
import { useTranslation } from 'react-i18next';

import './index.scss';

interface DeleteModelTypes {
    title: string;
    okButtonText: string;
    modalBodyText: string;
    visible: boolean;
    cancelButtonText: string;
    handleOk: () => void;
    confirmLoading: boolean;
    handleCancel: () => void;
    setConformationText: (value: string | ((prevVar: string) => string)) => void;
}

const ConfirmationModal: React.FC<DeleteModelTypes> = (props) => {
    const { t } = useTranslation();
    const {
        title,
        visible,
        confirmLoading,
        modalBodyText,
        okButtonText,
        cancelButtonText,
        handleOk,
        handleCancel,
        setConformationText,
    } = props;

    return (
        <div>
            <Modal
                title={[<span className="delete-conformation-modal-header-text">{title}</span>]}
                visible={visible}
                onOk={handleOk}
                maskClosable={false}
                centered
                width={450}
                closable={false}
                footer={[
                    <div>
                        <Button onClick={handleCancel} className="delete-conformation-modal-btn-cancel">
                            {cancelButtonText || t('global.cancelButton')}
                        </Button>
                        <Button
                            onClick={handleOk}
                            className="delete-conformation-modal-btn-delete"
                            loading={confirmLoading}
                        >
                            {okButtonText}
                        </Button>
                    </div>,
                ]}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
            >
                <div>
                    <p className="delete-conformation-modal-body-text">{modalBodyText}</p>
                </div>
            </Modal>
        </div>
    );
};

export default ConfirmationModal;
export type { DeleteModelTypes };
