/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/button-has-type */
/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './index.scss';

interface editorProps {
    onChange: (e: string) => void;
    savedContent: string;
}

const TextEditor: React.FC<editorProps> = ({ onChange, savedContent }) => {
    const [, setContentState] = useState();
    const [, setHtmlContent] = useState();
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const onContentStateChange = (changeContentState: any) => {
        setContentState(changeContentState);
        setHtmlContent(draftToHtml(convertToRaw(editorState.getCurrentContent())) as any);
        onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    };
    const onEditorStateChange = (changeEditorState: any) => {
        setEditorState(changeEditorState);
    };

    useEffect(() => {
        const contentBlock = htmlToDraft(savedContent);
        const contentBody = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        setEditorState(EditorState.createWithContent(contentBody));
    }, []);

    return (
        <div>
            <div>
                <Editor
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                    editorState={editorState}
                    onEditorStateChange={onEditorStateChange}
                    onContentStateChange={onContentStateChange}
                    toolbar={{
                        options: ['inline', 'blockType', 'fontSize', 'textAlign', 'link', 'history'],
                        inline: {
                            options: ['bold', 'italic', 'underline'],
                        },
                    }}
                />
            </div>
        </div>
    );
};

export default TextEditor;
