import React, { useEffect } from 'react';
import { Row, Col, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import useTitle from '../../utils/hooks/useTitle';
import StudyListTable from '../../components/studies/table/studyListTable';
import './index.scss';
import { useHistory } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Studies: React.FC = () => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { setHeaderTitle } = useTitle();
    const history = useHistory();
    const { t } = useTranslation();

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        setHeaderTitle(t('study.studyListTitle'));
    }, []);

    return (
        <div className="root">
            <Row>
                <Col span={24} style={{ textAlign: 'right' }}>
                    ``
                    <Button
                        type="primary"
                        className="studyList-btn-createStudy"
                        size="large"
                        shape="round"
                        onClick={() => {
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                            history.push('/app/studies/createstudy');
                        }}
                    >
                        {t('study.createNewStudy')}
                    </Button>
                </Col>
            </Row>
            <StudyListTable />
        </div>
    );
};

export default Studies;
