import React from 'react';
import { Layout as AntLayout } from 'antd';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Route, Switch, Redirect } from 'react-router-dom';
import { GuardProvider, GuardedRoute } from 'react-router-guards';
import AuthService from '../services/identity/authService';
// import Login from '../pages/login/login';
// import Loading from '../components/loading/loading';
import ProfileContent from '../pages/profileContent/profileContent';
// import ProfileContentAdmin from '../pages/profileContentAdmin/profileContentAdmin';
import AppHeader from '../components/header/appHeader';
import { appMode } from '../types/global';

import './index.scss';

const { Content } = AntLayout;

const AuthRoutes: React.FC = () => {
    const authService = new AuthService();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const requireLogin = async (to: any, from: any, next: any) => {
        if (to.meta.auth) {
            if (await authService.getUser()) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                next();
            } else {
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                authService.login();
            }
        } else if (await authService.getUser()) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            next.redirect('/app/studies');
        } else {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            next();
        }
    };

    return (
        <AntLayout className="app-layout-fix">
            <AntLayout className="app-layout-site">
                <AppHeader mode={appMode.AUTH as string} />
                <Content className="app-layout-content">
                    <GuardProvider guards={[requireLogin]}>
                        <Switch>
                            <GuardedRoute
                                exact
                                path="/auth/login"
                                render={() => <Redirect to="/app/studies" />}
                                meta={{ auth: true }}
                            />

                            <GuardedRoute
                                exact
                                path="/auth"
                                render={() => <Redirect to="/auth/login" />}
                                meta={{ auth: true }}
                            />
                            <GuardedRoute
                                exact
                                path="/"
                                render={() => <Redirect to="/auth/login" />}
                                meta={{ auth: true }}
                            />
                            <GuardedRoute exact path="/auth/profile" component={ProfileContent} meta={{ auth: true }} />
                            {/* <GuardedRoute
                                exact
                                path="/auth/adminprofile"
                                component={ProfileContentAdmin}
                                meta={{ auth: true }}
                            /> */}
                        </Switch>
                    </GuardProvider>
                </Content>
            </AntLayout>
        </AntLayout>
    );
};

export default AuthRoutes;
