/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from 'react';
import AuthService from './services/identity/authService';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faPlus,
    faUserFriends,
    faEdit,
    faArrowAltCircleLeft,
    faTrash,
    faEnvelopeOpen,
    faAddressCard,
    faHourglassHalf,
    faEye,
    faCheck,
    faBan,
    faCaretDown,
    faUpload,
    faExpandAlt,
    faCompressAlt,
} from '@fortawesome/free-solid-svg-icons';
import Layout from './layout/layout';
import { TitleProvider } from './providers/titleProvider';
import AuthProvider from './providers/authProvider';
import DefaultModelProvider from './providers/defaultModelProvider';

const App: React.FC = () => {
    const authService = new AuthService();
    library.add(
        faPlus,
        faUserFriends,
        faEdit,
        faArrowAltCircleLeft,
        faTrash,
        faEnvelopeOpen,
        faAddressCard,
        faHourglassHalf,
        faEye,
        faCheck,
        faBan,
        faCaretDown,
        faUpload,
        faExpandAlt,
        faCompressAlt,
    );
    const title = ' ';

    return (
        <TitleProvider headerTitle={title}>
            <AuthProvider>
                <DefaultModelProvider>
                    <Layout />
                </DefaultModelProvider>
            </AuthProvider>
        </TitleProvider>
    );
};

export default App;
