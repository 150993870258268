import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { ENDPOINTS } from './contracts';
import axiosInstance from '../../utils/axios/axiosInstance';
import { APIResponse, RoleModel } from '../../types/global';

class RoleService {
    public static async GetRoles(): Promise<AxiosResponse<APIResponse<RoleModel[]>>> {
        const requestConfig: AxiosRequestConfig = {
            method: 'GET',
            url: `${ENDPOINTS.ROLE_BASE_URL}`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                // 'Access-Control-Allow-Origin': '*',
            },
        };

        const response: AxiosResponse = await axiosInstance(requestConfig);
        return response;
    }
}

export default RoleService;
