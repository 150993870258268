import React, { useEffect, useState } from 'react';
import { Drawer, Form, Button, Col, Row, Avatar, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { UserOutlined } from '@ant-design/icons';
import Loading from '../loading/loading';
import DeleteModal from '../deleteModal/deleteModal';
import AuthService from '../../services/identity/authService';
import './index.scss';
import ParticipantService from '../../services/participant';
import { ParticipantDataModel } from '../../types/global';

type drawerProps = {
    visible: boolean;
    participantId: string;
    onClose: () => void;
    onUpdate: () => void;
};

type participantDetailsObject = {
    personalId: string;
    firstName: string;
    lastName: string;
    streetAddress: string;
    city: string;
    email: string;
    mobile: string;
    postalCode: string;
};

const ParticipantContactDrawer: React.FC<drawerProps> = ({ onClose, visible, participantId, onUpdate }) => {
    const authService = new AuthService();
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true);
    const obj = {
        personalId: '',
        firstName: '',
        lastName: '',
        streetAddress: '',
        city: '',
        email: '',
        mobile: '',
        postalCode: '',
    };
    const [participantData, setDataSource] = useState<participantDetailsObject>(obj);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [deleteModalConfirmLoading, setDeleteModalConfirmLoading] = useState(false);

    const openNotification = (type: string, message: string, err: string) => {
        if (type === 'success' || type === 'warning') notification[type]({ message, description: err });
    };

    const fetch = async () => {
        try {
            setLoading(true);
            const user = await authService.getUser();
            const token: string = user?.access_token || '';
            if (participantId) {
                const response: ParticipantDataModel = (await (
                    await ParticipantService.getParticipant(token, participantId)
                ).data) as ParticipantDataModel;
                const participant: participantDetailsObject = {
                    personalId: response.maskedPersonalId,
                    firstName: response.firstName,
                    lastName: response.lastName,
                    streetAddress: response.streetAddress,
                    city: response.city,
                    email: response.email,
                    mobile: response.mobileNumber,
                    postalCode: response.postalCode,
                };
                setDataSource(participant);
            }
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        fetch();
    }, [onClose, visible, participantId, onUpdate]);

    const onClickSubmit = () => {
        setDeleteModalVisible(true);
    };

    const deleteModalHandleOk = async () => {
        try {
            setDeleteModalConfirmLoading(true);
            const user = await authService.getUser();
            const token: string = user?.access_token || '';
            const idArray = [];
            idArray.push(participantId);
            await ParticipantService.deleteParticipant(token, idArray);
            openNotification('success', t('contactDrawer.notification.deleteSuccess'), '');
            setDeleteModalConfirmLoading(false);
            setDeleteModalVisible(false);
            onUpdate();
            onClose();
        } catch (error) {
            console.log(error);
            openNotification('warning', t('contactDrawer.notification.deleteFail'), '');
            setDeleteModalConfirmLoading(false);
            setDeleteModalVisible(false);
        }
    };
    const deleteModalHandleCancel = () => {
        setDeleteModalVisible(false);
    };

    return (
        <>
            <Drawer
                title={
                    <div className="contact-drawer-title">
                        {participantData.firstName != null && participantData.lastName != null ? (
                            <Avatar size="large" className="contact-drawer-avatar">
                                {participantData.firstName.charAt(0).toUpperCase()}
                                {participantData.lastName.charAt(0).toUpperCase()}
                            </Avatar>
                        ) : (
                            <Avatar size="large" icon={<UserOutlined />} />
                        )}
                    </div>
                }
                width={450}
                onClose={onClose}
                visible={visible}
                footer={
                    <div className="contact-drawer-footer">
                        <Row>
                            <Col span={12}>
                                <Button onClick={onClose} className="contact-drawer-btn-cancel">
                                    {t('contactDrawer.cancelButton')}
                                </Button>
                            </Col>
                            <Col span={12}>
                                <Button onClick={onClickSubmit} className="contact-drawer-btn-delete">
                                    {t('contactDrawer.deleteButton')}
                                </Button>
                            </Col>
                        </Row>
                    </div>
                }
            >
                {!loading ? (
                    <Form layout="vertical" form={form} className="contact-drawer-form">
                        <Row gutter={16} className="contact-drawer-form-row">
                            <Col span={24}>
                                <Row>
                                    <Col span={12}>
                                        <div className="contact-drawer-form-label">{t('contactDrawer.personalId')}</div>
                                    </Col>
                                    <Col span={12}>
                                        <div className="contact-drawer-form-label">{participantData.personalId}</div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row gutter={16} className="contact-drawer-form-row">
                            <Col span={24}>
                                <Row>
                                    <Col span={12}>
                                        <div className="contact-drawer-form-label">{t('contactDrawer.firstName')}</div>
                                    </Col>
                                    <Col span={12}>
                                        <div className="contact-drawer-form-label">
                                            {participantData.firstName || 'N/A'}
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row gutter={16} className="contact-drawer-form-row">
                            <Col span={24}>
                                <Row>
                                    <Col span={12}>
                                        <div className="contact-drawer-form-label">{t('contactDrawer.lastName')}</div>
                                    </Col>
                                    <Col span={12}>
                                        <div className="contact-drawer-form-label">
                                            {participantData.lastName || 'N/A'}
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row gutter={16} className="contact-drawer-form-row">
                            <Col span={24}>
                                <Row>
                                    <Col span={12}>
                                        <div className="contact-drawer-form-label">
                                            {t('contactDrawer.streetAddress')}
                                        </div>
                                    </Col>
                                    <Col span={12}>
                                        <div className="contact-drawer-form-label">
                                            {participantData.streetAddress || 'N/A'}
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row gutter={16} className="contact-drawer-form-row">
                            <Col span={24}>
                                <Row>
                                    <Col span={12}>
                                        <div className="contact-drawer-form-label">{t('contactDrawer.postalCode')}</div>
                                    </Col>
                                    <Col span={12}>
                                        <div className="contact-drawer-form-label">
                                            {participantData.postalCode || 'N/A'}
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row gutter={16} className="contact-drawer-form-row">
                            <Col span={24}>
                                <Row>
                                    <Col span={12}>
                                        <div className="contact-drawer-form-label">{t('contactDrawer.city')}</div>
                                    </Col>
                                    <Col span={12}>
                                        <div className="contact-drawer-form-label">{participantData.city || 'N/A'}</div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row gutter={16} className="contact-drawer-form-row">
                            <Col span={24}>
                                <Row>
                                    <Col span={12}>
                                        <div className="contact-drawer-form-label">{t('contactDrawer.email')}</div>
                                    </Col>
                                    <Col span={12}>
                                        <div className="contact-drawer-form-label">
                                            {participantData.email || 'N/A'}
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row gutter={16} className="contact-drawer-form-row">
                            <Col span={24}>
                                <Row>
                                    <Col span={12}>
                                        <div className="contact-drawer-form-label">
                                            {t('contactDrawer.mobileNumber')}
                                        </div>
                                    </Col>
                                    <Col span={12}>
                                        <div className="contact-drawer-form-label">
                                            {participantData.mobile || 'N/A'}
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                ) : (
                    <Loading />
                )}
            </Drawer>
            <DeleteModal
                title={t('global.participantDelete.title')}
                modalText={t('global.participantDelete.messageText')}
                visible={deleteModalVisible}
                okButtonText={t('global.participantDelete.okButtonText')}
                handleOk={deleteModalHandleOk}
                confirmLoading={deleteModalConfirmLoading}
                handleCancel={deleteModalHandleCancel}
            />
        </>
    );
};
export default ParticipantContactDrawer;
