import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Modal, Row, Table, Image } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import ReactPlayer from 'react-player';
import { studyDataModal, consentArrayInResponse, fileArrayObjectInResponse } from '../../../types/global';
import '../index.scss';
import { ENDPOINTS } from '../../../services/study/contracts';

type studyVewModalTypes = {
    handleCancel: () => void;
    modalVisible: boolean;
    studyObj: studyDataModal;
    // studyId: string;
};
type consentTable = {
    key: string;
    consentNumber: string;
    consentText: string;
};
const columns = [
    {
        title: 'Introduction text',
        dataIndex: 'consentNumber',
        width: '25%',
    },
    {
        title: 'Consent',
        dataIndex: 'consentText',
    },
];

const StudyViewModal: React.FC<studyVewModalTypes> = (props) => {
    const { studyObj, handleCancel, modalVisible } = props;
    const [tableDataSource, setTableDataSource] = useState<Array<consentTable>>();
    const [mainSection, setMainSection] = useState(true);
    const [consentSection, setConsentSection] = useState(false);
    const [studyDescriptionSection, setStudyDescriptionSection] = useState(false);
    const [filePreviewSection, setFilePreviewSection] = useState(false);
    const [{ imageFileStatus, fileSourceUrl }, setFileStatus] = useState({ imageFileStatus: false, fileSourceUrl: '' });
    const [studyDescription, setStudyDescription] = useState('');
    const [studyAttachedFiles, setStudyAttachedFiles] = useState<Array<{ key: string; value: string }>>([]);
    const { t } = useTranslation();

    const fetch = () => {
        const { introductionText } = studyObj;
        const consentArray: Array<consentArrayInResponse> = studyObj.consents;
        const consentTableData: Array<consentTable> = [];
        for (let index = 0; index < consentArray.length; index += 1) {
            const element: consentArrayInResponse = consentArray[index];
            const obj: consentTable = {
                key: element.id,
                consentNumber: `${t('studyViewModal.consentIssue')} ${index + 1}`,
                consentText: element.consentText,
            };
            consentTableData.push(obj);
        }
        const introObject: consentTable = {
            key: '0',
            consentNumber: t('studyViewModal.introductionText'),
            consentText: introductionText,
        };
        consentTableData.unshift(introObject);
        setTableDataSource(consentTableData);
    };

    useEffect(() => {
        if (Object.entries(studyObj).length !== 0) {
            fetch();
            setStudyDescription(studyObj.participationInfo);
            setStudyAttachedFiles(studyObj.filesInfo);
        }
    }, [props]);

    const clearSections = () => {
        setTableDataSource([]);
        setMainSection(true);
        setConsentSection(false);
        setStudyDescriptionSection(false);
        setFilePreviewSection(false);
        setFileStatus((prevState) => ({
            ...prevState,
            imageFileStatus: false,
            fileSourceUrl: '',
        }));
        setStudyDescription('');
        setStudyAttachedFiles([]);
    };

    const onClickFileLink = (item: { key: string; value: string }) => {
        if (item.value === '.jpg' || item.value === '.jpeg' || item.value === '.png' || item.value === '.jfif') {
            setFileStatus((prevState) => ({
                ...prevState,
                imageFileStatus: true,
                fileSourceUrl: item.key,
            }));
            setMainSection(false);
            setFilePreviewSection(true);
        }
        if (item.value === '.mp4') {
            setFileStatus((prevState) => ({
                ...prevState,
                imageFileStatus: false,
                fileSourceUrl: item.key,
            }));
            setMainSection(false);
            setFilePreviewSection(true);
        }
    };

    const uploadFileNameMaker = (item: fileArrayObjectInResponse) => {
        const fileUrl = item.key.split('_');
        const fileNameArray: string[] = [];
        for (let index = 1; index < fileUrl.length; index += 1) {
            fileNameArray.push(fileUrl[index]);
        }
        const fileName = decodeURIComponent(fileNameArray.toString()).replaceAll(',', '_');
        return fileName;
    };

    return (
        <div>
            <Modal
                title={t('studyViewModal.title')}
                visible={modalVisible}
                footer={null}
                centered
                closable
                onCancel={() => {
                    clearSections();
                    handleCancel();
                }}
                width={1080}
            >
                <div>
                    <div>
                        {mainSection && (
                            <Row gutter={20}>
                                <Col span={12}>
                                    <Row>
                                        <Col span={24}>
                                            <Card title={t('studyViewModal.studyName')} size="small" bordered={false}>
                                                {studyObj.studyName}
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <Card
                                                title={t('studyViewModal.informedConsent')}
                                                size="small"
                                                bordered={false}
                                                extra={
                                                    <Button
                                                        type="text"
                                                        size="small"
                                                        icon={<FontAwesomeIcon icon="expand-alt" />}
                                                        onClick={() => {
                                                            setMainSection(false);
                                                            setConsentSection(true);
                                                        }}
                                                    />
                                                }
                                            >
                                                <Col span={24} className="studyView-studyInfo-content-div">
                                                    <Table
                                                        showHeader={false}
                                                        dataSource={tableDataSource}
                                                        columns={columns}
                                                        pagination={false}
                                                    />
                                                </Col>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={12}>
                                    <Row>
                                        <Col span={24}>
                                            <Card
                                                title={t('studyViewModal.attachedImageOrVideo')}
                                                size="small"
                                                bordered={false}
                                            >
                                                <div>
                                                    {studyAttachedFiles.map((item: fileArrayObjectInResponse) => (
                                                        <Button
                                                            type="link"
                                                            onClick={() => {
                                                                onClickFileLink(item);
                                                            }}
                                                        >
                                                            {uploadFileNameMaker(item)}
                                                        </Button>
                                                    ))}
                                                </div>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <Card
                                                title={t('studyViewModal.participantInfo')}
                                                size="small"
                                                bordered={false}
                                                extra={
                                                    <Button
                                                        type="text"
                                                        size="small"
                                                        icon={<FontAwesomeIcon icon="expand-alt" />}
                                                        onClick={() => {
                                                            setMainSection(false);
                                                            setStudyDescriptionSection(true);
                                                        }}
                                                    />
                                                }
                                            >
                                                <Col span={24} className="studyView-studyInfo-content">
                                                    <div className="studyView-studyInfo-content-div">
                                                        {parse(studyDescription)}
                                                    </div>
                                                </Col>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        )}
                    </div>

                    <div>
                        {consentSection && (
                            <Row>
                                <Col span={24}>
                                    <Card
                                        title={t('studyViewModal.informedConsent')}
                                        size="small"
                                        bordered={false}
                                        extra={
                                            <Button
                                                type="text"
                                                size="small"
                                                icon={<FontAwesomeIcon icon="compress-alt" />}
                                                onClick={() => {
                                                    setConsentSection(false);
                                                    setMainSection(true);
                                                }}
                                            />
                                        }
                                    >
                                        <Col span={24} className="studyView-studyInfo-content-div-expand">
                                            <Table
                                                showHeader={false}
                                                dataSource={tableDataSource}
                                                columns={columns}
                                                pagination={false}
                                            />
                                        </Col>
                                    </Card>
                                </Col>
                            </Row>
                        )}
                    </div>
                    <div>
                        {studyDescriptionSection && (
                            <Row>
                                <Col span={24}>
                                    <Card
                                        title={t('studyViewModal.participantInfo')}
                                        size="small"
                                        bordered={false}
                                        extra={
                                            <Button
                                                type="text"
                                                size="small"
                                                icon={<FontAwesomeIcon icon="compress-alt" />}
                                                onClick={() => {
                                                    setStudyDescriptionSection(false);
                                                    setMainSection(true);
                                                }}
                                            />
                                        }
                                    >
                                        <Col span={24} className="studyView-studyInfo-content">
                                            <div className="studyView-studyInfo-content-div-expand">
                                                {parse(studyDescription)}
                                            </div>
                                        </Col>
                                    </Card>
                                </Col>
                            </Row>
                        )}
                    </div>
                    <div>
                        {filePreviewSection && (
                            <Row>
                                <Col span={24}>
                                    <Card
                                        title={t('studyViewModal.attachedFile')}
                                        size="small"
                                        bordered={false}
                                        extra={
                                            <Button
                                                type="text"
                                                size="small"
                                                icon={<FontAwesomeIcon icon="compress-alt" />}
                                                onClick={() => {
                                                    setFilePreviewSection(false);
                                                    setMainSection(true);
                                                }}
                                            />
                                        }
                                    >
                                        <Col span={24}>
                                            {imageFileStatus ? (
                                                <div className="studyView-image-preview">
                                                    <Image
                                                        width={400}
                                                        src={`${ENDPOINTS.MEDIA_RESOURCE_ENDPOINT}/${fileSourceUrl}`}
                                                        className="studyView-image-loader"
                                                    />
                                                </div>
                                            ) : (
                                                <div>
                                                    <div className="studyView-video-player">
                                                        <ReactPlayer
                                                            url={fileSourceUrl}
                                                            controls
                                                            className="studyView-react-player"
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                        </Col>
                                    </Card>
                                </Col>
                            </Row>
                        )}
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default StudyViewModal;
