/* eslint-disable import/prefer-default-export */
const BASE_URL = process.env.REACT_APP_BASE_URL;

export const ENDPOINTS = {
    GET_STUDIES: `${BASE_URL}/api/study`,
    GET_DEFAULT_STUDIES: `${BASE_URL}/api/study/GetStudyDetails`,
    CREATE_STUDY: `${BASE_URL}/api/study`,
    PUBLISH_STUDY: `${BASE_URL}/api/study/publish`,
    DELETE_STUDY: `${BASE_URL}/api/study/delete`,
    UPLOAD_FILE: `${BASE_URL}/api/File`,
    DELETE_FILE: `${BASE_URL}/api/File`,
    RESOURCE_ENDPOINT: `${BASE_URL}/media`,
    MEDIA_RESOURCE_ENDPOINT: `${BASE_URL}/Resources`,
};
