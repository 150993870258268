/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useState, useContext } from 'react';
import {
    Drawer,
    Form,
    Button,
    Col,
    Row,
    Input,
    notification,
    Alert,
    Tabs,
    Modal,
    Upload,
    Typography,
    Table,
    Tag,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { CSVLink } from 'react-csv';
import FloatLabel from '../floatLabel/floatLabel';
import ParticipantService from '../../services/participant';
import AuthService from '../../services/identity/authService';
import Loading from '../loading/loading';
import defaultModalContext from '../../utils/context/defaultModel-context';
import MaskInput from '../maskInput/maskInput';
import {
    addParticipantRequestBody,
    duplicateArrayResponse,
    duplicateResponse,
    invitationValidateStatus,
    inviteResponse,
} from '../../types/global';
import csvSampleData from './csvSampleData';
import 'react-phone-number-input/style.css';
import './index.scss';

const { TabPane } = Tabs;
const { Dragger } = Upload;
const { Text } = Typography;

type drawerProps = {
    visible: boolean;
    studyId: string;
    studyName: string;
    onClose: (form: any) => void;
    updateStudyList: () => void;
};

type csvParticipantObject = addParticipantRequestBody;
type csvInvalidParticipantObject = {
    personalId: string;
    reason: Array<string>;
    email?: string;
    mobileNumber?: string;
};

const InvitationDrawer: React.FC<drawerProps> = (props) => {
    const { visible, studyId, updateStudyList, onClose, studyName } = props;
    const [buffering, setBuffering] = useState(false);
    const authService = new AuthService();
    const { inviteEmail } = useContext(defaultModalContext);
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [mobileValue, setMobileValue] = useState(' ');
    const [emailValue, setEmailValue] = useState('');
    const [personalIdValue, setPersonalIdValue] = useState('');

    const [uploadModalVisible, setUploadModalVisible] = useState(false);
    const [csvUploadMode, setCSVUploadMode] = useState(false);
    const [uploadFileList, setUploadFileList] = useState<any>([]);
    const [validParticipantData, setValidParticipantData] = useState<Array<any>>([]);
    const [invalidParticipantData, setInvalidParticipantData] = useState<Array<any>>([]);
    const [uploadSectionVisible, setUploadSectionVisible] = useState(false);
    const [csvPreviewSectionVisible, setCsvPreviewSectionVisible] = useState(false);
    const [modalBuffering, setModalBuffering] = useState(false);

    const openNotification = (notificationType: string, message: string, err: string) => {
        if (notificationType === 'success' || notificationType === 'warning')
            notification[notificationType]({ message, description: err });
    };

    const validTableColumns = [
        {
            title: t('csvUploadModal.table.personalId'),
            dataIndex: 'personalId',
            key: 'personalId',
        },
        {
            title: t('csvUploadModal.table.mobileNumber'),
            dataIndex: 'mobileNumber',
            key: 'mobileNumber',
        },
        {
            title: t('csvUploadModal.table.email'),
            dataIndex: 'email',
            key: 'email',
        },
    ];
    const invalidTableColumns = [
        ...validTableColumns,
        {
            title: t('csvUploadModal.table.reason'),
            dataIndex: 'reason',
            key: 'reason',
            render: (data: Array<any>) => {
                let reasonString = '';
                if (data.length === 1) {
                    const reason = data[0];
                    reasonString = reason;
                } else {
                    data.forEach((element: string) => {
                        reasonString = reasonString.concat(`${element}\n \n`);
                    });
                }

                return reasonString;
            },
        },
    ];

    const requestBodyMaker = (requestArray: Array<addParticipantRequestBody>): Array<addParticipantRequestBody> => {
        const requestBody: Array<addParticipantRequestBody> = [];
        requestArray.forEach((element: addParticipantRequestBody) => {
            let checkingRequestBody;
            const formatPersonalId = element.personalId.replace('-', '').trim();
            if (element.email === '' || element.email === undefined) {
                checkingRequestBody = {
                    studyId,
                    personalId: formatPersonalId,
                    mobileNumber: (element.mobileNumber as string).includes('+')
                        ? element.mobileNumber
                        : '+'.concat(element.mobileNumber as string),
                };
            } else if (element.mobileNumber === '' || element.mobileNumber === undefined) {
                checkingRequestBody = {
                    studyId,
                    personalId: formatPersonalId,
                    email: element.email,
                };
            } else {
                checkingRequestBody = {
                    studyId,
                    personalId: formatPersonalId,
                    email: element.email,
                    mobileNumber: element.mobileNumber.includes('+')
                        ? element.mobileNumber
                        : '+'.concat(element.mobileNumber),
                };
            }
            requestBody.push(checkingRequestBody);
        });
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return requestBody;
    };

    const uploadCSV = async (requestBodyArray: Array<addParticipantRequestBody>) => {
        try {
            const user = await authService.getUser();
            const token: string = user?.access_token || '';
            const res: Array<inviteResponse> = (await ParticipantService.addParticipant(token, requestBodyArray)).data;
            if (res.length > 0 || res !== undefined || res != null) {
                openNotification('success', t('invitationDrawer.notification.participantAdd'), '');
                updateStudyList();
            } else {
                openNotification('warning', t('invitationDrawer.notification.participantAddFail'), '');
            }
        } catch (error: any) {
            console.log(error);
            if (error.status !== undefined) {
                if (error.status === 422) {
                    openNotification(
                        'warning',
                        t('invitationDrawer.notification.participantAddFail'),
                        error.data.message,
                    );
                }
                if (error.status === 400) {
                    openNotification(
                        'warning',
                        t('invitationDrawer.notification.participantAddFail'),
                        t('invitationDrawer.notification.validationError'),
                    );
                }
            }
        } finally {
            setBuffering(false);
            setUploadFileList([]);
            setValidParticipantData([]);
            setInvalidParticipantData([]);
            onClose(form);
        }
    };

    const onClickSubmit = () => {
        if (csvUploadMode) {
            setBuffering(true);
            const requestBodyArray: Array<addParticipantRequestBody> = requestBodyMaker(validParticipantData);
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            uploadCSV(requestBodyArray);
        } else {
            form.submit();
        }
    };

    const onFinish = async (values: addParticipantRequestBody) => {
        try {
            setBuffering(true);
            const tempBody = [];
            const tempObj = {
                studyId,
                personalId: values.personalId,
                email: values.email,
                mobileNumber: values.mobileNumber,
            };
            tempBody.push(tempObj);
            const requestBodyArray: Array<addParticipantRequestBody> = requestBodyMaker(tempBody);
            const user = await authService.getUser();
            const token: string = user?.access_token || '';
            const res: Array<inviteResponse> = (await ParticipantService.addParticipant(token, requestBodyArray)).data;

            if (res[0].responseCode === null) {
                openNotification('success', t('invitationDrawer.notification.participantAdd'), '');
                updateStudyList();
            } else if (res[0].responseCode === invitationValidateStatus.RECODE_EXIST) {
                const subResponseArray = res[0].subResponseCodes;
                (subResponseArray as string[]).forEach((element: string) => {
                    if (element === invitationValidateStatus.EMAIL_EXIST) {
                        openNotification(
                            'warning',
                            t('invitationDrawer.notification.participantAddFail'),
                            t('invitationDrawer.notification.emailExist'),
                        );
                    }
                    if (element === invitationValidateStatus.MOBILE_NUMBER_EXIST) {
                        openNotification(
                            'warning',
                            t('invitationDrawer.notification.participantAddFail'),
                            t('invitationDrawer.notification.mobileNumberExist'),
                        );
                    }
                    if (element === invitationValidateStatus.PERSONAL_ID_EXIST) {
                        openNotification(
                            'warning',
                            t('invitationDrawer.notification.participantAddFail'),
                            t('invitationDrawer.notification.personalIdExist'),
                        );
                    }
                });
            } else {
                openNotification('warning', t('invitationDrawer.notification.participantAddFail'), '');
            }
        } catch (error: any) {
            console.log(error);
            if (error.status !== undefined) {
                if (error.status === 422) {
                    openNotification(
                        'warning',
                        t('invitationDrawer.notification.participantAddFail'),
                        error.data.message,
                    );
                }
                if (error.status === 400) {
                    openNotification(
                        'warning',
                        t('invitationDrawer.notification.participantAddFail'),
                        t('invitationDrawer.notification.validationError'),
                    );
                }
            }
        } finally {
            setBuffering(false);
            form.resetFields(['personalId', 'mobileNumber', 'email', 'message']);
            setMobileValue(' ');
            setEmailValue('');
            setPersonalIdValue('');
            onClose(form);
        }
    };
    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const options = {
        // eslint-disable-next-line consistent-return
        replace: (domNode: any) => {
            if (domNode.attribs && domNode.attribs.id === 'replace') {
                return <>{studyName}</>;
            }
        },
    };

    const disableSendButton = () => {
        if (csvUploadMode) {
            if (validParticipantData.length > 0) {
                return false;
            }
            return true;
        }
        const mobile = form.getFieldValue('mobileNumber');
        const email = form.getFieldValue('email');
        if ((mobile === '' && email === '') || (mobile === undefined && email === undefined)) {
            return true;
        }
        return false;
    };

    const onClickDraggerDivButton = () => {
        setUploadModalVisible(true);
        setUploadSectionVisible(true);
    };
    const onCloseCSVUploadModal = () => {
        setUploadModalVisible(false);
        setUploadFileList([]);
        setValidParticipantData([]);
        setInvalidParticipantData([]);
        setUploadSectionVisible(false);
        setCsvPreviewSectionVisible(false);
        setModalBuffering(false);
    };
    const onUploadCSVDragger = (fileOptions: any) => {
        const { file } = fileOptions;
        let fileList = [file];
        fileList = fileList.slice(-1);
        setUploadFileList(fileList);
    };
    const onRemoveCSVDragger = () => {
        setUploadFileList([]);
    };
    const onBeforeUploadCSVDragger = (file: any) => {
        if (!(file.type === 'text/csv' || file.type === 'application/vnd.ms-excel')) {
            openNotification('warning', t('csvUploadModal.unsupportedFileFormat'), t('csvUploadModal.infoText'));
            return false;
        }
        return true;
    };
    const checkValidMobileNumber = (dataArray: Array<string>) => {
        if (dataArray[1].toString().trim() === '') {
            return true;
        }
        if (dataArray[1].toString().charAt(0) === '+') {
            const number = dataArray[1].toString().trim();
            if (isValidPhoneNumber(number)) {
                return true;
            }
            return false;
        }
        const number = dataArray[1].toString().trim();
        const internationalNumber = '+'.concat(number);
        if (isValidPhoneNumber(internationalNumber)) {
            return true;
        }
        return false;
    };
    const checkValidPersonalID = (dataArray: Array<string>) => {
        const personalIDRegex = /^\d{8}[-\s]?\d{4}\r?$/;
        if (personalIDRegex.test(dataArray[0].toString().trim())) {
            return true;
        }
        return false;
    };
    const checkValidEmail = (dataArray: Array<string>) => {
        const emailRegex = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (dataArray[2].toString().trim() === '') {
            return true;
        }
        if (emailRegex.test(dataArray[2].toString().trim())) {
            return true;
        }
        return false;
    };
    const checkDuplicate = async (
        validData: Array<csvParticipantObject>,
        invalidData: Array<csvInvalidParticipantObject>,
    ) => {
        for (let i = 0; i < validData.length; i += 1) {
            const duplicateReason = [];
            let duplicateStatus = false;
            for (let j = i + 1; j < validData.length; j += 1) {
                if (validData[i].personalId === validData[j].personalId) {
                    duplicateReason.push(t('csvUploadModal.reasons.duplicatePersonalId'));
                    duplicateStatus = true;
                }
                if (validData[i].mobileNumber === validData[j].mobileNumber) {
                    if (validData[i].mobileNumber === '' && validData[j].mobileNumber === '') {
                        duplicateStatus = false;
                    } else {
                        duplicateReason.push(t('csvUploadModal.reasons.duplicateMobileNumber'));
                        duplicateStatus = true;
                    }
                }
                if (validData[i].email === validData[j].email) {
                    if (validData[i].email === '' && validData[j].email === '') {
                        duplicateStatus = false;
                    } else {
                        duplicateReason.push(t('csvUploadModal.reasons.duplicateEmail'));
                        duplicateStatus = true;
                    }
                }
            }
            if (duplicateStatus) {
                const obj: csvInvalidParticipantObject = {
                    personalId: validData[i].personalId,
                    mobileNumber: validData[i].mobileNumber,
                    email: validData[i].email,
                    reason: duplicateReason,
                };
                invalidData.push(obj);
                if (i > -1) {
                    validData.splice(i, 1);
                }
            }
        }
        const requestBody: Array<addParticipantRequestBody> = requestBodyMaker(validData);
        const user = await authService.getUser();
        const token: string = user?.access_token || '';
        const response: duplicateResponse = await (
            await ParticipantService.checkDuplicateParticipant(token, requestBody)
        ).data;

        if (response.data === null) {
            setValidParticipantData(validData);
            setInvalidParticipantData(invalidData);
            setUploadSectionVisible(false);
            setCsvPreviewSectionVisible(true);
            setModalBuffering(false);
        } else {
            const duplicateArray: Array<duplicateArrayResponse> = response.data;
            duplicateArray.forEach((element: duplicateArrayResponse) => {
                const duplicateReason: Array<string> = [];
                element.subResponseCodes.forEach((reasonCode: string) => {
                    if (reasonCode === invitationValidateStatus.EMAIL_EXIST) {
                        duplicateReason.push(t('csvUploadModal.reasons.duplicateDBEmail'));
                    }
                    if (reasonCode === invitationValidateStatus.MOBILE_NUMBER_EXIST) {
                        duplicateReason.push(t('csvUploadModal.reasons.duplicateDBMobileNumber'));
                    }
                    if (reasonCode === invitationValidateStatus.PERSONAL_ID_EXIST) {
                        duplicateReason.push(t('csvUploadModal.reasons.duplicateDBPersonalId'));
                    }
                });
                const obj: csvInvalidParticipantObject = {
                    personalId: element.participant.personalId,
                    mobileNumber: element.participant.mobileNumber ? element.participant.mobileNumber : '',
                    email: element.participant.email ? element.participant.email : '',
                    reason: duplicateReason,
                };
                invalidData.push(obj);
                const index = validData.findIndex((item: csvParticipantObject) => {
                    if (item.personalId === element.participant.personalId) {
                        return true;
                    }
                    const formatId = element.participant.personalId
                        .slice(0, -4)
                        .concat('-', element.participant.personalId.slice(element.participant.personalId.length - 4));

                    if (item.personalId === formatId) {
                        return true;
                    }
                    return false;
                });
                if (index > -1) {
                    validData.splice(index, 1);
                }
            });
            setValidParticipantData(validData);
            setInvalidParticipantData(invalidData);
            setUploadSectionVisible(false);
            setCsvPreviewSectionVisible(true);
            setModalBuffering(false);
        }
    };
    const validateCSVData = (CSVData: Array<Array<string>>) => {
        let invalidFormat = false;
        const validData = [];
        const invalidData = [];
        try {
            for (let i = 0; i < CSVData.length; i += 1) {
                let isNotEmptyRecode = false;
                let validPersonalId = false;
                let validMobileNumber = false;
                let validEmail = false;
                const invalidReason = [];
                if (!(CSVData[i].length > 3)) {
                    if (CSVData[i][0] === '') {
                        invalidReason.push(t('csvUploadModal.reasons.emptyPersonalId'));
                    } else if (CSVData[i][1] === '' && CSVData[i][2] === '') {
                        invalidReason.push(t('csvUploadModal.reasons.emptyEmail&MobileNumber'));
                    } else {
                        isNotEmptyRecode = true;
                        if (checkValidPersonalID(CSVData[i])) {
                            validPersonalId = true;
                        } else {
                            invalidReason.push(t('csvUploadModal.reasons.invalidId'));
                        }
                    }
                    if (checkValidMobileNumber(CSVData[i])) {
                        validMobileNumber = true;
                    } else {
                        invalidReason.push(t('csvUploadModal.reasons.invalidMobileNumber'));
                    }
                    if (checkValidEmail(CSVData[i])) {
                        validEmail = true;
                    } else {
                        invalidReason.push(t('csvUploadModal.reasons.invalidEmail'));
                    }
                    if (isNotEmptyRecode && validPersonalId && validMobileNumber && validEmail) {
                        const obj = { personalId: CSVData[i][0], mobileNumber: CSVData[i][1], email: CSVData[i][2] };
                        validData.push(obj);
                    } else {
                        const obj = {
                            personalId: CSVData[i][0],
                            mobileNumber: CSVData[i][1],
                            email: CSVData[i][2],
                            reason: invalidReason,
                        };

                        invalidData.push(obj);
                    }
                } else {
                    invalidFormat = true;

                    break;
                }
            }
            if (!invalidFormat) {
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                checkDuplicate(validData, invalidData);
            } else {
                openNotification('warning', t('csvUploadModal.reasons.invalidCSVFormat'), '');
                setUploadFileList([]);
                setValidParticipantData([]);
                setInvalidParticipantData([]);
                setCsvPreviewSectionVisible(false);
                setModalBuffering(false);
            }
        } catch (error) {
            console.log(error);
            openNotification('warning', t('csvUploadModal.reasons.invalidCSVFormat'), '');
            setUploadFileList([]);
            setValidParticipantData([]);
            setInvalidParticipantData([]);
            setCsvPreviewSectionVisible(false);
            setModalBuffering(false);
        }
    };
    const processCSVData = (csvData: string) => {
        const withoutQuotes = csvData.replace(/['"]+/g, '');
        const splitIntoRows = withoutQuotes.split(/\r\n|\n/); // splits file into rows
        const parsedCSV: Array<any> = [];
        let limit = splitIntoRows.length;
        if (splitIntoRows[splitIntoRows.length - 1] === '') {
            limit = splitIntoRows.length - 1;
        }
        for (let i = 1; i < limit; i += 1) {
            const data = splitIntoRows[i].split(','); // splits into columns

            const rowArr: Array<any> = [];
            for (let j = 0; j < data.length; j += 1) {
                rowArr.push(data[j].trim());
            }
            parsedCSV.push(rowArr);
        }
        validateCSVData(parsedCSV);
    };
    const handleFiles = (file: Blob) => {
        // Check for the various File API support.
        if (window.FileReader) {
            // FileReader are supported.
            const reader: FileReader = new FileReader();
            // Read file into memory as UTF-8
            reader.readAsText(file, 'ISO-8859-1');
            reader.onload = (event: any) => {
                const csv = event.target.result;
                processCSVData(csv);
            };
            reader.onerror = (evt: any) => {
                console.log(reader.error);
                if (evt.target.error.name === 'NotReadableError') {
                    openNotification('warning', t('csvUploadModal.canNotReadFile'), '');
                }
            };
        } else {
            openNotification('warning', t('csvUploadModal.fileReaderNotSupport'), '');
        }
    };
    const onClickNextCSVUpload = () => {
        setModalBuffering(true);
        handleFiles(uploadFileList[0]);
        setUploadFileList([]);
    };
    const onClickUploadCSVUpload = () => {
        setCsvPreviewSectionVisible(false);
        setModalBuffering(false);
        setUploadModalVisible(false);
    };
    const onRemoveTag = (participant: csvParticipantObject) => {
        setValidParticipantData(validParticipantData.filter((item) => item !== participant));
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        // setValidParticipantData((oldArray) => oldArray.filter((item) => item !== participant));
    };
    const onClickTabSelector = (index: string) => {
        if (index === '2') {
            setCSVUploadMode(true);
        } else {
            setCSVUploadMode(false);
        }
    };

    return (
        <>
            <Drawer
                title={
                    <div>
                        <div className="drawer-title">{t('invitationDrawer.title')}</div>
                        <div>
                            <Alert
                                message={t('invitationDrawer.infoMessage')}
                                type="info"
                                showIcon
                                className="drawer-alert"
                            />
                        </div>
                    </div>
                }
                width={450}
                onClose={() => {
                    setMobileValue(' ');
                    setEmailValue('');
                    setPersonalIdValue('');
                    setUploadFileList([]);
                    setValidParticipantData([]);
                    setInvalidParticipantData([]);
                    onClose(form);
                }}
                visible={visible}
                bodyStyle={{ paddingTop: 0 }}
                footer={
                    <div className="drawer-footer">
                        <Row>
                            <Col span={12}>
                                <Button
                                    onClick={() => {
                                        setMobileValue(' ');
                                        setEmailValue('');
                                        setPersonalIdValue('');
                                        onClose(form);
                                    }}
                                    className="drawer-btn-cancel"
                                >
                                    {t('invitationDrawer.cancelButton')}
                                </Button>
                            </Col>
                            <Col span={12}>
                                <Button
                                    onClick={onClickSubmit}
                                    className="drawer-btn-send"
                                    disabled={disableSendButton()}
                                >
                                    {t('invitationDrawer.sendButton')}
                                </Button>
                            </Col>
                        </Row>
                    </div>
                }
            >
                <Tabs defaultActiveKey="1" centered className="invitation-drawer-tab" onTabClick={onClickTabSelector}>
                    <TabPane tab={t('invitationDrawer.tab1')} key="1">
                        {!buffering ? (
                            <Form
                                layout="vertical"
                                className="drawer-form-invitation"
                                form={form}
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                initialValues={{
                                    prefix: '+46',
                                }}
                            >
                                <Row gutter={16}>
                                    <Col span={24} className="drawer-form-row">
                                        <FloatLabel label={t('invitationDrawer.personalId')} value={personalIdValue}>
                                            <Form.Item
                                                name="personalId"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t('invitationDrawer.personalIdRequired'),
                                                    },
                                                    {
                                                        pattern: /^\d{8}[-\s]?\d{4}\r?$/,
                                                        message: t('invitationDrawer.personalIdValidation'),
                                                    },
                                                ]}
                                            >
                                                <MaskInput
                                                    value={personalIdValue}
                                                    onChange={(e: any) => setPersonalIdValue(e.target.value)}
                                                    mask="99999999-9999"
                                                    placeholder="        -    "
                                                />
                                            </Form.Item>
                                        </FloatLabel>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={24} className="drawer-form-row">
                                        <FloatLabel
                                            label={t('invitationDrawer.mobileNumber')}
                                            value={mobileValue}
                                            intlNumber
                                        >
                                            <Form.Item
                                                name="mobileNumber"
                                                rules={[
                                                    () => ({
                                                        validator(_, value1) {
                                                            if (!value1 || (value1 && isValidPhoneNumber(value1))) {
                                                                return Promise.resolve();
                                                            }

                                                            return Promise.reject(
                                                                new Error(t('invitationDrawer.mobileNumberRule')),
                                                            );
                                                        },
                                                    }),
                                                ]}
                                            >
                                                <PhoneInput
                                                    international
                                                    defaultCountry="SE"
                                                    value={mobileValue}
                                                    onChange={(e: any) => {
                                                        setMobileValue(e);
                                                        if (e === undefined) {
                                                            setMobileValue(' ');
                                                        }
                                                    }}
                                                />
                                            </Form.Item>
                                        </FloatLabel>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={24} className="drawer-form-row">
                                        <FloatLabel label={t('invitationDrawer.email')} value={emailValue}>
                                            <Form.Item
                                                name="email"
                                                rules={[
                                                    {
                                                        type: 'email',
                                                        message: t('invitationDrawer.emailValidation'),
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    onChange={(e) => {
                                                        setEmailValue(e.target.value);
                                                    }}
                                                />
                                            </Form.Item>
                                        </FloatLabel>
                                    </Col>
                                </Row>
                                {/* <Row gutter={16}>
                                    <Col span={24} className="drawer-form-row">
                                        <Form.Item name="content" label={t('invitationDrawer.messagePreview')}>
                                            <div className="drawer-form-message-box ">
                                                <div className="drawer-form-message-content">
                                                    {parse(inviteEmail, options)}
                                                </div>
                                            </div>
                                        </Form.Item>
                                    </Col>
                                </Row> */}
                            </Form>
                        ) : (
                            <Loading />
                        )}
                    </TabPane>
                    <TabPane tab={t('invitationDrawer.tab2')} key="2">
                        {!buffering ? (
                            <>
                                <div
                                    className="invitation-drawer-upload-csv-wrap"
                                    onClick={onClickDraggerDivButton}
                                    aria-hidden="true"
                                >
                                    <div>
                                        <Row>
                                            <FontAwesomeIcon icon="upload" className="upload-icon" />
                                        </Row>
                                    </div>
                                </div>
                                <div className="invitation-drawer-tags-upper-wrap">
                                    <div className="invitation-drawer-tags-wrap">
                                        {validParticipantData.map((participant: any) => (
                                            <Tag
                                                key={participant.personalId}
                                                closable
                                                visible
                                                onClose={() => {
                                                    onRemoveTag(participant);
                                                }}
                                            >
                                                {participant.email ? participant.email : participant.mobileNumber}
                                            </Tag>
                                        ))}
                                    </div>
                                </div>
                            </>
                        ) : (
                            <Loading />
                        )}
                    </TabPane>
                </Tabs>
            </Drawer>
            <Modal
                title={t('csvUploadModal.title')}
                visible={uploadModalVisible}
                footer={null}
                centered
                closable
                onCancel={onCloseCSVUploadModal}
                width={750}
                className="csvUpload-dragger"
            >
                {!modalBuffering ? (
                    <div>
                        {uploadSectionVisible && (
                            <div>
                                <Row>
                                    <Col span={24}>
                                        <Dragger
                                            name="file"
                                            // accept=".csv"
                                            customRequest={onUploadCSVDragger}
                                            maxCount={1}
                                            fileList={uploadFileList}
                                            onRemove={onRemoveCSVDragger}
                                            beforeUpload={onBeforeUploadCSVDragger}
                                        >
                                            <div>
                                                <p className="upload-dragger-button">
                                                    <Button className="upload-dragger-btn-upload" type="primary">
                                                        {t('csvUploadModal.draggerButton')}
                                                    </Button>
                                                </p>
                                                <p className="upload-dragger-text">
                                                    <Text strong>{t('csvUploadModal.draggerText')}</Text>
                                                </p>
                                            </div>
                                        </Dragger>
                                    </Col>
                                </Row>
                                <Row className="invitation-drawer-upload-sample-link-row" justify="space-between">
                                    <Col>
                                        <Text strong>{t('csvUploadModal.infoText')} </Text>{' '}
                                        <CSVLink filename="sample.csv" data={csvSampleData}>
                                            <span className="invitation-drawer-sample-link">
                                                {t('csvUploadModal.linkText')}
                                            </span>
                                        </CSVLink>
                                    </Col>
                                    <Col>
                                        <Button
                                            type="primary"
                                            className="upload-dragger-btn-next"
                                            onClick={onClickNextCSVUpload}
                                            disabled={uploadFileList.length === 0}
                                        >
                                            {t('csvUploadModal.nextButton')}
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        )}
                        {csvPreviewSectionVisible && (
                            <div>
                                <div className="csvUpload-preview-text">
                                    <Text strong>{t('csvUploadModal.preview')}</Text>
                                </div>

                                <Table
                                    dataSource={validParticipantData}
                                    columns={validTableColumns}
                                    pagination={false}
                                    size="small"
                                    scroll={{ y: 150 }}
                                />
                                <div className="csvUpload-invalid-text">
                                    {invalidParticipantData.length > 0 ? (
                                        <h4 style={{ color: 'red' }}>
                                            {t('csvUploadModal.invalidRecords')} {invalidParticipantData.length}{' '}
                                        </h4>
                                    ) : (
                                        <h4>
                                            {t('csvUploadModal.invalidRecords')} {invalidParticipantData.length}{' '}
                                        </h4>
                                    )}
                                </div>
                                <div>
                                    {invalidParticipantData.length > 0 ? (
                                        <Table
                                            dataSource={invalidParticipantData}
                                            columns={invalidTableColumns}
                                            pagination={false}
                                            scroll={{ y: 150 }}
                                            size="small"
                                            className="csvUpload-invalid-table"
                                        />
                                    ) : (
                                        ''
                                    )}
                                </div>
                                <div>
                                    <Row className="csvUpload-details-div" justify="space-between" align="bottom">
                                        <Col>
                                            <Text>
                                                {t('csvUploadModal.Quantity')}{' '}
                                                {validParticipantData.length + invalidParticipantData.length}
                                            </Text>
                                            <br />
                                            <Text>
                                                {t('csvUploadModal.eligibleForUpload')} {validParticipantData.length}
                                            </Text>
                                            <br />
                                            <Text>
                                                {t('csvUploadModal.failedUploads')} {invalidParticipantData.length}
                                            </Text>
                                        </Col>
                                        <Col>
                                            <Row>
                                                <div className="csvUpload-upload-button-section">
                                                    <Button
                                                        className="upload-dragger-btn-cancel"
                                                        onClick={onCloseCSVUploadModal}
                                                    >
                                                        {t('csvUploadModal.cancelButton')}
                                                    </Button>
                                                </div>
                                                <div>
                                                    <Button
                                                        type="primary"
                                                        className="upload-dragger-btn-next"
                                                        onClick={onClickUploadCSVUpload}
                                                    >
                                                        {t('csvUploadModal.uploadButton')}
                                                    </Button>
                                                </div>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        )}
                    </div>
                ) : (
                    <Loading />
                )}
            </Modal>
        </>
    );
};
export default InvitationDrawer;
