/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect, useRef, useState } from 'react';
import {
    Row,
    Col,
    PageHeader,
    Input,
    Button,
    Table,
    Popconfirm,
    Card,
    notification,
    Upload,
    message,
    Progress,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InboxOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import AuthService from '../../services/identity/authService';
import useTitle from '../../utils/hooks/useTitle';
import defaultModalContext from '../../utils/context/defaultModel-context';
import TextEditor from '../../components/textEditor/textEditor';
import DeleteModal from '../../components/deleteModal/deleteModal';
import { EditableRow, EditableCell } from './consentTableOptions';
import StudyService from '../../services/study';
import { ENDPOINTS } from '../../services/study/contracts';
import {
    consentDataObject,
    studyResponseObject,
    consentObjectInResponse,
    fileArrayObjectInResponse,
    uploadFileArrayObject,
    studyRequestBody,
    consentArrayForReqBody,
} from '../../types/global';

import './index.scss';
import { useHistory, useLocation } from 'react-router-dom';

const { TextArea } = Input;
const { Dragger } = Upload;

const defaultDataSource: Array<consentDataObject> = [
    {
        key: 0,
        consentId: '',
        consentNumber: 'Consent agreement',
        consent: 'Detta är en introduktionstext som kan redigeras.',
    },
    {
        key: 1,
        consentId: '',
        consentNumber: `Consent issue 1`,
        consent: 'Consent content 0',
    },
    {
        key: 2,
        consentId: '',
        consentNumber: 'Consent issue 2',
        consent: 'Consent content 1',
    },
];
interface LocationState {
    id: string;
    studyName: string;
    participationInfo: string;
    storageId: string;
}
const CreateStudy: React.FC = () => {
    const location = useLocation<LocationState>();
    const history = useHistory();
    const { CancelToken } = axios;
    const { setHeaderTitle } = useTitle();
    const { t } = useTranslation();
    const authService = new AuthService();
    const { consent1, consent2, participantInfoText, introductionText } = useContext(defaultModalContext);
    const [studyTitle, setStudyTitle] = useState(location.state ? location.state.studyName : '');
    const [studyId, setStudyId] = useState('');
    const [savingStatus, setSavingStatus] = useState(false);
    const [studyStatus, setStudyStatus] = useState(0);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [participantInfo, setParticipantInfo] = useState(
        location.state ? location.state.participationInfo : participantInfoText,
    );
    const [htmlContent, setHtmlContent] = useState(participantInfoText);
    const [dataSource, setDataSource] = useState(defaultDataSource);
    const [count, setCount] = useState(3);
    const [uploadFileList, setUploadFileList] = useState<any>([]);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadDisable, setUploadDisable] = useState(false);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [{ deleteFileUrl, deleteFileUid }, setDeleteFileUrl] = useState({ deleteFileUrl: '', deleteFileUid: '' });
    const [deleteModalConfirmLoading, setDeleteModalConfirmLoading] = useState(false);
    const [disable, setDisable] = useState(0);
    const [storageId, setStorageId] = useState(() => {
        if (location.state) {
            if (
                location.state.storageId === '00000000-0000-0000-0000-000000000000' ||
                location.state.storageId === null ||
                location.state.storageId === undefined
            ) {
                const tempStorageId = uuidv4();
                return tempStorageId;
            }
            return location.state.storageId;
        }
        const tempStorageId = uuidv4();
        return tempStorageId;
    });
    const cancelFileUpload = useRef<any>(null);

    const openNotification = (type: string, NotificationMessage: string, err: string) => {
        if (type === 'success' || type === 'warning')
            notification[type]({ message: NotificationMessage, description: err });
        if (type === 'error') notification[type]({ message: NotificationMessage, description: err });
    };

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };
    const defaultColumns = [
        {
            title: 'Introduction text',
            dataIndex: 'consentNumber',
            width: '25%',
        },
        {
            title: 'Consent',
            dataIndex: 'consent',
            editable: true,
        },
        {
            title: '',
            dataIndex: 'operation',
            width: '10%',
            render: (text: any, record: consentDataObject) => {
                if (record.key === 0) {
                    return null;
                }
                return dataSource.length >= 1 ? (
                    <Popconfirm
                        title={t('newStudy.concentDeletePopupText')}
                        onConfirm={() => {
                            handleDelete(record.key);
                        }}
                    >
                        <FontAwesomeIcon className="create-trash-consent" icon="trash" />
                    </Popconfirm>
                ) : null;
            },
        },
    ];
    const columns = defaultColumns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: consentDataObject) => {
                if (record.key === 0) {
                    return {
                        record,
                        editable: col.editable,
                        dataIndex: col.dataIndex,
                        title: col.title,
                        handleSave,
                    };
                }
                return {
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave,
                };
            },
        };
    });

    useEffect(() => {
        setHeaderTitle(t('newStudy.createNewStudyTitle'));
        const reProduceData = defaultDataSource.map((item: consentDataObject) => {
            if (item.key === 0) {
                item.consent = introductionText;
                return item;
            }
            if (item.key === 1) {
                item.consent = consent1;
                return item;
            }
            if (item.key === 2) {
                item.consent = consent2;
                return item;
            }
            return item;
        });
        setDataSource(reProduceData);
    }, [consent1, consent2]);

    const consent1TextChange = (StudyTitle: any) => {
        if (disable === 0) {
            const reProduceData = dataSource.map((item: consentDataObject) => {
                if (item.key === 0) {
                    item.consent = introductionText;
                    return item;
                }
                if (item.key === 1) {
                    const checkConsent1Text = item.consent.includes('Jag samtycker till att delta i studien ”');
                    if (checkConsent1Text) {
                        item.consent = consent1.replace('Name of study', StudyTitle);
                        return item;
                    }
                }
                if (item.key === 2) {
                    item.consent = consent2;
                    return item;
                }
                return item;
            });

            setDataSource(reProduceData);
        }
    };

    const getStudyDetails = async () => {
        try {
            const user = await authService.getUser();
            const token: string = user?.access_token || '';
            const response = await StudyService.getStudy(token, location.state.id);
            DataBind(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (location.state) {
            setHeaderTitle(t('newStudy.updateNewStudyTitle'));
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            getStudyDetails();
        }
    }, [location.state]);

    const handleSave = (row: consentDataObject) => {
        setSavingStatus(false);
        const newData: Array<consentDataObject> = [...dataSource];
        const index = newData.findIndex((item) => row.key === item.key);
        const item: consentDataObject = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setDataSource(newData);
    };
    const handleDelete = (key: number) => {
        setSavingStatus(false);
        const copyOfDataSource: Array<consentDataObject> = [...dataSource];
        const removeData = copyOfDataSource.filter((item) => item.key === key);
        const checkConsent1Text = removeData[0].consent.includes('Jag samtycker till att delta i studien ”');
        if (checkConsent1Text) {
            setDisable(1);
        } else {
            setDisable(0);
        }
        const filterData: Array<consentDataObject> = copyOfDataSource.filter((item) => item.key !== key);
        const rearrangeData: Array<consentDataObject> = [];
        for (let index = 0; index < filterData.length; index += 1) {
            const element: consentDataObject = filterData[index];
            if (index === 0) {
                rearrangeData.push(element);
            } else {
                element.key = index;
                element.consentNumber = `${t('newStudy.consentIssue')} ${index}`;
                rearrangeData.push(element);
            }
        }
        setDataSource(rearrangeData);
        setCount(count - 1);
    };

    const handleAdd = () => {
        setSavingStatus(false);
        const newData: consentDataObject = {
            key: count,
            consentId: '',
            consentNumber: `${t('newStudy.consentIssue')} ${count}`,
            consent: '',
        };
        const newDataSource: Array<consentDataObject> = [...dataSource, newData];
        setDataSource(newDataSource);
        setCount(count + 1);
    };
    const checkEmptyConsent = () => {
        for (let index = 0; index < dataSource.length; index += 1) {
            const element: consentDataObject = dataSource[index];
            if (element.consent === '') {
                return true;
            }
        }
        return false;
    };

    const updateBodyMaker = () => {
        const consentArray: Array<consentArrayForReqBody> = [];
        let introText = '';
        dataSource.forEach((item: consentDataObject) => {
            if (item.key !== 0) {
                if (item.consentId === '') {
                    consentArray.push({ consentText: item.consent });
                } else {
                    consentArray.push({ id: item.consentId, consentText: item.consent });
                }
            }
            if (item.key === 0) {
                introText = item.consent;
            }
        });
        let content;
        if (htmlContent === '') {
            content = participantInfo;
        } else {
            content = htmlContent;
        }
        const requestBody: studyRequestBody = {
            studyName: studyTitle,
            participationInfo: content,
            status: studyStatus,
            consents: consentArray,
            introductionText: introText,
            storageId,
        };
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        updateStudy(requestBody);
    };

    const onSaveStudy = () => {
        try {
            setButtonLoading(true);
            if (checkEmptyConsent()) {
                openNotification('warning', t('newStudy.notification.emptyConsent'), '');
                setButtonLoading(false);
            } else if (studyId === '') {
                const consentArray: Array<consentArrayForReqBody> = [];
                let introText = '';
                dataSource.forEach((item) => {
                    if (item.key !== 0) {
                        consentArray.push({ consentText: item.consent });
                    }
                    if (item.key === 0) {
                        introText = item.consent;
                    }
                });
                const requestBody: studyRequestBody = {
                    studyName: studyTitle,
                    participationInfo: htmlContent,
                    status: studyStatus,
                    consents: consentArray,
                    introductionText: introText,
                    storageId,
                };
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                SavingStudy(requestBody);
            } else if (studyStatus === 0) {
                updateBodyMaker();
            } else if (
                dataSource.length > 1 &&
                studyTitle.length > 3 &&
                (participantInfo.length > 10 || htmlContent.length > 10) &&
                studyId.length > 3
            ) {
                updateBodyMaker();
            } else if (!(dataSource.length > 1)) {
                openNotification('warning', t('newStudy.notification.atLeastOneConsent'), '');
                setButtonLoading(false);
            } else {
                openNotification(
                    'warning',
                    t('newStudy.notification.actionFailed'),
                    t('newStudy.notification.minimumRequirementsFail'),
                );
                setButtonLoading(false);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const updateStudy = async (requestBody: studyRequestBody) => {
        try {
            const user = await authService.getUser();
            const token: string = user?.access_token || '';
            const response = await StudyService.updateStudy(token, requestBody, studyId);
            DataBind(response.data.data);
        } catch (error) {
            console.log(error);
            setButtonLoading(false);
            openNotification(
                'warning',
                t('newStudy.notification.actionFailed'),
                t('newStudy.notification.minimumRequirementsFail'),
            );
        }
    };

    const SavingStudy = async (studyObj: studyRequestBody) => {
        try {
            const user = await authService.getUser();
            const token: string = user?.access_token || '';
            // console.log(studyObj);
            const response = await StudyService.createStudy(token, studyObj);
            DataBind(response.data.data);
            openNotification('success', t('newStudy.notification.studyCreated'), '');
        } catch (error) {
            console.log(error);
            setButtonLoading(false);
            openNotification(
                'warning',
                t('newStudy.notification.actionFailed'),
                t('newStudy.notification.minimumRequirementsFail'),
            );
        }
    };

    const DataBind = (response: studyResponseObject) => {
        try {
            const fileArray: Array<fileArrayObjectInResponse> = response.filesInfo;
            const fileListArray: Array<uploadFileArrayObject> = [];
            fileArray.forEach((element: fileArrayObjectInResponse) => {
                const fileUrl = element.key.split('_');
                const fileNameArray: string[] = [];
                for (let index = 1; index < fileUrl.length; index += 1) {
                    fileNameArray.push(fileUrl[index]);
                }
                const fileName = decodeURIComponent(fileNameArray.toString()).replaceAll(',', '_');
                const fileId = fileUrl[0].split('/')[5];
                const fileObj = {
                    key: fileId,
                    uid: fileId,
                    name: fileName,
                    url: `${ENDPOINTS.RESOURCE_ENDPOINT}/${element.key}`,
                };
                fileListArray.push(fileObj);
            });
            setUploadFileList(fileListArray);
            const consentArray: Array<consentObjectInResponse> = response.consents;
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            const loopLength: number = consentArray.length + 1;
            const rearrangeData: Array<consentDataObject> = [];
            for (let index = 0; index < loopLength; index += 1) {
                if (index === 0) {
                    const introText = {
                        key: 0,
                        consentId: '',
                        consentNumber: `${t('newStudy.introductionText')}`,
                        consent: response.introductionText,
                    };
                    rearrangeData.push(introText);
                } else {
                    const element = consentArray[index - 1];
                    const consent = {
                        key: index,
                        consentId: element.id,
                        consentNumber: `${t('newStudy.consentIssue')} ${index}`,
                        consent: element.consentText,
                    };
                    rearrangeData.push(consent);
                }
            }
            if (
                response.storageId === '00000000-0000-0000-0000-000000000000' ||
                response.storageId === null ||
                response.storageId === undefined
            ) {
                const tempStorageId = uuidv4();

                setStorageId(tempStorageId);
            } else {
                setStorageId(response.storageId);
            }
            setCount(loopLength);
            setStudyId(response.id);
            setStudyTitle(response.studyName);
            setParticipantInfo(response.participationInfo);
            setDataSource(rearrangeData);
            setStudyStatus(response.status);
            setButtonLoading(false);
            setSavingStatus(true);
        } catch (error) {
            console.log(error);
        }
    };

    const onChangeTextEditor = (e: string) => {
        setSavingStatus(false);
        setHtmlContent(e);

        if (participantInfo.length > 3) {
            setParticipantInfo('');
        }
    };

    const onClickPublish = async () => {
        try {
            if (
                dataSource.length > 1 &&
                studyTitle.length > 3 &&
                (participantInfo.length > 10 || htmlContent.length > 10) &&
                studyId.length > 3
            ) {
                if (checkEmptyConsent()) {
                    openNotification('warning', t('newStudy.notification.emptyConsent'), '');
                } else {
                    const user = await authService.getUser();
                    const token: string = user?.access_token || '';
                    const response = await StudyService.publishStudy(token, studyId);
                    DataBind(response.data.data);
                    setHeaderTitle(t('newStudy.updateNewStudyTitle'));
                    openNotification('success', t('newStudy.notification.studyPublishSuccess'), '');
                }
            } else if (!(dataSource.length > 1)) {
                openNotification('warning', t('newStudy.notification.atLeastOneConsent'), '');
            } else {
                openNotification('warning', t('newStudy.notification.studyPublishFail'), '');
            }
        } catch (error: any) {
            console.log(error);
            if (error.status === 422) {
                openNotification('warning', error.data.message, '');
            }
        }
    };

    const setSaveButtonGroup = () => {
        if (studyStatus === 0) {
            return (
                <div>
                    <Button
                        type="primary"
                        style={{ marginRight: '30px' }}
                        className="create-study-btn-publish"
                        size="large"
                        shape="round"
                        disabled={!savingStatus}
                        onClick={onClickPublish}
                    >
                        {t('newStudy.publishButton')}
                    </Button>
                    <Button
                        type="primary"
                        className="create-study-btn-save draft-btn"
                        size="large"
                        shape="round"
                        loading={buttonLoading}
                        onClick={onSaveStudy}
                        disabled={savingStatus}
                    >
                        {savingStatus ? t('newStudy.draftSaveButton') : t('newStudy.saveButton')}
                    </Button>
                </div>
            );
        }
        return (
            <div>
                <Button
                    type="primary"
                    className="create-study-btn-save update-btn"
                    size="large"
                    shape="round"
                    loading={buttonLoading}
                    onClick={onSaveStudy}
                    disabled={savingStatus}
                >
                    {savingStatus ? t('newStudy.updatedButton') : t('newStudy.updateButton')}
                </Button>
            </div>
        );
    };

    const uploadFiles = async (options: any) => {
        const { onSuccess, onError, file, onProgress } = options;
        setUploadDisable(true);
        const user = await authService.getUser();
        const token: string = user?.access_token || '';
        const reqBody = new FormData();
        reqBody.append('FormFile', file);
        reqBody.append('FileName', file.name);
        reqBody.append('StudyId', storageId);
        const requestConfig = {
            method: 'post',
            url: `${ENDPOINTS.UPLOAD_FILE}`,
            headers: {
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${token}`,
            },
            onUploadProgress: (event: any) => {
                const percent = Math.floor((event.loaded / event.total) * 100);
                setUploadProgress(percent);
                if (percent === 100) {
                    setTimeout(() => setUploadProgress(0), 1000);
                }
                onProgress({ percent: (event.loaded / event.total) * 100 });
            },
            cancelToken: new CancelToken((c) => {
                cancelFileUpload.current = c;
            }),
            data: reqBody,
        };

        try {
            const response = await StudyService.uploadFiles(requestConfig);
            onSuccess('ok');
            if (response.data !== undefined || response.data !== null) {
                setUploadDisable(false);
                const responseUrl = response.data;
                const fileUrl = responseUrl.split('_');
                const fileNameArray: string[] = [];
                for (let index = 1; index < fileUrl.length; index += 1) {
                    fileNameArray.push(fileUrl[index]);
                }
                const fileName = decodeURIComponent(fileNameArray.toString()).replaceAll(',', '_');
                const fileId = fileUrl[0].split('/')[5];
                const fileObj = {
                    key: fileId,
                    uid: fileId,
                    name: fileName,
                    url: responseUrl,
                };
                // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                setUploadFileList((oldArray: Array<uploadFileArrayObject>) => [...oldArray, fileObj]);
                openNotification('success', t('newStudy.notification.fileUploadSuccess'), '');
            }
        } catch (error) {
            // console.log('Upload error');
            console.log(error);
            if (axios.isCancel(error)) {
                console.log('user cancel');
                console.log(error);
                openNotification(
                    'error',
                    t('newStudy.notification.uploadFailedTitle'),
                    t('newStudy.notification.cancelUploadDesc'),
                );
            } else {
                openNotification('error', t('newStudy.notification.uploadFailedTitle'), '');
            }
            onError({ error });
            setUploadProgress(0);
            setUploadFileList([]);
            setUploadDisable(false);
        }
    };

    const onClickUploadCancel = () => {
        if (cancelFileUpload.current) {
            cancelFileUpload.current();
        }
    };

    const handleChange = (info: any) => {
        const { status } = info.file;
        // if (status !== 'uploading') {
        //     console.log('info.file, info.fileList');
        //     console.log(info.file, info.fileList);
        // }
        // if (status === 'uploading') {
        //     console.log('uploading uploading');
        // }
        // if (status === 'success') {
        //     console.log('success');
        // }
        if (status === 'done') {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            message.success(`${info.file.name} file uploaded successfully.`);
            // console.log('done upload');
        } else if (status === 'error') {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            message.error(`${info.file.name} file upload failed.`);
        }
    };

    const handleOnRemove = (file: any) => {
        const fileURL = file.url.toString();
        setDeleteFileUrl((state) => ({
            ...state,
            deleteFileUrl: fileURL,
            deleteFileUid: file.uid,
        }));
        setDeleteModalVisible(true);
    };

    const deleteModalHandleOk = async () => {
        try {
            setDeleteModalConfirmLoading(true);
            const user = await authService.getUser();
            const token: string = user?.access_token || '';
            await StudyService.removeFiles(deleteFileUrl, token);
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            const index = uploadFileList.map((item: any) => item.uid).indexOf(deleteFileUid);
            const tempFileList = uploadFileList;
            tempFileList.splice(index, 1);
            setUploadFileList([...tempFileList]);
            openNotification('success', t('newStudy.notification.deleteFileSuccess'), '');
            setDeleteModalConfirmLoading(false);
            setDeleteModalVisible(false);
            setDeleteFileUrl((state) => ({
                ...state,
                deleteFileUrl: '',
                deleteFileUid: '',
            }));
        } catch (error) {
            console.log(error);
            openNotification('warning', t('newStudy.notification.deleteFileFail'), '');
            setDeleteFileUrl((state) => ({
                ...state,
                deleteFileUrl: '',
                deleteFileUid: '',
            }));
            setDeleteModalConfirmLoading(false);
            setDeleteModalVisible(false);
        }
    };
    const deleteModalHandleCancel = () => {
        setDeleteFileUrl((state) => ({
            ...state,
            deleteFileUrl: '',
            deleteFileUid: '',
        }));
        setDeleteModalVisible(false);
    };
    const uploadDisableChecker = (file: any) => {
        const fileType = file.type;
        const fileSize = file.size;
        const tempFileList = uploadFileList;
        // console.log(fileType);

        if (tempFileList.length > 0) {
            openNotification(
                'error',
                t('newStudy.notification.uploadBlockedTitle'),
                t('newStudy.notification.uploadBlockedDesc'),
            );
            return false;
        }
        if (
            fileType === 'image/jpeg' ||
            fileType === 'image/png' ||
            fileType === 'image/jpg' ||
            fileType === 'video/mp4'
        ) {
            if (fileType === 'image/jpeg' || fileType === 'image/png' || fileType === 'image/jpg') {
                if (fileSize < 10585760) {
                    return true;
                }
                openNotification(
                    'error',
                    t('newStudy.notification.uploadBlockedTitle'),
                    t('newStudy.notification.uploadFileSize', { size: 10 }),
                );
                return false;
            }
            if (fileType === 'video/mp4') {
                if (fileSize < 105857600) {
                    return true;
                }
                openNotification(
                    'error',
                    t('newStudy.notification.uploadBlockedTitle'),
                    t('newStudy.notification.uploadFileSize', { size: 100 }),
                );
                return false;
            }
        } else {
            openNotification(
                'error',
                t('newStudy.notification.actionFailed'),
                t('newStudy.notification.unsupportedFileFormat'),
            );
            return false;
        }

        return false;
    };

    return (
        <div>
            <div>
                <PageHeader
                    className="create-study-page-header"
                    onBack={() => {
                        history.push('/app/studies');
                    }}
                    title={studyTitle || 'Title'}
                    backIcon={<FontAwesomeIcon icon="arrow-alt-circle-left" className="create-study-backIcon" />}
                />
                <div className="site-card-border-less-wrapper">
                    <Card className="create-card">
                        <Row gutter={40}>
                            <Col span={12}>
                                <Row>
                                    <Col span={24}>
                                        <Row>
                                            <div className="create-study-subTitle">{t('newStudy.nameOfTheStudy')}</div>
                                        </Row>
                                        <Row>
                                            <TextArea
                                                placeholder={t('newStudy.nameOfTheStudyPlaceHolder')}
                                                autoSize={{ minRows: 5, maxRows: 8 }}
                                                onChange={(e) => {
                                                    setSavingStatus(false);
                                                    setStudyTitle(e.target.value);
                                                    // consent1TextChange(e.target.value);
                                                }}
                                                maxLength={100}
                                                value={studyTitle}
                                                size="small"
                                            />
                                        </Row>
                                        <Row>
                                            <Col span={24} className="create-study-maxCharacter">
                                                {t('newStudy.maxCharacter')}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <Row>
                                            <div className="create-study-subTitle">{t('newStudy.informedConsent')}</div>
                                        </Row>
                                        <Row>
                                            <Col span={24}>
                                                <Table
                                                    showHeader={false}
                                                    components={components}
                                                    rowClassName={() => 'create-study-editable-row'}
                                                    bordered
                                                    dataSource={dataSource}
                                                    columns={columns as any}
                                                    pagination={false}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={24} className="create-study-btn-addConsentPosition">
                                                <Button
                                                    onClick={handleAdd}
                                                    type="primary"
                                                    size="small"
                                                    className="create-study-btn-addConsent"
                                                >
                                                    +
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={12}>
                                <Row>
                                    <Col span={24}>
                                        <div className="create-study-subTitle">{t('newStudy.uploadVideoOrImage')}</div>
                                    </Col>
                                </Row>
                                <Row className="upload-box">
                                    <Col span={24}>
                                        <div>
                                            <Dragger
                                                name="file"
                                                // accept=".png,.jpg,.jpeg,.mp4"
                                                customRequest={uploadFiles}
                                                onChange={handleChange}
                                                maxCount={1}
                                                fileList={uploadFileList}
                                                onRemove={handleOnRemove}
                                                beforeUpload={uploadDisableChecker}
                                                disabled={uploadDisable}
                                            >
                                                <p className="upload-dragger-icon">
                                                    <InboxOutlined />
                                                </p>
                                                <p className="upload-dragger-text">{t('newStudy.fileDragAreaText')}</p>
                                                {uploadProgress > 0 ? (
                                                    <div>
                                                        <Row align="middle">
                                                            <Col span={20}>
                                                                <Progress percent={uploadProgress} />
                                                            </Col>
                                                            <Col span={4}>
                                                                <Button type="link" onClick={onClickUploadCancel}>
                                                                    {t('newStudy.uploadCancelButton')}
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                ) : null}
                                            </Dragger>
                                        </div>
                                    </Col>
                                </Row>

                                <Row>
                                    <div className="create-study-subTitle">{t('newStudy.participantInfo')}</div>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <div>
                                            <TextEditor onChange={onChangeTextEditor} savedContent={participantInfo} />
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="create-study-pageFooter">
                            <Col span={12}>
                                <Button
                                    type="primary"
                                    className="create-study-btn-cancel"
                                    size="large"
                                    shape="round"
                                    onClick={() => {
                                        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                                        history.push('/app/studies');
                                    }}
                                >
                                    {t('newStudy.cancelButton')}
                                </Button>
                            </Col>
                            <Col span={12} className="create-study-clo-footerButton">
                                {setSaveButtonGroup()}
                            </Col>
                        </Row>
                    </Card>
                </div>
            </div>
            <DeleteModal
                title={t('newStudy.fileDelete.title')}
                modalText={t('newStudy.fileDelete.messageText')}
                visible={deleteModalVisible}
                handleOk={deleteModalHandleOk}
                okButtonText={t('newStudy.fileDelete.okButtonText')}
                confirmLoading={deleteModalConfirmLoading}
                handleCancel={deleteModalHandleCancel}
            />
        </div>
    );
};
export default CreateStudy;
