import React from 'react';

type auth = {
    isAuth: boolean;
    role: string;
    avatarText: string;
    headerName: string;
};
const AuthContext = React.createContext<auth>({} as auth);

export default AuthContext;
