import React from 'react';
import { Modal, Button } from 'antd';
import { useTranslation } from 'react-i18next';

import './index.scss';

interface DeleteModelTypes {
    title: string;
    okButtonText: string;
    modalText: string;
    visible: boolean;
    cancelButtonText?: string;
    handleOk: () => void;
    confirmLoading: boolean;
    handleCancel: () => void;
}

const DeleteModal: React.FC<DeleteModelTypes> = (props) => {
    const { t } = useTranslation();
    const { title, visible, confirmLoading, modalText, okButtonText, cancelButtonText, handleOk, handleCancel } = props;

    return (
        <div>
            <Modal
                title={[<span className="delete-modal-header-text">{title}</span>]}
                visible={visible}
                onOk={handleOk}
                centered
                width={450}
                closable={false}
                footer={[
                    <div>
                        <Button onClick={handleCancel} className="delete-modal-btn-cancel">
                            {cancelButtonText || t('global.cancelButton')}
                        </Button>
                        <Button onClick={handleOk} className="delete-modal-btn-delete" loading={confirmLoading}>
                            {okButtonText}
                        </Button>
                    </div>,
                ]}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
            >
                <p className="delete-modal-body-text">{modalText}</p>
            </Modal>
        </div>
    );
};

export default DeleteModal;
