/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, { useEffect, useState } from 'react';
import { Row, Col, Input, Button, Card, Form, Spin, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import useTitle from '../../utils/hooks/useTitle';
import AuthService from '../../services/identity/authService';
import { APIResponse, UpdateUser } from '../../types/global';
import './index.scss';
import 'react-phone-number-input/style.css';
import UserService from '../../services/user';
import { AxiosResponse } from 'axios';

type formValues = {
    email: string;
    firstName: string;
    institution: string;
    lastName: string;
    mobileNumber: string;
    postalAddress: string;
    postalCode: string;
    role: string;
    streetAddress: string;
    unit: string;
};

const Profile: React.FC = () => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { setHeaderTitle } = useTitle();
    const authService = new AuthService();
    const { t } = useTranslation();
    const history = useHistory();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [mobileValue, setMobileValue] = useState('');
    const [userDetail, setUserDetail] = useState<UpdateUser>();

    const openNotification = (type: string, message: string, err: string) => {
        if (type === 'success' || type === 'warning') notification[type]({ message, description: err });
    };

    const setValues = (response: AxiosResponse<APIResponse<UpdateUser>>, userEmail: string, userRole: string) => {
        try {
            const metaData = response.data.data;
            if (response.data !== null) {
                form.setFieldsValue({
                    firstName: metaData.firstName ? metaData.firstName : '',
                    lastName: metaData.lastName ? metaData.lastName : '',
                    role: userRole,
                    email: userEmail,
                    mobileNumber: metaData.mobileNumber ? metaData.mobileNumber : '',
                    postalAddress: metaData.streetAddress ? metaData.streetAddress : '',
                    postalCode: metaData.postalCode ? metaData.postalCode : '',
                });
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const getUserDetails = async () => {
        try {
            const user = await authService.getUser();
            const userEmail: string = user?.profile.email || '';
            const userRole: string = user?.profile.role || '';
            const response = await UserService.GetMyDetails();
            setUserDetail(response.data.data);
            setValues(response, userEmail, userRole);
        } catch (error) {
            console.log(error);
            openNotification('warning', t('profile.notification.error'), ' ');
            setLoading(false);
        }
    };

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        setHeaderTitle(t('profile.title'));
        setLoading(true);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        getUserDetails();
    }, []);

    const layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 20, offset: 2 },
    };

    const formItemArray = [
        {
            name: 'firstName',
            label: t('profile.firstName'),
            disable: false,
            rules: [{ required: true, message: t('autProfile.requiredField') }],
        },
        {
            name: 'lastName',
            label: t('profile.lastName'),
            disable: false,
            rules: [{ required: true, message: t('autProfile.requiredField') }],
        },
        { name: 'role', label: t('profile.role'), disable: true },
        { name: 'email', label: t('profile.email'), disable: true },
        { name: 'mobileNumber', label: t('profile.mobileNumber'), disable: false },
        {
            name: 'postalAddress',
            label: t('profile.postalAddress'),
            disable: false,
            rules: [{ required: true, message: t('autProfile.requiredField') }],
        },
        {
            name: 'postalCode',
            label: t('profile.postalCode'),
            disable: false,
            rules: [{ required: true, message: t('autProfile.requiredField') }],
        },
    ];

    const onFinish = async (values: formValues) => {
        try {
            const user = await authService.getUser();
            setLoading(true);
            const userRole: string = user?.profile.role || '';
            const userEmail = user?.profile.email || '';
            const reqBody: UpdateUser = {
                firstName: values.firstName,
                lastName: values.lastName,
                mobileNumber: values.mobileNumber,
                streetAddress: values.postalAddress,
                postalCode: values.postalCode,
            };
            const response = await UserService.UpdateUser(userDetail?.id ?? '', reqBody);
            setValues(response, userEmail, userRole);
            openNotification('success', t('profile.notification.updateSuccess'), '');
            setLoading(false);
        } catch (error) {
            console.log(error);
            openNotification('warning', t('profile.notification.updateFail'), ' ');
            setLoading(false);
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div className="profile-root">
            <Card className="profile-card">
                <Spin spinning={loading}>
                    <Row>
                        <Col span={24} className="profile-card-title">
                            {t('profile.cardTitle')}
                        </Col>
                    </Row>
                    <Form
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...layout}
                        className="profile-form"
                        form={form}
                        colon={false}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >
                        {formItemArray.map((recode) => {
                            if (recode.name === 'mobileNumber') {
                                return (
                                    <Row key={recode.name}>
                                        <Col span={24}>
                                            <Form.Item
                                                name={recode.name}
                                                label={recode.label}
                                                labelAlign="left"
                                                rules={[
                                                    () => ({
                                                        validator(_, value1) {
                                                            if (!value1 || (value1 && isValidPhoneNumber(value1))) {
                                                                return Promise.resolve();
                                                            }

                                                            return Promise.reject(
                                                                new Error(t('profile.mobileNumberRule')),
                                                            );
                                                        },
                                                    }),
                                                ]}
                                            >
                                                <PhoneInput
                                                    international
                                                    defaultCountry="SE"
                                                    value={mobileValue}
                                                    onChange={(e: any) => setMobileValue(e)}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                );
                            }
                            return (
                                <Row key={recode.name}>
                                    <Col span={24}>
                                        <Form.Item
                                            name={recode.name}
                                            label={recode.label}
                                            rules={recode.rules}
                                            labelAlign="left"
                                        >
                                            <Input disabled={recode.disable} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            );
                        })}
                        <Row>
                            <Col span={12} className="profile-col-cancel">
                                <Button
                                    className="profile-btn-cancel"
                                    onClick={() => {
                                        history.push('/app/studies');
                                    }}
                                >
                                    {t('global.cancelButton')}
                                </Button>
                            </Col>
                            <Col span={12} className="profile-col-save">
                                <Button htmlType="submit" className="profile-btn-save">
                                    {t('profile.saveButton')}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Card>
        </div>
    );
};
export default Profile;
