/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, { useState, useContext, useEffect } from 'react';
import { Layout, Menu, Divider } from 'antd';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { BankOutlined, UserAddOutlined } from '@ant-design/icons';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import authContext from '../utils/context/auth-context';
import { roles } from '../types/global';

import econsentLogo from '../assets/logo.svg';
import econsentELogo from '../assets/elogo.svg';

import './index.scss';

const { Sider } = Layout;

const SideBar: React.FC<RouteComponentProps> = (props) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { location } = props;
    const { role } = useContext(authContext);
    const [collapsed, setCollapsed] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [adminRole, setAdminRole] = useState(false);

    useEffect(() => {
        if (role === roles.ADMIN) {
            setAdminRole(true);
        }
        if (role === roles.RESEARCHER) {
            setAdminRole(false);
        }
    }, []);

    const onCollapse = () => {
        setCollapsed(!collapsed);
    };

    const selectedMenuItem = (pathname: string): string[] => {
        switch (pathname) {
            case '/app/studies':
                return ['/app/studies'];
            case '/app/studies/createstudy':
                return ['/app/studies'];
            case '/app/participants':
                return ['/app/studies'];
            case '/app/user':
                return ['/app/user'];
            default:
                // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                return [location.pathname];
        }
    };

    return (
        <Sider width={220} className="app-sidebar" collapsible collapsed={collapsed} onCollapse={onCollapse}>
            <div className="app-sidebar-logo">
                {collapsed ? (
                    <img src={econsentELogo} alt="E-consent Elogo" />
                ) : (
                    <img src={econsentLogo} alt="E-consent Logo" className="app-sidebar-elogo" />
                )}
            </div>
            {!collapsed && <Divider className="app-sidebar-divider" />}

            {role === roles.ADMIN ? (
                <Menu
                    mode="inline"
                    defaultSelectedKeys={['/studies']}
                    selectedKeys={selectedMenuItem(location.pathname)}
                >
                    <Menu.Item className="app-sidebar-menu" key="/app/studies" icon={<BankOutlined />}>
                        <Link to="/app/studies" className="app-sidebar-link">
                            Studies
                        </Link>
                    </Menu.Item>
                    <Menu.Item className="app-sidebar-menu" key="/app/user" icon={<UserAddOutlined />}>
                        <Link to="/app/user" className="app-sidebar-link">
                            User
                        </Link>
                    </Menu.Item>
                </Menu>
            ) : (
                <Menu
                    mode="inline"
                    defaultSelectedKeys={['/studies']}
                    selectedKeys={selectedMenuItem(location.pathname)}
                >
                    <Menu.Item className="app-sidebar-menu" key="/app/studies" icon={<BankOutlined />}>
                        <Link to="/app/studies" className="app-sidebar-link">
                            Studies
                        </Link>
                    </Menu.Item>
                </Menu>
            )}
        </Sider>
    );
};

export default withRouter(SideBar);
