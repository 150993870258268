import React, { useState, useContext, useEffect } from 'react';
import { Layout as AntLayout } from 'antd';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Route, Switch, Redirect } from 'react-router-dom';
import { GuardProvider, GuardedRoute } from 'react-router-guards';
import AuthService from '../services/identity/authService';
import Studies from '../pages/studies/studies';
import CreateStudy from '../pages/createStudy/createStudy';
// import Loading from '../components/loading/loading';
import Participants from '../pages/participants/participants';
import Profile from '../pages/profile/profile';
import UserManagement from '../pages/userManagement/userManagement';
import { appMode, roles } from '../types/global';

import SideBar from '../layout/sideBar';
import AppHeader from '../components/header/appHeader';
import authContext from '../utils/context/auth-context';

import './index.scss';

const { Content } = AntLayout;

const Routes: React.FC = () => {
    const authService = new AuthService();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const requireLogin = async (to: any, from: any, next: any) => {
        if (to.meta.auth) {
            if (await authService.getUser()) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                next();
            } else {
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                authService.login();
            }
        } else if (await authService.getUser()) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            next.redirect('/app/studies');
        } else {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            next();
        }
    };

    const { role } = useContext(authContext);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [adminRole, setAdminRole] = useState(false);

    useEffect(() => {
        if (role === roles.ADMIN) {
            setAdminRole(true);
        }
        if (role === roles.RESEARCHER) {
            setAdminRole(false);
        }
    }, []);

    return (
        <AntLayout className="app-layout-fix">
            <SideBar />
            <AntLayout className="app-layout-site">
                <AppHeader mode={appMode.APP as string} />
                <Content className="app-layout-content">
                    <GuardProvider guards={[requireLogin]}>
                        {role === roles.ADMIN ? (
                            <Switch>
                                <GuardedRoute
                                    exact
                                    path="/app/studies/createstudy"
                                    component={CreateStudy}
                                    meta={{ auth: true }}
                                />
                                <GuardedRoute exact path="/app/user" component={UserManagement} meta={{ auth: true }} />
                                <GuardedRoute exact path="/app/studies" component={Studies} meta={{ auth: true }} />
                                <GuardedRoute
                                    exact
                                    path="/app/participants"
                                    component={Participants}
                                    meta={{ auth: true }}
                                />
                                <GuardedRoute exact path="/app/profile" component={Profile} meta={{ auth: true }} />
                            </Switch>
                        ) : (
                            <Switch>
                                <GuardedRoute
                                    exact
                                    path="/app/studies/createstudy"
                                    component={CreateStudy}
                                    meta={{ auth: true }}
                                />
                                <GuardedRoute exact path="/app/studies" component={Studies} meta={{ auth: true }} />
                                <GuardedRoute
                                    exact
                                    path="/app/participants"
                                    component={Participants}
                                    meta={{ auth: true }}
                                />
                                <GuardedRoute exact path="/app/profile" component={Profile} meta={{ auth: true }} />
                            </Switch>
                        )}
                        {/* <Switch>
                            <GuardedRoute
                                exact
                                path="/app/studies/createstudy"
                                component={CreateStudy}
                                meta={{ auth: true }}
                            />
                            {adminRole && (
                                <GuardedRoute exact path="/app/user" component={UserManagement} meta={{ auth: true }} />
                            )}
                            <GuardedRoute exact path="/app/studies" component={Studies} meta={{ auth: true }} />
                            <GuardedRoute
                                exact
                                path="/app/participants"
                                component={Participants}
                                meta={{ auth: true }}
                            />
                            <GuardedRoute exact path="/app/profile" component={Profile} meta={{ auth: true }} />
                        </Switch> */}
                    </GuardProvider>
                </Content>
            </AntLayout>
        </AntLayout>
    );
};

export default Routes;
