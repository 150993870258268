import { Role } from '../../types/global';

const getUserType = (userRoleId: number): string => {
    let userType: string;

    switch (userRoleId) {
        case Role.ADMIN:
            userType = 'Admin';
            break;
        case Role.RESEARCHER:
            userType = 'Researcher';
            break;
        // Add more cases as needed
        default:
            userType = 'OtherRole';
            break;
    }

    return userType;
};

export default getUserType;
