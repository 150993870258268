import React, { useState } from 'react';

import './index.scss';

interface LabelProps {
    label: string;
    value: string;
    disable?: boolean;
    prefix?: boolean;
    intlNumber?: boolean;
}

const FloatLabel: React.FC<LabelProps> = ({ label, value, disable, intlNumber, prefix, children }) => {
    const [focus, setFocus] = useState(false);
    const setLabelClass = () => {
        if (disable) {
            const labelClass = focus || (value && value.length !== 0) ? 'labelDisable label-float' : 'label';
            return labelClass;
        }
        if (prefix) {
            const labelClass = focus || (value && value.length !== 0) ? 'labelPrefix label-float' : 'labelPrefix';
            return labelClass;
        }
        if (intlNumber) {
            const labelClass =
                focus || (value && value.length !== 0) ? 'labelIntlNumber label-float' : 'labelIntlNumber';
            return labelClass;
        }
        const labelClass = focus || (value && value.length !== 0) ? 'label label-float' : 'label';
        return labelClass;
    };

    return (
        <div className="float-label" onBlur={() => setFocus(false)} onFocus={() => setFocus(true)}>
            {children}
            <label className={setLabelClass()}>{label}</label>
        </div>
    );
};

export default FloatLabel;
