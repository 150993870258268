import axios, { AxiosInstance } from 'axios';
import { MyToken } from '../../types/global';
import AuthService from '../../services/identity/authService';
import { jwtDecode } from 'jwt-decode';

const axiosInstance: AxiosInstance = axios.create({
    // timeout: 10000,
});

axiosInstance.interceptors.request.use(async (request) => {
    const authService = new AuthService();
    const user = await authService.getUser();
    const token: string = user?.access_token || '';
    const decoded: MyToken = jwtDecode(token);

    request.headers.tenantId = decoded.tenant_id;
    request.headers.Authorization = `Bearer ${token}`;
    return request;
});

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject(error.response),
);

export default axiosInstance;
