import { AxiosRequestConfig, AxiosResponse } from 'axios';
import axiosInstance from '../../utils/axios/axiosInstance';
import { APIResponse, UsersDataModel } from '../../types/global';
import { ENDPOINTS } from './contract';

class TenantService {
    public static async GetUsers(authToken: string): Promise<AxiosResponse<APIResponse<UsersDataModel[]>>> {
        const requestConfig: AxiosRequestConfig = {
            method: 'GET',
            url: `${ENDPOINTS.TENANT_BASE_URL}/Users`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                // 'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${authToken}`,
            },
        };

        const response: AxiosResponse = await axiosInstance(requestConfig);
        return response;
    }
}

export default TenantService;
