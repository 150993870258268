/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useEffect, useState } from 'react';
import { PageHeader, Button } from 'antd';
import { useLocation, Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import useTitle from '../../utils/hooks/useTitle';
import ParticipantsListTable from '../../components/participants/table/participantsListTable';
import { studyDataModal, studyStatus } from '../../types/global';
import './index.scss';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Participants: React.FC = (props: any) => {
    const { location, history } = props;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { setHeaderTitle } = useTitle();
    const { t } = useTranslation();
    const studyRef = useLocation<studyDataModal>().state;
    const [drawVisible, setDrawVisible] = useState(false);

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        setHeaderTitle(t('participant.participantTitle'));
    }, []);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onCloseDrawer = (myForm: any) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        myForm.resetFields(['personalId', 'mobileNumber', 'email', 'message']);
        setDrawVisible(false);
    };
    const setStudyName = () => {
        if (studyRef === undefined) {
            return 'Title';
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return studyRef.studyName;
    };
    const setDisableAddParticipant = () => {
        if (location.state === undefined) {
            return false;
        }
        if (location.state.status === studyStatus.DRAFT) {
            return true;
        }
        return false;
    };

    return (
        <div className="root">
            {location.state ? (
                <div>
                    <PageHeader
                        className="participant-list-page-header"
                        onBack={() => {
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                            history.push('/app/studies');
                        }}
                        title={setStudyName()}
                        backIcon={
                            <FontAwesomeIcon icon="arrow-alt-circle-left" className="participant-list-backIcon " />
                        }
                        extra={
                            <Button
                                type="primary"
                                className="ant-btn-addParticipant"
                                size="large"
                                disabled={setDisableAddParticipant()}
                                onClick={() => {
                                    setDrawVisible(true);
                                }}
                                shape="round"
                            >
                                {t('participant.addParticipant')}
                            </Button>
                        }
                    />
                    <ParticipantsListTable
                        invitationDrawerVisible={drawVisible}
                        invitationDrawerOnClose={onCloseDrawer}
                    />
                </div>
            ) : (
                <Redirect
                    to={{
                        pathname: '/studies',
                        state: { from: location },
                    }}
                />
            )}
        </div>
    );
};

export default Participants;
