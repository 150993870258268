/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from 'react';

const defaultObj = {
    inviteEmail: '',
    reminderEmail: '',
    consent1: '',
    consent2: '',
    participantInfoText: '',
    introductionText: '',
};

const AuthContext = React.createContext(defaultObj);

export default AuthContext;
