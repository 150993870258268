/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from 'react';
import AuthService from '../services/identity/authService';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Route, Switch, Redirect } from 'react-router-dom';
import { GuardProvider, GuardedRoute } from 'react-router-guards';
import Routes from '../routes/routes';
import AuthRoute from '../routes/authRoute';
// import Loading from '../components/loading/loading';

import './index.scss';

const Layout: React.FC = () => {
    const authService = new AuthService();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const requireLogin = async (to: any, from: any, next: any) => {
        if (to.meta.auth) {
            if (await authService.getUser()) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                next();
            } else {
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                authService.login();
            }
        } else if (await authService.getUser()) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            next.redirect('/app/studies');
        } else {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            next();
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function AppRoutes({ component, ...rest }: any) {
        return (
            <GuardedRoute
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...rest}
                render={(props) => React.createElement(component, props)}
                meta={{ auth: true }}
            />
        );
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function AuthRoutes({ component, ...rest }: any) {
        return (
            <GuardedRoute
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...rest}
                render={(props) => React.createElement(component, props)}
                meta={{ auth: true }}
            />
        );
    }

    return (
        <div>
            <GuardProvider guards={[requireLogin]}>
                <Switch>
                    <GuardedRoute exact path="/" render={() => <Redirect to="/auth" />} meta={{ auth: true }} />
                    <GuardedRoute
                        exact
                        path="/app"
                        render={() => <Redirect to="/app/studies" />}
                        meta={{ auth: true }}
                    />
                    <AppRoutes path="/app" component={Routes} />
                    <AuthRoutes path="/auth" component={AuthRoute} />
                </Switch>
            </GuardProvider>
        </div>
    );
};

export default Layout;
