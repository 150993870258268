/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useContext, useEffect } from 'react';
import { Drawer, Form, Button, Col, Row, Input, notification, Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
import ParticipantService from '../../services/participant';
import Loading from '../loading/loading';
import defaultModalContext from '../../utils/context/defaultModel-context';
import AuthService from '../../services/identity/authService';
import './index.scss';
import {
    MessageType,
    invitationValidateStatus,
    sendReminderRequestBody,
    updateContactInfoRequestBody,
} from '../../types/global';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';

type drawerProps = {
    visible: boolean;
    participantId: string;
    mobileNumber: string;
    email: string;
    personalId: string;
    studyName: string;
    studyId: string;
    onClose: () => void;
};

const ReminderDrawer: React.FC<drawerProps> = (props) => {
    const { visible, participantId, mobileNumber, email, personalId, studyName, studyId, onClose } = props;
    const [buffering, setBuffering] = useState(false);
    const authService = new AuthService();
    const { reminderEmail } = useContext(defaultModalContext);
    const [form] = Form.useForm();
    const { t } = useTranslation();

    const [contactInfo, setContactInfo] = useState<{
        mobileNumber?: string | null;
        email?: string | null;
    }>({
        mobileNumber,
        email,
    });

    const [sendByEmail, setSendByEmail] = useState(false);
    const [sendBySMS, setSendBySMS] = useState(false);

    const openNotification = (type: string, message: string, err: string) => {
        if (type === 'success' || type === 'warning') notification[type]({ message, description: err });
    };

    const updateParticipantContactInfo = async (token: string) => {
        try {
            const requestBody: updateContactInfoRequestBody = {
                id: participantId,
                email: contactInfo.email,
                mobileNumber: contactInfo.mobileNumber,
                studyId,
            };
            const response = (await ParticipantService.updateParticipantContactInfo(token, requestBody)).data;
            if (response.isSuccess === true) {
                openNotification('success', t('reminderDrawer.notification.updateParticipant'), '');
                return true;
            }

            if (response.responseCode === invitationValidateStatus.EMAIL_EXIST) {
                openNotification(
                    'warning',
                    t('reminderDrawer.notification.updateParticipantFail'),
                    t('reminderDrawer.notification.emailExist'),
                );
                return false;
            }
            if (response.responseCode === invitationValidateStatus.MOBILE_NUMBER_EXIST) {
                openNotification(
                    'warning',
                    t('reminderDrawer.notification.updateParticipantFail'),
                    t('reminderDrawer.notification.mobileNumberExist'),
                );
                return false;
            }

            if (response.isSuccess === null) {
                openNotification('warning', t('reminderDrawer.notification.updateParticipantFail'), '');
                return false;
            }
        } catch (error: any) {
            openNotification('warning', t('reminderDrawer.notification.updateParticipantFail'), '');
            setContactInfo({ mobileNumber: mobileNumber || null, email: email || null });
            form.resetFields(['personalId', 'mobileNumber', 'email', 'message']);
            return false;
        }
    };

    const onClickSubmit = () => {
        form.submit();
    };

    const onFinish = async () => {
        try {
            await form.validateFields();

            setBuffering(true);
            const user = await authService.getUser();
            const token: string = user?.access_token || '';
            const idList = [participantId];

            const methods: MessageType[] = [];
            if (sendByEmail) methods.push(MessageType.Email);
            if (sendBySMS) methods.push(MessageType.SMS);

            if (methods.length === 0) {
                throw new Error('Please select at least one method (Email or SMS)');
            }

            const hasContactInfoChanged = contactInfo.mobileNumber !== mobileNumber || contactInfo.email !== email;
            if (hasContactInfoChanged) {
                const updateSuccess = await updateParticipantContactInfo(token);
                if (!updateSuccess) {
                    setBuffering(false);
                    return;
                }
            }

            const request: sendReminderRequestBody = {
                ids: idList,
                messageType:
                    // eslint-disable-next-line no-nested-ternary
                    methods.length === 2
                        ? MessageType.Both
                        : methods[0] === MessageType.Email
                        ? MessageType.Email
                        : MessageType.SMS,
            };

            await ParticipantService.sendReminder(token, request);
            openNotification('success', t('reminderDrawer.notification.sendReminder'), '');
            onClose();
        } catch (error: any) {
            console.log(error);
            if (error.status !== undefined) {
                if (error.status === 422) {
                    openNotification('warning', t('reminderDrawer.notification.sendReminderFail'), error.data.message);
                }
                if (error.status === 400) {
                    openNotification('warning', t('reminderDrawer.notification.sendReminderFail'), ' ');
                }
            }
        } finally {
            setBuffering(false);
            form.resetFields(['personalId', 'mobileNumber', 'email', 'message']);
        }
    };
    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const fetch = () => {
        setContactInfo({ mobileNumber: mobileNumber || null, email: email || null });
        form.setFieldsValue({
            personalId,
            email,
            mobileNumber,
        });
    };

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        setSendByEmail(false);
        setSendBySMS(false);
        fetch();
    }, [props]);

    const options = {
        // eslint-disable-next-line consistent-return
        replace: (domNode: any) => {
            if (domNode.attribs && domNode.attribs.id === 'replace') {
                return <>{studyName}</>;
            }
        },
    };

    return (
        <>
            <Drawer
                title={<div className="reminder-drawer-title">{t('reminderDrawer.title')}</div>}
                width={450}
                onClose={onClose}
                visible={visible}
                footer={
                    <div className="reminder-drawer-footer">
                        <Row>
                            <Col span={12}>
                                <Button onClick={onClose} className="reminder-drawer-btn-cancel">
                                    {t('reminderDrawer.cancelButton')}
                                </Button>
                            </Col>
                            <Col span={12}>
                                <Button
                                    onClick={onClickSubmit}
                                    className="reminder-drawer-btn-send"
                                    disabled={!sendByEmail && !sendBySMS}
                                >
                                    {t('reminderDrawer.sendButton')}
                                </Button>
                            </Col>
                        </Row>
                    </div>
                }
            >
                {!buffering ? (
                    <Form
                        layout="vertical"
                        className="reminder-drawer-form"
                        form={form}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        initialValues={{
                            personalId,
                            mobileNumber: contactInfo.mobileNumber,
                            email: contactInfo.email,
                        }}
                    >
                        <Row gutter={16}>
                            <Col span={24} className="reminder-drawer-form-row">
                                <Form.Item name="personalId" label="Personal Number">
                                    <Input placeholder={t('reminderDrawer.personalId')} disabled />
                                </Form.Item>
                            </Col>
                        </Row>
                        <p className="p-20">{t('reminderDrawer.selectOption')}</p>
                        <Row gutter={16}>
                            <Col span={5}>
                                <Checkbox
                                    checked={sendBySMS}
                                    onChange={(e) => setSendBySMS(e.target.checked)}
                                    name="SMS"
                                >
                                    SMS
                                </Checkbox>
                            </Col>
                            <Col span={19}>
                                <Form.Item
                                    name="mobileNumber"
                                    rules={[
                                        () => ({
                                            validator(_, value1) {
                                                if (!value1 || (value1 && isValidPhoneNumber(value1))) {
                                                    return Promise.resolve();
                                                }

                                                return Promise.reject(new Error(t('reminderDrawer.mobileNumberRule')));
                                            },
                                        }),
                                    ]}
                                >
                                    <PhoneInput
                                        international
                                        disabled={!sendBySMS}
                                        defaultCountry="SE"
                                        onChange={(e: string) => {
                                            setContactInfo({ ...contactInfo, mobileNumber: e || '' });
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={5}>
                                <Checkbox checked={sendByEmail} onChange={(e) => setSendByEmail(e.target.checked)}>
                                    Email
                                </Checkbox>
                            </Col>
                            <Col span={19}>
                                <Form.Item
                                    name="email"
                                    rules={[
                                        {
                                            type: 'email',
                                            message: t('reminderDrawer.emailValidation'),
                                        },
                                        () => ({
                                            validator(_, value) {
                                                if (value === null && sendByEmail) {
                                                    return Promise.reject(
                                                        new Error(t('reminderDrawer.emailCannotBeEmpty')),
                                                    );
                                                }

                                                return Promise.resolve();
                                            },
                                        }),
                                    ]}
                                >
                                    <Input
                                        placeholder={t('reminderDrawer.email')}
                                        onChange={(e) => setContactInfo({ ...contactInfo, email: e.target.value })}
                                        disabled={!sendByEmail}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        {/* <Row gutter={16}>
                            <Col span={24} className="reminder-drawer-form-row">
                                <Form.Item name="content" label={t('reminderDrawer.messagePreview')}>
                                    <div className="reminder-drawer-form-message-box">
                                        <div className="reminder-drawer-form-message-content">
                                            {parse(reminderEmail, options)}
                                        </div>
                                    </div>
                                </Form.Item>
                            </Col>
                        </Row> */}
                    </Form>
                ) : (
                    <Loading />
                )}
            </Drawer>
        </>
    );
};
export default ReminderDrawer;
