import React, { useState } from 'react';

const TitleContext = React.createContext({});

const defaultTitle = {
    title: '',
};
interface titleTypes {
    headerTitle: string;
}

export const TitleProvider: React.FC<titleTypes> = ({ children, headerTitle }) => {
    const [title, setTitle] = useState(headerTitle || defaultTitle);
    const setHeaderTitle = (value: string) => {
        setTitle(value);
    };

    return <TitleContext.Provider value={{ title, setHeaderTitle }}> {children}</TitleContext.Provider>;
};
export const TitleConsumer = TitleContext.Consumer;
export default TitleContext;
