/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, { useContext } from 'react';
import { Layout as AntLayout, Menu, Avatar, Button, Dropdown, Row, Col } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import AuthService from '../../services/identity/authService';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import authContext from '../../utils/context/auth-context';
import useTitle from '../../utils/hooks/useTitle';
import { appMode, roles } from '../../types/global';
import econsentWhiteLogo from '../../assets/whiteLogo.svg';
import './index.scss';

const { Header } = AntLayout;

type headerProps = {
    mode: string;
};

const AppHeader: React.FC<headerProps> = ({ mode }) => {
    const authService = new AuthService();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { title } = useTitle();
    const { role, avatarText, headerName } = useContext(authContext);
    const { t } = useTranslation();
    const history = useHistory();

    const setRoleName = () => {
        if (role === roles.ADMIN) {
            return t('header.roleAdmin');
        }
        if (role === roles.RESEARCHER) {
            return t('header.roleResearcher');
        }
        return '';
    };
    const appProfileMenu = (
        <Menu>
            <Menu.Item>
                <Button
                    className="header-menu-link-button"
                    onClick={() => {
                        history.push('/app/profile');
                    }}
                >
                    {t('header.editProfile')}
                </Button>
            </Menu.Item>
            <Menu.Item>
                <Button
                    className="header-menu-link-button"
                    onClick={() => {
                        // eslint-disable-next-line @typescript-eslint/no-floating-promises
                        authService.logout();
                    }}
                >
                    {t('header.logout')}
                </Button>
            </Menu.Item>
        </Menu>
    );
    const authProfileMenu = (
        <Menu>
            <Menu.Item>
                <Button
                    className="header-menu-link-button"
                    onClick={() => {
                        // eslint-disable-next-line @typescript-eslint/no-floating-promises
                        authService.logout();
                    }}
                >
                    {t('header.logout')}
                </Button>
            </Menu.Item>
        </Menu>
    );

    const setProfileMenu = () => {
        if (mode === appMode.AUTH) {
            return authProfileMenu;
        }
        return appProfileMenu;
    };
    const setHeaderTitle = () => {
        if (mode === appMode.AUTH) {
            return <img src={econsentWhiteLogo} alt="E-consent Logo" className="header-logo" />;
        }
        return <span>{title}</span>;
    };

    return (
        <>
            <Header className="header-layout-background">
                <Row justify="space-between" align="middle" className="header-layout-row">
                    <Col span={8} className="header-layout-title-col">
                        {setHeaderTitle()}
                    </Col>
                    <Col span={8} className="header-layout-name-col">
                        <div className="header-account-details">
                            <div>
                                <Avatar size={30} className="header-avatar-icon">
                                    {avatarText}
                                </Avatar>
                            </div>
                            <Dropdown overlay={setProfileMenu()}>
                                <div className="header-dropdown-div">
                                    <div className="header-dropdown-section">
                                        <div className="header-name-section">
                                            <span className="header-name-span">{headerName}</span>
                                            <span className="header-role-span">{setRoleName()}</span>
                                        </div>
                                        <div className="header-dropdown-arrow">
                                            <DownOutlined />
                                        </div>
                                    </div>
                                </div>
                            </Dropdown>
                        </div>
                    </Col>
                </Row>
            </Header>
        </>
    );
};

export default AppHeader;
