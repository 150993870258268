/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { Drawer, Form, Button, Col, Row, Input, Tag, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import FloatLabel from '../floatLabel/floatLabel';
import Loading from '../loading/loading';
import './index.scss';
import RoleService from '../../services/role';
import { NotificationType, RoleModel, User } from '../../types/global';
import UserService from '../../services/user';
import openNotification from '../../utils/notification/notification';

interface drawerProps {
    visible: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onClose: (form: any, id?: string, user?: User) => void;
}

const { Option } = Select;

interface newUserObject {
    firstName: string;
    lastName: string;
    email: string;
    role: string;
    institution: string;
    unit: string;
    id: number;
    key: number;
}

const AddUserDrawer: React.FC<drawerProps> = ({ onClose, visible }) => {
    const [userRole, setUserRole] = useState<RoleModel[]>([{ id: 0, roleName: '' }]);
    const [buffering, setBuffering] = useState(false);
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [{ fNameValue, lNameValue, emailValue, roleValue }, setUserValues] = useState({
        fNameValue: '',
        lNameValue: '',
        emailValue: '',
        roleValue: '',
        institutionValue: '',
        unitValue: '',
    });

    const getAllRoles = () => {
        RoleService.GetRoles()
            .then((res) => {
                const { data } = res.data;
                setUserRole(data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onFinish = ({
        firstName,
        lastName,
        email,
        role,
    }: {
        firstName: string;
        lastName: string;
        email: string;
        role: number;
    }) => {
        try {
            setBuffering(true);
            const obj: User = {
                firstName: firstName.trim(),
                lastName: lastName.trim(),
                email: email.trim(),
                roleId: role,
            };

            setBuffering(true);

            UserService.AddUser(obj)
                .then((res) => {
                    openNotification(NotificationType.success, 'User Added Successfully', '');
                    form.resetFields(['firstName', 'lastName', 'email', 'role']);
                    onClose(form, res.data.data.id, obj);
                })
                .catch((err) => {
                    openNotification(NotificationType.error, 'User Adding Failed', err.data.message);
                })
                .finally(() => {
                    setBuffering(false);
                });
        } catch (error) {
            console.log(error);
            setBuffering(false);
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    useEffect(() => {
        getAllRoles();
    }, []);

    return (
        <div>
            {' '}
            <>
                <Drawer
                    title={<div className="drawer-title">{t('addUserDrawer.title')}</div>}
                    width={450}
                    onClose={() => {
                        setUserValues((state) => ({
                            ...state,
                            fNameValue: '',
                            lNameValue: '',
                            emailValue: '',
                            roleValue: '',
                            institutionValue: '',
                            unitValue: '',
                        }));
                        onClose(form);
                    }}
                    // eslint-disable-next-line react/destructuring-assignment
                    visible={visible}
                    // bodyStyle={{ paddingBottom: 80 }}
                    footer={
                        !buffering && (
                            <div className="drawer-footer">
                                <Row>
                                    <Col span={12}>
                                        <Button
                                            onClick={() => {
                                                setUserValues((state) => ({
                                                    ...state,
                                                    fNameValue: '',
                                                    lNameValue: '',
                                                    emailValue: '',
                                                    roleValue: '',
                                                    institutionValue: '',
                                                    unitValue: '',
                                                }));
                                                onClose(form);
                                            }}
                                            className="drawer-btn-cancel"
                                        >
                                            {t('addUserDrawer.cancelButton')}
                                        </Button>
                                    </Col>
                                    <Col span={12}>
                                        <Button onClick={() => form.submit()} className="drawer-btn-addAll">
                                            {t('addUserDrawer.addUserButton')}
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        )
                    }
                >
                    {!buffering ? (
                        <div>
                            <Form
                                layout="vertical"
                                className="drawer-form"
                                form={form}
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                initialValues={
                                    {
                                        // prefix: '+46',
                                    }
                                }
                            >
                                <Row gutter={16}>
                                    <Col span={24} className="drawer-form-row">
                                        <FloatLabel label={t('addUserDrawer.firstName')} value={fNameValue}>
                                            <Form.Item
                                                name="firstName"
                                                rules={[
                                                    { required: true, message: t('addUserDrawer.firstNameRequired') },
                                                    () => ({
                                                        validator(_, value: string) {
                                                            if (!value || value.trim() !== '') {
                                                                return Promise.resolve();
                                                            }
                                                            return Promise.reject(
                                                                new Error(t('addUserDrawer.firstNameRequired')),
                                                            );
                                                        },
                                                    }),
                                                ]}
                                            >
                                                <Input
                                                    onChange={(e) => {
                                                        setUserValues((state) => ({
                                                            ...state,
                                                            fNameValue: e.target.value,
                                                        }));
                                                    }}
                                                />
                                            </Form.Item>
                                        </FloatLabel>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={24} className="drawer-form-row">
                                        <FloatLabel label={t('addUserDrawer.lastName')} value={lNameValue}>
                                            <Form.Item
                                                name="lastName"
                                                rules={[
                                                    { required: true, message: t('addUserDrawer.lastNameRequired') },
                                                    () => ({
                                                        validator(_, value: string) {
                                                            if (!value || value.trim() !== '') {
                                                                return Promise.resolve();
                                                            }
                                                            return Promise.reject(
                                                                new Error(t('addUserDrawer.lastNameRequired')),
                                                            );
                                                        },
                                                    }),
                                                ]}
                                            >
                                                <Input
                                                    onChange={(e) => {
                                                        setUserValues((state) => ({
                                                            ...state,
                                                            lNameValue: e.target.value,
                                                        }));
                                                    }}
                                                />
                                            </Form.Item>
                                        </FloatLabel>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={24} className="drawer-form-row">
                                        <FloatLabel label={t('addUserDrawer.email')} value={emailValue}>
                                            <Form.Item
                                                name="email"
                                                rules={[
                                                    { required: true, message: t('addUserDrawer.emailRequired') },
                                                    {
                                                        type: 'email',
                                                        message: t('addUserDrawer.emailValidation'),
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    onChange={(e) => {
                                                        setUserValues((state) => ({
                                                            ...state,
                                                            emailValue: e.target.value,
                                                        }));
                                                    }}
                                                />
                                            </Form.Item>
                                        </FloatLabel>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={24} className="drawer-form-row">
                                        <FloatLabel label={t('addUserDrawer.role')} value={roleValue}>
                                            <Form.Item
                                                name="role"
                                                rules={[{ required: true, message: t('addUserDrawer.roleRequired') }]}
                                            >
                                                <Select
                                                    onChange={(e) => {
                                                        setUserValues((state) => ({
                                                            ...state,
                                                            roleValue: `${e}`,
                                                        }));
                                                    }}
                                                >
                                                    {userRole.map((role: RoleModel) => (
                                                        <Option key={role.id} value={role.id}>
                                                            {role.roleName}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </FloatLabel>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    ) : (
                        <Loading />
                    )}
                </Drawer>
            </>
        </div>
    );
};

export default AddUserDrawer;
