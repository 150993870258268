import React, { useState, useEffect } from 'react';
import { Table, Tag, Space, Input, Row, Col, Button, Tooltip, notification } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AuthService from '../../../services/identity/authService';
import StudyService from '../../../services/study';
import InvitationDrawer from '../../invitationDrawer/invitationDrawer';
import DeleteModal from '../../deleteModal/deleteModal';
import StudyViewModal from '../viewModal/studyViewModal';
import { studyDataModal, studyStatus } from '../../../types/global';

import '../index.scss';

const StudyListTable: React.FC = () => {
    const [dataSource, setDataSource] = useState<Array<studyDataModal>>([]);
    const [duplicateDataSource, setDuplicateDataSource] = useState<Array<studyDataModal>>([]);
    const [drawVisible, setDrawVisible] = useState(false);
    const [studyId, setStudyId] = useState('');
    const [studyDeleteId, setStudyDeleteId] = useState('');
    const [studyName, setStudyName] = useState('');
    const history = useHistory();
    const { t } = useTranslation();
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [deleteModalConfirmLoading, setDeleteModalConfirmLoading] = useState(false);
    const authService = new AuthService();
    const [loading, setLoading] = useState(true);
    const [studyViewModalVisible, setStudyViewModalVisible] = useState(false);
    const [studyObject, setStudyObject] = useState({} as studyDataModal);

    const openNotification = (type: string, message: string, err: string) => {
        if (type === 'success' || type === 'warning') notification[type]({ message, description: err });
    };

    const fetch = async () => {
        try {
            const user = await authService.getUser();
            const token: string = user?.access_token || '';
            // console.log(token);
            const response = await StudyService.getAllStudies(token);
            // console.log(response);
            setDataSource(response.data);
            setDuplicateDataSource(response.data);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setDataSource([]);
            setLoading(false);
        }
    };

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        fetch();
    }, []);

    const deleteModalHandleOk = async () => {
        try {
            setDeleteModalConfirmLoading(true);
            const user = await authService.getUser();
            const token: string = user?.access_token || '';
            await StudyService.deleteStudy(token, studyDeleteId);
            await fetch();
            openNotification('success', t('study.notification.deleteSuccess'), '');
            setDeleteModalConfirmLoading(false);
            setDeleteModalVisible(false);
            setStudyDeleteId('');
        } catch (error) {
            console.log(error);
            openNotification('warning', t('study.notification.deleteFail'), '');
            setStudyDeleteId('');
            setDeleteModalConfirmLoading(false);
            setDeleteModalVisible(false);
        }
    };
    const deleteModalHandleCancel = () => {
        setStudyDeleteId('');
        setDeleteModalVisible(false);
    };

    const handleTooltip = (status: number) => {
        const tooltip =
            status === studyStatus.PUBLISHED ? t('study.tooltip.publishedInvite') : t('study.tooltip.invite');
        return tooltip;
    };

    const columns: ColumnsType<studyDataModal> = [
        {
            title: t('study.table.study'),
            dataIndex: 'studyName',
            key: 'studyName',
            className: 'studyColumn',
            width: '15%',
            sorter: (a, b) => {
                if (a.studyName !== null && b.studyName !== null) {
                    return a.studyName.localeCompare(b.studyName);
                }
                const c = 'N';
                const d = 'N';
                return c.localeCompare(d);
            },
            sortDirections: ['ascend', 'descend'],
            render: (state: string) => {
                if (state === null) {
                    return 'N/A';
                }
                return state;
            },
        },
        {
            title: t('study.table.included'),
            dataIndex: 'numberOfParticipants',
            align: 'center',
            key: 'numberOfParticipants',
            sorter: (a, b) => a.numberOfParticipants - b.numberOfParticipants,
        },
        {
            title: t('study.table.status'),
            key: 'Status',
            dataIndex: 'status',
            align: 'center',
            sorter: (a, b) => a.status - b.status,
            render: (state) => {
                let color;
                let content;
                if (state === studyStatus.PUBLISHED) {
                    color = 'green';
                    content = t('study.table.published');
                    return (
                        <Tag color={color} className="studyList-tag-publish">
                            {content}
                        </Tag>
                    );
                }
                color = 'orange';
                content = t('study.table.draft');
                return (
                    <Tag color={color} className="studyList-tag-draft">
                        {content}
                    </Tag>
                );
            },
        },
        {
            title: t('study.table.action'),
            key: 'action',
            align: 'center',
            render: (recode: studyDataModal) => {
                const { status, isEditDisable }: { status: number; isEditDisable: boolean } = recode;
                return (
                    <Space size={60}>
                        <Tooltip placement="bottom" title={handleTooltip(status)}>
                            <Button
                                icon={<FontAwesomeIcon icon="plus" className="studyList-table-icon" />}
                                className="studyList-table-link-button"
                                disabled={status === 0}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setStudyName(recode.studyName);
                                    setStudyId(recode.id);
                                    setDrawVisible(true);
                                }}
                            />
                        </Tooltip>
                        <Tooltip placement="bottom" title={t('study.tooltip.listOfParticipant')}>
                            <Button
                                icon={<FontAwesomeIcon icon="user-friends" className="studyList-table-icon" />}
                                className="studyList-table-link-button "
                                onClick={(e) => {
                                    e.stopPropagation();
                                    history.push({ pathname: '/app/participants', state: recode });
                                }}
                            />
                        </Tooltip>
                        <Tooltip placement="bottom" title={t('study.tooltip.editStudy')}>
                            <Button
                                icon={<FontAwesomeIcon icon="edit" className="studyList-table-icon" />}
                                className="studyList-table-link-button "
                                disabled={isEditDisable}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    history.push({ pathname: '/app/studies/createstudy', state: recode });
                                }}
                            />
                        </Tooltip>
                        <Tooltip placement="bottom" title={t('study.tooltip.deleteStudy')}>
                            <Button
                                icon={<FontAwesomeIcon icon="trash" className="studyList-table-icon" />}
                                className="studyList-table-link-button "
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setStudyDeleteId(recode.id);
                                    setDeleteModalVisible(true);
                                }}
                            />
                        </Tooltip>
                    </Space>
                );
            },
        },
    ];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onCloseDrawer = (myForm: any) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        myForm.resetFields(['personalId', 'mobileNumber', 'email', 'message']);
        setDrawVisible(false);
    };

    const onSearch = (e: string) => {
        if (e === '') {
            setDataSource(duplicateDataSource);
        } else {
            const tableData: Array<studyDataModal> = duplicateDataSource;
            const filterData: Array<studyDataModal> = tableData.filter((entry: studyDataModal) =>
                entry.studyName.toLowerCase().includes(e.toLowerCase()),
            );
            setDataSource(filterData);
        }
    };
    const onCloseStudyModal = () => {
        setStudyViewModalVisible(false);
    };
    const onClickRow = (record: studyDataModal) => {
        setStudyViewModalVisible(true);
        setStudyObject(record);
    };
    return (
        <div className="studyList-root">
            <Table<studyDataModal>
                columns={columns}
                dataSource={dataSource}
                rowKey="id"
                style={{ cursor: 'pointer' }}
                onRow={(record) => ({
                    onClick: () => {
                        onClickRow(record);
                    },
                })}
                loading={loading}
                pagination={{ hideOnSinglePage: true }}
                title={() => (
                    <Row>
                        <Col span={6}>
                            <Input
                                placeholder={t('global.searchPlaceHolder')}
                                prefix={<SearchOutlined />}
                                allowClear
                                onChange={(e) => {
                                    onSearch((e.target as HTMLInputElement).value);
                                }}
                                onPressEnter={(e) => {
                                    onSearch((e.target as HTMLInputElement).value);
                                }}
                            />
                        </Col>
                    </Row>
                )}
            />
            <DeleteModal
                title={t('global.studyDelete.title')}
                modalText={t('global.studyDelete.messageText')}
                visible={deleteModalVisible}
                handleOk={deleteModalHandleOk}
                okButtonText={t('global.studyDelete.okButtonText')}
                confirmLoading={deleteModalConfirmLoading}
                handleCancel={deleteModalHandleCancel}
            />
            <InvitationDrawer
                visible={drawVisible}
                onClose={onCloseDrawer}
                studyId={studyId}
                studyName={studyName}
                updateStudyList={fetch}
            />
            <StudyViewModal
                handleCancel={onCloseStudyModal}
                modalVisible={studyViewModalVisible}
                studyObj={studyObject || null}
                // studyId={studyObject.id}
            />
        </div>
    );
};
export default StudyListTable;
